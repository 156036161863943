import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { ReactElement, ReactNode, useState } from 'react';

import { Button } from '../../';

import classes from './Annotations.module.scss';

interface Props {
  children?: ReactNode;
  onCancel: () => void;
  onDelete: () => Promise<void>;
  title: string;
}

const DeleteConfirmation = ({ children, onCancel, onDelete, title }: Props): ReactElement => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string>();

  async function onConfirm() {
    setSubmitting(true);
    setSubmitError(undefined);
    try {
      await onDelete();
    } catch (error) {
      setSubmitError('Failed to delete. Please try again later.');
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Modal closeOnOverlayClick={false} isCentered isOpen onClose={onCancel} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          {children}
          {submitError && <p className={classes.errorMessage}>{submitError}</p>}
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onCancel} variant="mediumEmphasis">
            Cancel
          </Button>
          <Button isLoading={submitting} onClick={onConfirm} variant="highEmphasis">
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteConfirmation;
