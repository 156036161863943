import { History } from 'history';
import { normalize } from 'normalizr';

import { DenormalizedAnnotation } from '../@types/OnSiteIQ';
import { Annotation } from '../@types/api/v0/rest/Annotation';
import api from '../api';
import {
  ANNOTATION_CREATED,
  ANNOTATION_DELETED,
  ANNOTATION_UPDATED,
  REPLY_CREATED,
  REPLY_DELETED,
  REPLY_UPDATED,
} from '../constants/actionTypes';
import { floorplanSchema, walkthroughSchema } from '../constants/schemas';
import { store } from '../store';
import { errorMessage, requestMessage, successMessage } from '../utils/network';
import {
  areMatchingFloorplansLoaded,
  areMatchingFloorplansPending,
  areTimetravelPairsLoaded,
  areTimetravelPairsPending,
  isFloorplanLoaded,
  isFloorplanPending,
  isOtherWalkthroughLoaded,
  isWalkthroughLoaded,
  isWalkthroughPending,
} from '../utils/store';

// This controls how many walkthroughs are preloaded simultaneously
const preloadWalkthroughCount = 4;

export const annotationCreated = (annotation: Annotation) => ({
  type: ANNOTATION_CREATED,
  entity: annotation as DenormalizedAnnotation,
  kind: 'annotations',
});

// @ts-expect-error Redux cruft
const replyCreated = (reply) => ({
  type: REPLY_CREATED,
  entity: reply,
  kind: 'replies',
});

// @ts-expect-error Redux cruft
export const addReply = (annotation, content) => (dispatch: typeof store.dispatch) => {
  const data = {
    annotation: annotation.id,
    safety_mitigation: content.safety_mitigation,
    content: content.comment,
  };

  return api.replies.create(data).then((resp) => {
    dispatch(replyCreated(resp));
    return Promise.resolve(resp);
  });
};

const annotationDeleted = (id: string) => ({
  type: ANNOTATION_DELETED,
  id,
});

// @ts-expect-error Redux cruft
const replyDeleted = (id) => ({
  type: REPLY_DELETED,
  id,
});

// @ts-expect-error Redux cruft
const annotationUpdated = (annotation) => ({
  type: ANNOTATION_UPDATED,
  kind: 'annotations',
  entity: annotation,
});

// @ts-expect-error Redux cruft
const replyUpdated = (reply) => ({
  type: REPLY_UPDATED,
  kind: 'replies',
  entity: reply,
});

// @ts-expect-error Redux cruft
export const deleteReply = (id) => (dispatch: typeof store.dispatch) =>
  api.replies.remove(id).then(() => {
    dispatch(replyDeleted(id));
  });

export const catchWalktroughError = (error: Response, history: History) => {
  if (error.status === 403 || error.status === 404) {
    history.replace('/not-found');
  }
};

// @ts-expect-error Redux cruft
export const loadWalkthrough = (id, onError) => (dispatch: typeof store.dispatch) => {
  if (isWalkthroughLoaded(id) || isWalkthroughPending(id)) {
    return;
  }

  const kind = 'walkthroughs';
  dispatch(requestMessage(kind, id));

  // If we already have a walkthrough from this floorplan/ project loaded we don't need to make API calls for them
  if (isOtherWalkthroughLoaded(id)) {
    Promise.all([api.walkthroughs.get(id), api.walkthroughs.getAnnotations(id)])
      .then(([walkthrough, annotations]) => {
        walkthrough.annotations = annotations;

        dispatch(successMessage(kind, normalize(walkthrough, walkthroughSchema), id));
      })
      .catch((err) => {
        dispatch(errorMessage(kind, err, id));
        onError(err);
      });
  } else {
    Promise.all([
      api.walkthroughs.get(id),
      api.walkthroughs.getFloorplan(id),
      api.walkthroughs.getProject(id),
      api.walkthroughs.getAnnotations(id),
    ])
      .then(([walkthrough, floorplan, project, annotations]) => {
        walkthrough.annotations = annotations;
        floorplan.walkthroughs = [walkthrough];
        floorplan.project = project;
        floorplan.annotations = annotations;

        dispatch(successMessage(kind, normalize(floorplan, floorplanSchema), id));
      })
      .catch((err) => {
        dispatch(errorMessage(kind, err, id));
        onError(err);
      });
  }
};

// TODO: delete this, it's making everything slow :(
// @ts-expect-error Redux cruft
export const preloadWalkthroughs = (otherWalkthroughs) => (dispatch: typeof store.dispatch) => {
  // This inner method is responsible for actually making the network calls
  // @ts-expect-error Redux cruft
  const innerLoader = (id) =>
    new Promise((resolve) => {
      const kind = 'walkthroughs';
      dispatch(requestMessage(kind, id));

      const requests = [api.walkthroughs.get(id), api.walkthroughs.getAnnotations(id)];

      // You might notice the catching using resolve bellow, this is because it doesn't matter in the
      // context of this function if the loading succedes or fails, I just want to throttle these requests
      // for the sake of the network
      // Furthermore, you might notice resolve being called in both the then and the catch functions
      // Ideally it could just be placed in the finally function, but I haven't been able to get the timing
      // working correctly using finally
      Promise.all(requests)
        .then(([walkthrough, annotations]) => {
          walkthrough.annotations = annotations;

          dispatch(successMessage(kind, normalize(walkthrough, walkthroughSchema), id));
          // @ts-expect-error Redux cruft
          resolve();
        })
        .catch((err) => {
          dispatch(errorMessage(kind, err, id));
          // @ts-expect-error Redux cruft
          resolve();
        });
    });

  // This inner method is called recursively to only load loadAmount walkthroughs at once
  // @ts-expect-error Redux cruft
  const loader = (ids) => {
    // How many walkthroughs should we preload at once?
    const loadAmount = preloadWalkthroughCount;

    // Load these now
    const toLoad = ids.slice(0, loadAmount);
    // @ts-expect-error Redux cruft
    const promises = toLoad.map((id) => innerLoader(id));

    // These will be loaded next time
    const rest = ids.slice(loadAmount);

    Promise.all(promises).then(() => rest.length && loader(rest));
  };

  // Order the other walkthroughs with the most recent first
  // then exclude any walkthroughs which are loaded or in the process of being loaded
  const needsLoad = otherWalkthroughs
    // @ts-expect-error Redux cruft
    .sort((a, b) => a.when - b.when)
    // @ts-expect-error Redux cruft
    .map((o) => o.id)
    // @ts-expect-error Redux cruft
    .filter((id) => !isWalkthroughLoaded(id) && !isWalkthroughPending(id));

  if (needsLoad.length) {
    loader(needsLoad);
  }
};

// @ts-expect-error Redux cruft
export const loadFloorplan = (id) => (dispatch: typeof store.dispatch) => {
  if (isFloorplanLoaded(id) || isFloorplanPending(id)) {
    return;
  }

  const kind = 'floorplans';
  dispatch(requestMessage(kind, id));

  Promise.all([api.floorplans.get(id), api.floorplans.getProject(id)])
    .then(([floorplan, project]) => {
      floorplan.project = project;

      dispatch(successMessage(kind, normalize(floorplan, floorplanSchema), id));
    })
    .catch((err) => dispatch(errorMessage(kind, err, id)));
};

// @ts-expect-error Redux cruft
export const loadTimetravelPairs = (id) => (dispatch: typeof store.dispatch) => {
  if (!id || areTimetravelPairsLoaded(id) || areTimetravelPairsPending(id)) {
    return;
  }

  const kind = 'timetravelPairs';
  dispatch(requestMessage(kind, id));

  // NOTE this sends its data as entity instead of entities so it's a bit unique
  // @ts-expect-error Redux cruft
  const successMessage = (entity, id) => ({
    type: 'onsiteiq/network/success',
    entity,
    kind,
    id,
  });

  api.nodes
    .getTimetravelPairs(id)
    .then((pairs) => dispatch(successMessage({ id, pairs }, id)))
    .catch((err) => dispatch(errorMessage(kind, err, id)));
};

// @ts-expect-error Redux cruft
export const loadMatchingFloorplans = (id) => (dispatch: typeof store.dispatch) => {
  if (!id || areMatchingFloorplansLoaded(id) || areMatchingFloorplansPending(id)) {
    return;
  }

  const kind = 'matchingFloorplans';
  dispatch(requestMessage(kind, id));

  // NOTE this sends its data as entity instead of entities so it's a bit unique
  // @ts-expect-error Redux cruft
  const successMessage = (entity, id) => ({
    type: 'onsiteiq/network/success',
    entity,
    kind,
    id,
  });

  api.nodes
    .getMatchingFloorplans(id)
    .then((pairs) => dispatch(successMessage({ id, pairs }, id)))
    .catch((err) => dispatch(errorMessage(kind, err, id)));
};

export const deleteAnnotation = (id: string) => (dispatch: typeof store.dispatch) =>
  api.annotations.remove(id).then(() => {
    dispatch(annotationDeleted(id));
  });

// @ts-expect-error Redux cruft
export const updateReply = (id, data) => (dispatch: typeof store.dispatch) =>
  api.replies.update(id, data).then((reply) => {
    dispatch(replyUpdated(reply));
  });

// @ts-expect-error Redux cruft
export const updateAnnotation = (annotation, content) => (dispatch: typeof store.dispatch) =>
  api.annotations.update(annotation.id, { content }).then((new_annotation) => {
    annotation.content = new_annotation.content;
    dispatch(annotationUpdated(annotation));
  });
