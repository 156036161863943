import { Checkbox } from '@chakra-ui/react';

import classes from './Annotations.module.scss';

interface Props {
  onChange: (nextValue: 'C' | 'O' | null) => void;
  value: 'C' | 'O' | null;
}

const SafetyStatusControl = ({ onChange, value }: Props) => (
  <div className={classes.safetyStatusControl}>
    <Checkbox
      isDisabled={value === 'C'}
      isChecked={value === 'O'}
      onChange={() => onChange(value === 'O' ? null : 'O')}
    >
      Open
    </Checkbox>
    <Checkbox
      isDisabled={value === 'O'}
      isChecked={value === 'C'}
      onChange={() => onChange(value === 'C' ? null : 'C')}
    >
      Closed
    </Checkbox>
  </div>
);

export default SafetyStatusControl;
