import { Icon } from '@chakra-ui/react';
import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { Store } from '../../@types/redux/store';
import { toggleDrawer } from '../../actions/app';
import { helpCenterUrl } from '../../constants/urls';
import { ChatIcon, HelpIcon, LogoIcon } from '../Icon';
import AccountDropdown from './AccountDropdown';
import {
  ActionProps,
  AnnotationsAction,
  ChangeFloorplanAction,
  ProgressTrackingAction,
  SearchAction,
  TimeTravelAction,
} from './SidebarActions';

import classes from './Sidebar.module.scss';

type SideBarObjectParameter = ActionProps & {
  actionItem: string;
};

export type SideBarAction = string | SideBarObjectParameter;

const sideBarActionItems: Record<string, { component: (props: ActionProps) => ReactNode }> = {
  'change-floorplan': {
    component: (props) => <ChangeFloorplanAction key="drawer-change-floorplan" {...props} />,
  },
  'time-travel': {
    component: (props) => <TimeTravelAction key="drawer-time-travel" {...props} />,
  },
  search: {
    component: (props) => <SearchAction key="drawer-search" {...props} />,
  },
  annotations: {
    component: (props) => <AnnotationsAction key="drawer-annotations" {...props} />,
  },
  progress: {
    component: (props) => <ProgressTrackingAction key="drawer-progress-tracking" {...props} />,
  },
};

const Sidebar = (props: { children?: ReactNode }): ReactElement => {
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const drawer = useSelector((state: Store) => state.app.drawer);
  const sideBarActionsList = useSelector((state: Store) => state.app.sideBarActionsList);
  const onHomePage = pathname === '/';

  function renderDrawerActionItem(actionItemParameter: SideBarAction, selectedDrawer = '') {
    let actionItem = '';
    let parameters: ActionProps = {};

    if (
      typeof actionItemParameter === 'string' &&
      ['annotations', 'change-floorplan', 'search', 'time-travel'].includes(actionItemParameter)
    ) {
      actionItem = actionItemParameter;
      parameters = {
        active: selectedDrawer === actionItemParameter,
        onClick: () =>
          dispatch(toggleDrawer(actionItemParameter as 'annotations' | 'change-floorplan' | 'search' | 'time-travel')),
      };
    }

    if (typeof actionItemParameter === 'object') {
      actionItem = actionItemParameter.actionItem;
      parameters = {
        ...actionItemParameter,
      };
    }

    return sideBarActionItems[actionItem].component(parameters);
  }

  return (
    <div className={classes.sidebar}>
      <nav>
        <Link className={classNames(classes.logo, { [classes.logoActive]: onHomePage })} to="/">
          <Icon aria-label="OnsiteIQ" as={LogoIcon} height="auto" width="2.5rem" />
        </Link>
        {sideBarActionsList.map((item) => renderDrawerActionItem(item, drawer))}
        {props.children}
      </nav>
      <div className={classes.groupBottom}>
        <a
          className={classNames(classes.bottomActionItem, classes.iconButton, classes.helpCenterButton)}
          data-pendo-label="Open help center"
          data-pendo-topic="sidebar"
          href={helpCenterUrl}
          rel="noreferrer"
          target="_blank"
        >
          <Icon aria-label="Help Center" as={HelpIcon} paddingBlockEnd="0.125rem" />
        </a>
        <button
          id="help-chat-button"
          aria-label="Send us a message"
          className={classNames(classes.bottomActionItem, classes.iconButton, classes.intercomButton)}
          data-pendo-label="Toggle support"
          data-pendo-topic="sidebar"
        >
          <ChatIcon aria-hidden />
        </button>
        <AccountDropdown triggerClassName={classNames(classes.bottomActionItem, classes.menuButton)} />
      </div>
    </div>
  );
};

export default Sidebar;
