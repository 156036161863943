import {
  Center,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Toast,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { FormikProps } from 'formik';
import omit from 'lodash/omit';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Floorplan as LegacyFloorplan, Project as LegacyProject } from '../../../@types/OnSiteIQ';
import { Annotation, AnnotationCreateRequestBody } from '../../../@types/api/v0/rest/Annotation';
import { Floorplan } from '../../../@types/api/v0/rest/Floorplan';
import { Node } from '../../../@types/api/v0/rest/Node';
import { Project } from '../../../@types/api/v0/rest/Project';
import { Walkthrough } from '../../../@types/api/v0/rest/Walkthrough';
import { Observation, ObservationCreateRequestBody } from '../../../@types/procore/v1/Observation';
import { Rfi, RfiCreateRequestBody } from '../../../@types/procore/v1/Rfi';
import { Store } from '../../../@types/redux/store';
import { CompanyApi } from '../../../api/procore/v1/CompanyApi';
import { CostCodeApi } from '../../../api/procore/v1/CostCodeApi';
import { ObservationApi } from '../../../api/procore/v1/ObservationApi';
import { ProjectApi } from '../../../api/procore/v1/ProjectApi';
import { RfiApi } from '../../../api/procore/v1/RfiApi';
import { SpecificationSectionApi } from '../../../api/procore/v1/SpecificationSectionApi';
import { AnnotationApi } from '../../../api/v0/rest/AnnotationApi';
import { LoadingIndicator } from '../../../components';
import Button from '../../../components/Button';
import { LogoIcon, MarkupsIcon, ProcoreIcon } from '../../../components/Icon';
import { ProcoreQueryKeys, ProjectHierarchyQueryKeys, QueryTopics } from '../../../constants/queries';
import theme from '../../../theme';
import { dataURItoFile } from '../../../utils';
import ProcoreObservationForm, { ProcoreObservationFormValues } from './ProcoreObservationForm';
import ProcoreRfiForm, { ProcoreRfiFormValues } from './ProcoreRfiForm';
import StandardAnnotationForm, { StandardAnnotationFormValues } from './StandardAnnotationForm';

export interface AnnotationModalContainerProps {
  /** Coordinates on the 360 viewer's bounding sphere representing the center of the annotation's image. */
  annotationTarget?: { x: number; y: number; z: number };
  /** Floorplan of the walkthrough node on which the annotation will be created. */
  floorplan: Floorplan | LegacyFloorplan;
  /** Base 64 encoded image snapshot of the 360º viewer to be saved as the annotation image. */
  image?: string;
  /** Flag indicating whether or not the modal is open. */
  isOpen?: boolean;
  /** Walkthrough node on which the annotation will be created. */
  node: Node;
  /** Handler to call when the modal should close. */
  onClose: () => void;
  /** Handler to call when the user successfully creates an annotation. */
  onCreateAnnotation: (annotation: Annotation) => void;
  /** Core API project to create the annotation for. */
  project: Project | LegacyProject;
  /** The walkthrough associated with the node currently being annotated. */
  walkthrough: Walkthrough;
}

/**
 * Type of annotation, where "Standard" means an OnsiteIQ annotation. Indexing starts from 1 so that truthiness checks
 * on `annotationType` below only catch the case where its value is just `undefined` rather than `undefined` or `0`.
 */
enum AnnotationType {
  STANDARD = 1,
  PROCORE_OBSERVATION = 2,
  PROCORE_RFI = 3,
}

const AnnotationModalContainer = (props: AnnotationModalContainerProps) => {
  const { annotationTarget, floorplan, image, isOpen, node, onClose, onCreateAnnotation, project, walkthrough } = props;

  const procoreCompanyId = project.procore_company_id ?? 0;
  const procoreProjectId = project.procore_project_id ?? 0;

  const procoreUser = useSelector((state: Store) => state.auth.procoreMe);
  const isProcoreEnabled = useMemo(
    () => Boolean(procoreUser?.id && procoreCompanyId && procoreProjectId),
    [procoreCompanyId, procoreProjectId, procoreUser?.id]
  );

  const [annotationType, setAnnotationType] = useState<AnnotationType | undefined>(() =>
    isProcoreEnabled ? undefined : AnnotationType.STANDARD
  );

  // When the modal closes, clear the selected annotation type.
  useEffect(() => {
    if (!isOpen) {
      setAnnotationType(isProcoreEnabled ? undefined : AnnotationType.STANDARD);
    }
  }, [isOpen, isProcoreEnabled]);

  const isProcoreObservationQueryEnabled = Boolean(isOpen) && annotationType === AnnotationType.PROCORE_OBSERVATION;
  const isProcoreRfiQueryEnabled = Boolean(isOpen) && annotationType === AnnotationType.PROCORE_RFI;

  const procoreContributingBehaviorsListQuery = useQuery({
    enabled: isProcoreObservationQueryEnabled,
    queryKey: [QueryTopics.PROCORE, ProcoreQueryKeys.CONTRIBUTING_BEHAVIORS, procoreCompanyId],
    queryFn: async ({ signal }) => (await CompanyApi.listContributingBehaviors(procoreCompanyId, { signal })).data,
  });

  const procoreContributingConditionsListQuery = useQuery({
    enabled: isProcoreObservationQueryEnabled,
    queryKey: [QueryTopics.PROCORE, ProcoreQueryKeys.CONTRIBUTING_CONDITIONS, procoreCompanyId],
    queryFn: async ({ signal }) => (await CompanyApi.listContributingConditions(procoreCompanyId, { signal })).data,
  });

  const procoreCostCodeListQuery = useQuery({
    enabled: isProcoreRfiQueryEnabled,
    queryKey: [QueryTopics.PROCORE, ProcoreQueryKeys.COST_CODES, procoreCompanyId, procoreProjectId],
    queryFn: async ({ signal }) => (await CostCodeApi.listCompact(procoreCompanyId, procoreProjectId, { signal })).data,
  });

  const procoreHazardListQuery = useQuery({
    enabled: isProcoreObservationQueryEnabled,
    queryKey: [QueryTopics.PROCORE, ProcoreQueryKeys.HAZARDS, procoreCompanyId],
    queryFn: async ({ signal }) => (await CompanyApi.listHazards(procoreCompanyId, { signal })).data,
  });

  const procoreLocationListQuery = useQuery({
    enabled: isProcoreObservationQueryEnabled || isProcoreRfiQueryEnabled,
    queryKey: [QueryTopics.PROCORE, ProcoreQueryKeys.LOCATIONS, procoreCompanyId, procoreProjectId],
    queryFn: async ({ signal }) =>
      (await ProjectApi.listLocations(procoreCompanyId, procoreProjectId, { signal })).data,
  });

  const procoreObservationAssigneeListQuery = useQuery({
    enabled: isProcoreObservationQueryEnabled,
    queryKey: [QueryTopics.PROCORE, ProcoreQueryKeys.OBSERVATION_ASSIGNEES, procoreCompanyId, procoreProjectId],
    queryFn: async ({ signal }) =>
      (await ObservationApi.listAssigneeOptions(procoreCompanyId, procoreProjectId, { signal })).data,
  });

  const procoreObservationDefaultDistributionMemberListQuery = useQuery({
    enabled: isProcoreObservationQueryEnabled,
    queryKey: [
      QueryTopics.PROCORE,
      ProcoreQueryKeys.OBSERVATION_DEFAULT_DISTRIBUTION_MEMBERS,
      procoreCompanyId,
      procoreProjectId,
    ],
    queryFn: async ({ signal }) =>
      (await ObservationApi.listDefaultDistributionMembers(procoreCompanyId, procoreProjectId, { signal })).data,
  });

  const procoreObservationPotentialDistributionMemberListQuery = useQuery({
    enabled: isProcoreObservationQueryEnabled,
    queryKey: [
      QueryTopics.PROCORE,
      ProcoreQueryKeys.OBSERVATION_POTENTIAL_DISTRIBUTION_MEMBERS,
      procoreCompanyId,
      procoreProjectId,
    ],
    queryFn: async ({ signal }) =>
      (await ObservationApi.listPotentialDistributionMembers(procoreCompanyId, procoreProjectId, { signal })).data,
  });

  const procoreObservationTypeListQuery = useQuery({
    enabled: isProcoreObservationQueryEnabled,
    queryKey: [QueryTopics.PROCORE, ProcoreQueryKeys.OBSERVATION_TYPES, procoreCompanyId, procoreProjectId],
    queryFn: async ({ signal }) =>
      (await ObservationApi.listObservationTypes(procoreCompanyId, procoreProjectId, { signal })).data,
  });

  const procoreRfiPotentialAssigneeListQuery = useQuery({
    enabled: isProcoreRfiQueryEnabled,
    queryKey: [QueryTopics.PROCORE, ProcoreQueryKeys.RFI_POTENTIAL_ASSIGNEES, procoreCompanyId, procoreProjectId],
    queryFn: async ({ signal }) =>
      (await ProjectApi.listPotentialRfiAssignees(procoreCompanyId, procoreProjectId, { signal })).data,
  });

  const procoreRfiPotentialReceivedFromListQuery = useQuery({
    enabled: isProcoreRfiQueryEnabled,
    queryKey: [QueryTopics.PROCORE, ProcoreQueryKeys.RFI_POTENTIAL_RECEIVED_FROMS, procoreCompanyId, procoreProjectId],
    queryFn: async ({ signal }) =>
      (await ProjectApi.listPotentialRfiReceivedFroms(procoreCompanyId, procoreProjectId, { signal })).data,
  });

  const procoreRfiPotentialResponsibleContractorListQuery = useQuery({
    enabled: isProcoreRfiQueryEnabled,
    queryKey: [
      QueryTopics.PROCORE,
      ProcoreQueryKeys.RFI_POTENTIAL_RESPONSIBLE_CONTRACTORS,
      procoreCompanyId,
      procoreProjectId,
    ],
    queryFn: async ({ signal }) =>
      (await ProjectApi.listPotentialRfiResponsibleContractors(procoreCompanyId, procoreProjectId, { signal })).data,
  });

  const procoreSpecificationSectionListQuery = useQuery({
    enabled: isProcoreRfiQueryEnabled,
    queryKey: [QueryTopics.PROCORE, ProcoreQueryKeys.SPECIFICATION_SECTIONS, procoreCompanyId, procoreProjectId],
    queryFn: async ({ signal }) =>
      (await SpecificationSectionApi.list(procoreCompanyId, procoreProjectId, { signal })).data,
  });

  const procoreTradeListQuery = useQuery({
    enabled: isProcoreObservationQueryEnabled,
    queryKey: [QueryTopics.PROCORE, ProcoreQueryKeys.TRADES, procoreCompanyId],
    queryFn: async ({ signal }) => (await CompanyApi.listTrades(procoreCompanyId, { signal })).data,
  });

  const standardAnnotationFormRef = useRef<FormikProps<StandardAnnotationFormValues>>(null);
  const procoreObservationFormRef = useRef<FormikProps<ProcoreObservationFormValues>>(null);
  const procoreRfiFormRef = useRef<FormikProps<ProcoreRfiFormValues>>(null);

  const queryClient = useQueryClient();

  const toast = useToast();

  const annotationDescription = useMemo(() => {
    switch (annotationType) {
      case AnnotationType.PROCORE_OBSERVATION:
        return 'Observation';
      case AnnotationType.PROCORE_RFI:
        return 'RFI';
      case AnnotationType.STANDARD:
      default:
        return 'Markup';
    }
  }, [annotationType]);

  const submitButtonText = useMemo(() => {
    switch (annotationType) {
      case AnnotationType.PROCORE_RFI:
        return 'Submit RFI';
      case AnnotationType.STANDARD:
      case AnnotationType.PROCORE_OBSERVATION:
      default:
        return 'Post Markup';
    }
  }, [annotationType]);

  const saveAnnotationMutation = useMutation({
    mutationFn: ({
      values,
    }: {
      values: StandardAnnotationFormValues | ProcoreObservationFormValues | ProcoreRfiFormValues;
    }) => {
      if (!annotationTarget) {
        console.error('[AnnotationModalContainer] No annotation target coordinates passed to mutation');
        throw new Error('Annotation target coordinates not provided');
      }
      if (!image) {
        console.error('[AnnotationModalContainer] No image data passed to mutation');
        throw new Error('Image data not provided');
      }

      let promise: Promise<unknown>;
      switch (annotationType) {
        case AnnotationType.PROCORE_OBSERVATION: {
          const formValues = values as ProcoreObservationFormValues;
          const observationCreateRequestBody: ObservationCreateRequestBody = {
            attachments: formValues.attachments,
            observation: omit(formValues, ['attachments']),
            project_id: project.procore_project_id!,
          };
          promise = ObservationApi.createObservationItem(
            project.procore_company_id!,
            observationCreateRequestBody
          ).then((response: AxiosResponse<Observation>) => {
            const annotationCreateRequestBody: AnnotationCreateRequestBody = {
              ...annotationTarget,
              content: [formValues.name, formValues.description].join('\n'),
              node: node.id,
              image,
              kind: 'Procore Observation',
              procore_link: `${window.PROCORE_APP_PREFIX}/${project.procore_project_id}/project/observations/items/${response.data.id}`,
            };
            return AnnotationApi.create(annotationCreateRequestBody);
          });
          break;
        }
        case AnnotationType.PROCORE_RFI: {
          const formValues = values as ProcoreRfiFormValues;
          const rfiCreateRequestBody: RfiCreateRequestBody = {
            rfi: {
              ...omit(formValues, ['attachments', 'body']),
              question: {
                attachments: formValues.attachments,
                body: formValues.body,
              },
            },
          };
          promise = RfiApi.createRfi(
            project.procore_company_id!,
            project.procore_project_id!,
            rfiCreateRequestBody
          ).then((response: AxiosResponse<Rfi>) => {
            const annotationCreateRequestBody: AnnotationCreateRequestBody = {
              ...annotationTarget,
              content: [formValues.subject, formValues.body].join('\n'),
              node: node.id,
              image,
              kind: 'Procore RFI',
              procore_link: `${window.PROCORE_APP_PREFIX}/${procoreProjectId}/project/rfi/show/${response.data.id}`,
            };
            return AnnotationApi.create(annotationCreateRequestBody);
          });
          break;
        }
        case AnnotationType.STANDARD:
        default: {
          const formValues = values as StandardAnnotationFormValues;
          const annotationCreateRequestBody: AnnotationCreateRequestBody = {
            ...formValues,
            ...annotationTarget,
            node: node.id,
            image,
            kind: 'Observation',
            procore_link: null,
          };
          promise = AnnotationApi.create(annotationCreateRequestBody);
        }
      }

      return promise;
    },
    onError: (error: unknown) => {
      console.error(`[AnnotationModalContainer] Failed to create ${annotationDescription}`, error);
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => (
          <Toast
            {...props}
            title="Error"
            description={`Failed to create new ${annotationDescription}. Please try again later.`}
            status="error"
          />
        ),
      });
    },
    onSuccess: (response) => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => (
          <Toast {...props} title="Success" description={`${annotationDescription} created.`} status="success" />
        ),
      });

      onCreateAnnotation((response as AxiosResponse<Annotation>).data);
      onClose();

      queryClient.refetchQueries({
        queryKey: [QueryTopics.PROJECT_HIERARCHY, ProjectHierarchyQueryKeys.WALKTHROUGH_ANNOTATIONS, walkthrough.id],
      });
    },
  });

  const submit = () => {
    switch (annotationType) {
      case AnnotationType.PROCORE_OBSERVATION:
        procoreObservationFormRef.current?.submitForm();
        break;
      case AnnotationType.PROCORE_RFI:
        procoreRfiFormRef.current?.submitForm();
        break;
      case AnnotationType.STANDARD:
      default:
        standardAnnotationFormRef.current?.submitForm();
        break;
    }
  };

  /** Default set of attachments to be included with the annotation. Only applies to a Procore Observation or RFI. */
  const defaultAttachments = !image
    ? []
    : [
        {
          image,
          file: dataURItoFile(
            image,
            `snapshot-${project.name}-${floorplan.name}-${format(new Date(), 'yyyy-MM-dd')}.jpg`
          ),
        },
      ];

  const queries = (() => {
    switch (annotationType) {
      case AnnotationType.STANDARD:
        return [];
      case AnnotationType.PROCORE_OBSERVATION:
        return [
          procoreContributingBehaviorsListQuery,
          procoreContributingConditionsListQuery,
          procoreHazardListQuery,
          procoreLocationListQuery,
          procoreObservationAssigneeListQuery,
          procoreObservationDefaultDistributionMemberListQuery,
          procoreObservationPotentialDistributionMemberListQuery,
          procoreObservationTypeListQuery,
          procoreTradeListQuery,
        ];
      case AnnotationType.PROCORE_RFI:
        return [
          procoreCostCodeListQuery,
          procoreLocationListQuery,
          procoreRfiPotentialAssigneeListQuery,
          procoreRfiPotentialReceivedFromListQuery,
          procoreRfiPotentialResponsibleContractorListQuery,
          procoreSpecificationSectionListQuery,
        ];
      default:
        return [];
    }
  })();

  const someQueryHasError = queries.some((query) => query.isError);
  const someQueryIsFetching = queries.some((query) => query.isFetching);

  const getModalBodyContents = () => {
    if (someQueryIsFetching) {
      return <LoadingIndicator />;
    }

    if (someQueryHasError) {
      return (
        <Text color={theme.colors.brand.error[200]}>
          {`An error occurred. You may not have adequate permissions to create Procore ${annotationDescription}s. Please check your permissions on Procore and try again.`}
        </Text>
      );
    }

    switch (annotationType) {
      case AnnotationType.STANDARD:
        return (
          <StandardAnnotationForm
            onSubmit={(values) => saveAnnotationMutation.mutate({ values })}
            ref={standardAnnotationFormRef}
          />
        );
      case AnnotationType.PROCORE_OBSERVATION:
        return (
          <ProcoreObservationForm
            assignees={procoreObservationAssigneeListQuery.data}
            contributingBehaviors={procoreContributingBehaviorsListQuery.data}
            contributingConditions={procoreContributingConditionsListQuery.data}
            defaultAttachments={defaultAttachments}
            defaultDistributionMembers={procoreObservationDefaultDistributionMemberListQuery.data}
            hazards={procoreHazardListQuery.data}
            locations={procoreLocationListQuery.data}
            observationTypes={procoreObservationTypeListQuery.data}
            onSubmit={(values) => saveAnnotationMutation.mutate({ values })}
            potentialDistributionMembers={procoreObservationPotentialDistributionMemberListQuery.data}
            ref={procoreObservationFormRef}
            trades={procoreTradeListQuery.data}
          />
        );
      case AnnotationType.PROCORE_RFI:
        return (
          <ProcoreRfiForm
            assignees={procoreRfiPotentialAssigneeListQuery.data}
            costCodes={procoreCostCodeListQuery.data}
            defaultAttachments={defaultAttachments}
            locations={procoreLocationListQuery.data}
            onSubmit={(values) => saveAnnotationMutation.mutate({ values })}
            receivedFroms={procoreRfiPotentialReceivedFromListQuery.data}
            ref={procoreRfiFormRef}
            responsibleContractors={procoreRfiPotentialResponsibleContractorListQuery.data}
            specificationSections={procoreSpecificationSectionListQuery.data}
          />
        );
      default:
        return (
          <VStack marginBlockEnd="1rem" spacing="1rem">
            <Button
              justifyContent="flex-start"
              leftIcon={
                <Icon
                  as={LogoIcon}
                  color={theme.colors.brand.primary[600]}
                  marginInlineEnd="0.5rem"
                  height="1.5rem"
                  width="1.5rem"
                />
              }
              maxWidth="18rem"
              onClick={() => setAnnotationType(AnnotationType.STANDARD)}
              variant="outlined"
              width="100%"
            >
              OnsiteIQ Observation
            </Button>
            <Button
              data-pendo-label="Create Procore observation"
              data-pendo-topic="annotations"
              justifyContent="flex-start"
              leftIcon={<Icon as={ProcoreIcon} marginInlineEnd="0.5rem" height="1.25rem" width="1.25rem" />}
              maxWidth="18rem"
              onClick={() => setAnnotationType(AnnotationType.PROCORE_OBSERVATION)}
              variant="outlined"
              width="100%"
            >
              Procore Observation
            </Button>
            <Button
              data-pendo-label="Create Procore RFI"
              data-pendo-topic="annotations"
              justifyContent="flex-start"
              leftIcon={<Icon as={ProcoreIcon} marginInlineEnd="0.5rem" height="1.25rem" width="1.25rem" />}
              maxWidth="18rem"
              onClick={() => setAnnotationType(AnnotationType.PROCORE_RFI)}
              variant="outlined"
              width="100%"
            >
              Procore RFI
            </Button>
          </VStack>
        );
    }
  };

  return (
    <Modal closeOnOverlayClick={false} isCentered isOpen={Boolean(isOpen)} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader alignItems="center" display="flex">
          <Center
            backgroundColor={theme.colors.brand.primary[100]}
            borderRadius="100%"
            color={theme.colors.brand.gray[800]}
            height="2rem"
            marginInlineEnd="0.75rem"
            width="2rem"
          >
            <Icon aria-hidden as={MarkupsIcon} height="1.25rem" width="1.25rem" />
          </Center>
          {`New ${annotationDescription}`}
        </ModalHeader>
        {Boolean(annotationType) && (
          <Select
            aria-label="Markup type"
            marginBlockStart="0.25rem"
            maxWidth="12rem"
            minHeight="unset !important"
            position="absolute"
            onChange={(event) => setAnnotationType(Number(event.target.value))}
            right="3.75rem"
            value={annotationType}
          >
            <option key="annotation-type-option-oiq" value={AnnotationType.STANDARD}>
              OnsiteIQ
            </option>
            <option
              key="annotation-type-option-procore-observation"
              disabled={!isProcoreEnabled}
              value={AnnotationType.PROCORE_OBSERVATION}
            >
              Procore (Observation)
            </option>
            <option
              key="annotation-type-option-procore-rfi"
              disabled={!isProcoreEnabled}
              value={AnnotationType.PROCORE_RFI}
            >
              Procore (RFI)
            </option>
          </Select>
        )}
        {/* Rendered the close button after the header for a correct tab sequence. */}
        <ModalCloseButton
          data-pendo-label="Modal close"
          data-pendo-topic="annotations"
          _focus={{
            borderColor: theme.colors.white,
            borderRadius: theme.radii.input,
            outline: '1px solid var(--primary-500)',
            outlineOffset: 0,
          }}
          _focusVisible={{
            borderColor: theme.colors.white,
            borderRadius: theme.radii.input,
            outline: '1px solid var(--primary-500)',
            outlineOffset: 0,
          }}
        />
        <ModalBody>{getModalBodyContents()}</ModalBody>
        {annotationType && !someQueryHasError && !someQueryIsFetching && (
          <ModalFooter>
            <Button mr={3} onClick={onClose} variant="mediumEmphasis">
              Cancel
            </Button>
            <Button
              data-pendo-label="Submit create annotation"
              data-pendo-topic="annotations"
              isLoading={saveAnnotationMutation.isLoading}
              onClick={submit}
              variant="highEmphasis"
            >
              {submitButtonText}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default AnnotationModalContainer;
