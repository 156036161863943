// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck Ignore this file completely; Redux is doomed.
import { List } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import union from 'lodash/union';

import { OSIQEntity } from '../@types/OnSiteIQ';
import { Entities, EntitiesFixed } from '../@types/redux/store';
import { ANNOTATION_DELETED, REPLY_DELETED } from '../constants/actionTypes';
import { isEmpty } from '../utils';

export interface EntityAction {
  type: string;
  kind: string | { entityKind: string };
  entities?: {
    entities: Entities;
    result: number[];
  };
  entity?: OSIQEntity;
  walkthrough?: string;
  id?: string;
  search?: string;
  userId?: number;
}

export const initialEntitiesState: Entities = {
  projects: {},
  floorplans: {},
  walkthroughs: {},
  nodes: {},
  annotations: {},
  // This is here so that normalizr doesn't break, but this is handled by RQ now.
  oshaCode: {},
  replies: {},
  users: {},
  timetravelPairs: {},
  matchingFloorplans: {},
};

function isEntityFixedType(entities: Entities | EntitiesFixed, typeString: string): typeString is keyof EntitiesFixed {
  return typeString in entities;
}

/* Remove an entity such that it is removed from any parent's list that
 * contains the entity's id as well */
function removeEntity(state: Entities, entityId: string, type: string, parentType: string) {
  const entities = { ...state };

  // Remove the entity itself
  // Still need to remove reference from parent
  if (isEntityFixedType(entities, type)) {
    delete (entities as any)[type][entityId];
  }

  const parents = isEntityFixedType(entities, parentType) ? entities[parentType] : null;

  // NOTE regarding following eslint-disable
  // Need to find a way to comfortably destructure arrays without triggering it
  if (parents) {
    Object.entries(parents)
      .filter(([k]) => k !== 'ids')
      // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
      .forEach(([_, parent]) => {
        if (!parent[type]) {
          console.warn(`During entity removal, entity type ${type} not found on parent entity.`);
          return;
        }

        // TODO not a fan of the following code
        if (parent[type].includes(entityId)) {
          const idx = parent[type].indexOf(entityId);
          parent[type] = parent[type].slice(0, idx).concat(parent[type].slice(idx + 1));
        }
      });
  }

  return entities;
}

// This reducer mutates state! To prevent initialEntitiesState from being mutated, seed the reducer with a deep copy
// of the initial data. Any execution flows which reset state should also make a deep copy until this is fixed.
const initialState = cloneDeep(initialEntitiesState);

export default function reducer(state: Entities | undefined, action: EntityAction) {
  if (!state) {
    return initialState;
  }

  // If the action includes an "entities" key, save them inside the structure. For example, if the action contained a
  // JSON structure like:
  // { "entities": { "walkthroughs": { "1234": { "id": "1234", "when": "2022-09-12T21:46:00Z" } } } }
  // We would merge this data for walkthrough 1234 into the store.
  if (action.entities && !isEmpty(action.entities.result)) {
    const actionEntities: EntitiesFixed = action.entities.entities as any;
    const newState = { ...state } as any;

    for (const [entityType, entitiesOfType] of Object.entries(actionEntities)) {
      for (const [entityId, entityValue] of Object.entries(entitiesOfType)) {
        // Only deeps copy and change modified keys.
        if (!isEqual(state[entityType][entityId], entityValue)) {
          newState[entityType][entityId] = { ...state[entityType][entityId], ...entityValue };
        }
      }
    }

    return newState;
  }

  /* Update or add an entity. Add it to the list of ids of that entity type
   * and add it to the {id: entity} mapping. */
  if (action.entity) {
    const kind = typeof action.kind === 'object' ? action.kind.entityKind : action.kind;
    const entities = isEntityFixedType(state as any, kind) ? { ...state[kind] } : null;
    if (entities === null) {
      return state;
    }
    entities[action.entity.id] = action.entity;

    const newState = { ...state, [kind]: entities };
    for (const [prop, val] of Object.entries(action.entity)) {
      const key = `${prop}s`;
      if (state[key]?.[val]?.[kind]) {
        newState[key][val][kind] = union(state[key][val][kind] as List<any>, [action.entity.id]);
      }
    }

    return newState;
  }

  /* TODO -- merge deletion of things together */
  if (action.type === ANNOTATION_DELETED) {
    return action.id ? removeEntity(state, action.id, 'annotations', 'floorplans') : state;
  }

  if (action.type === REPLY_DELETED) {
    return action.id ? removeEntity(state, action.id, 'replies', 'annotations') : state;
  }

  return state;
}
