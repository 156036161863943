import { AppAction } from '../@types/redux/appActions';
import { App } from '../@types/redux/store';
import {
  CLOSE_DRAWER,
  SAVE_FROM_LOCATION,
  SET_ACTIVE_DRAWER_ITEM,
  SET_SIDEBAR_ACTIONS_LIST,
  SHOW_DRAWER,
  TOGGLE_DRAWER,
} from '../constants/actionTypes';

export const initialAppState: App = {
  activeDrawerItem: undefined,
  drawer: undefined,
  fromLocation: undefined,
  search: {},
  sideBarActionsList: [],
};

export default function reducer(state: App | undefined, action: AppAction): App {
  if (!state) {
    return initialAppState;
  }

  switch (action.type) {
    case CLOSE_DRAWER:
      return { ...state, drawer: undefined };

    case SAVE_FROM_LOCATION:
      return { ...state, fromLocation: action.fromLocation };

    case SET_ACTIVE_DRAWER_ITEM:
      return { ...state, activeDrawerItem: action.activeDrawerItem };

    case SET_SIDEBAR_ACTIONS_LIST:
      return { ...state, sideBarActionsList: action.sideBarActionsList };

    case SHOW_DRAWER:
      return { ...state, drawer: action.drawer };

    case TOGGLE_DRAWER:
      return { ...state, drawer: state.drawer === action.drawer ? undefined : action.drawer };

    default:
      return state;
  }
}
