export const SET_LOGIN_REQUIRED = 'auth/load';
export const LOGIN = 'auth/login';
export const SET_USER_PROFILE = 'auth/set_user_profile';
export const LOGOUT = 'auth/logout';
export const SET_PROCORE_TOKEN = 'auth/set_procore_token';
export const SET_PROCORE_ME = 'auth/set_procore_me';
export const CLEAR_PROCORE = 'auth/clear_procore';

export const NETWORK_REQ = 'onsiteiq/network/request';
export const NETWORK_ERR = 'onsiteiq/network/error';
export const NETWORK_SUCCESS = 'onsiteiq/network/success';

export const REPLY_CREATED = 'onsiteiq/replies/created';
export const REPLY_UPDATED = 'onsiteiq/replies/updated';
export const REPLY_DELETED = 'onsiteiq/replies/deleted';

export const ANNOTATION_CREATED = 'onsiteiq/annotations/created';
export const ANNOTATION_DELETED = 'onsiteiq/annotation/deleted';
export const ANNOTATION_UPDATED = 'onsiteiq/annotation/updated';

export const ADD_NOTIFICATION = 'notifications/add';
export const REMOVE_NOTIFICATION = 'notifications/remove';

export const CLOSE_DRAWER = 'onsiteiq/drawer/close';
export const SET_ACTIVE_DRAWER_ITEM = 'onsiteiq/drawer/setactiveitem';
export const SHOW_DRAWER = 'onsiteiq/drawer/show';
export const TOGGLE_DRAWER = 'onsiteiq/drawer/toggle';

export const SET_SIDEBAR_ACTIONS_LIST = 'onsiteiq/sidebar/set_sidebar_actions_list';

export const SAVE_FROM_LOCATION = 'onsiteiq/fromlocation/save';
