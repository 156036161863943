import { ReactElement, useState } from 'react';

import { LoadingIndicator, SearchInput } from '../..';
import { Floorplan as LegacyFloorplan, Project as LegacyProject, MatchingFloorplan } from '../../../@types/OnSiteIQ';
import { Floorplan } from '../../../@types/api/v0/rest/Floorplan';
import { Project } from '../../../@types/api/v0/rest/Project';
import { naturalSort } from '../../../utils';
import { DrawerBody, DrawerControls, DrawerHeader, SidebarActionDrawer } from '../../SidebarActionDrawer';
import FloorplanCard from './FloorplanCard';

export interface ChangeFloorplanDrawerProps {
  /** When any floorplan is clicked, call this handler if provided. */
  action?: () => void;
  /** Close the drawer. */
  close: () => void;
  /** If provided, the drawer header will show this as the current walkthrough's date. */
  date?: string;
  /** Currently-selected floorplan. */
  floorplan: Floorplan | LegacyFloorplan;
  /**
   * Show a loading spinner. Useful in cases when the parent component will change the drawer's content soon, and to
   * prevent the user from changing the current floorplan in the meantime. For example, when the 360º viewer is
   * transitioning between ground nodes on a slow connection, the user should not be able to change the floorplan
   * mid-stream.
   */
  isLoading?: boolean;
  /** When the user clicks on a given floorplan, bring them to a comparably recent walkthrough of the selected floor. */
  matches?: MatchingFloorplan[];
  /** Current project instance. */
  project: Project | LegacyProject;
  /** Other floorplans for this project. */
  projectFloorplans: (Floorplan | LegacyFloorplan)[];
  /** Whether or not the drawer is visible. */
  show: boolean;
}

const ChangeFloorplanDrawer = (props: ChangeFloorplanDrawerProps): ReactElement => {
  const [searchInput, setSearchInput] = useState('');

  const floorplans = naturalSort(
    props.projectFloorplans.filter((pf) => pf.name.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())),
    'name'
  );
  const searchDisabled = (props.projectFloorplans?.length ?? 0) === 0 || props.isLoading;

  return (
    <SidebarActionDrawer show={props.show} close={props.close}>
      <DrawerHeader project={props.project} date={props.date} />
      <DrawerControls>
        <SearchInput disabled={searchDisabled} onChange={setSearchInput} value={searchInput} variant="medium" />
      </DrawerControls>
      <DrawerBody>
        {props.isLoading && <LoadingIndicator />}
        {!props.isLoading && (
          <>
            {floorplans.map((f) => (
              <FloorplanCard
                key={f.id}
                action={props.action}
                active={f.id === props.floorplan.id}
                currentDate={props.date}
                floorplan={f}
                match={props.matches && props.matches.find((m) => m.floorplan === f.id)}
                project={props.project}
              />
            ))}
          </>
        )}
      </DrawerBody>
    </SidebarActionDrawer>
  );
};

export default ChangeFloorplanDrawer;
