import { Box, Text } from '@chakra-ui/react';

import { Me } from '../../../@types/OnSiteIQ';
import { Detection, DetectionVote } from '../../../@types/api/v0/rest/Detection';
import theme from '../../../theme';
import SearchDetectionItem from './SearchDetectionItem';

import classes from './Search.module.scss';

export interface SearchDetectionListProps {
  /** Currently-selected search detection. */
  currentDetection?: Detection;
  /** List of search detections of the previously-selected detection type for this walkthrough. */
  detections: Detection[];
  /** Handler called when the user clicks the "View 360" button on a search detection. */
  on360Click: (detection: Detection) => void;
  /** Handler called when the user selects a detection. */
  onDetectionSelect: (detection: Detection) => void;
  /** Handler called when the user votes on a detection. A vote value of `L` means "like", `D` means "dislike". */
  onDetectionVote: (detection: Detection, vote: 'L' | 'D') => void;
  /** Current user details. */
  user?: Me | undefined;
}

const SearchDetectionList = (props: SearchDetectionListProps) => {
  const { currentDetection, detections, on360Click, onDetectionSelect, onDetectionVote, user } = props;

  const getUserVotes = (votes: DetectionVote[]) => votes.find((vote) => vote.user_id === user?.id);

  if (detections.length === 0) {
    return (
      <Text color={theme.colors.brand.gray[600]} marginBlock="0.5rem" marginInline="2rem" textAlign="left">
        No results found.
      </Text>
    );
  }

  return (
    <Box className={classes.imageCardGrid}>
      {detections.map((detection, index) => (
        <SearchDetectionItem
          detection={detection}
          isActive={currentDetection ? currentDetection?.id === detection.id : index === 0}
          key={detection.id}
          on360Click={() => on360Click(detection)}
          onDetectionSelect={onDetectionSelect}
          onDetectionVote={onDetectionVote}
          userVote={getUserVotes(detection.votes)}
        />
      ))}
    </Box>
  );
};

export default SearchDetectionList;
