import { Box } from '@chakra-ui/react';
import classNames from 'classnames';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { Project as LegacyProject } from '../@types/OnSiteIQ';
import { Project } from '../@types/api/v0/rest/Project';
import { formatIsoDate } from '../utils/dateUtils';
import { DoubleBackChevronIcon } from './Icon';

import classes from './SidebarActionDrawer.module.scss';

interface DrawerProps {
  children: ReactNode;
  show: boolean;
  className?: string;
  close?: () => void;
}

interface DrawerHeaderProps {
  project: Project | LegacyProject;
  date?: string;
}

interface DrawerSimpleSectionProps {
  children: ReactNode;
}

/**
 * Drawer used exclusively for sidebar actions.
 * Will be replaced and consolidated into the updated drawer in Nav 2.0
 */
export const SidebarActionDrawer = (props: DrawerProps): ReactElement => {
  const [shouldEnter, setShouldEnter] = useState<boolean>(false);

  useEffect(() => {
    if (props.show && !shouldEnter) {
      setShouldEnter(true);
    }
    if (!props.show && shouldEnter) {
      setShouldEnter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  return (
    <CSSTransition in={shouldEnter} timeout={{ enter: 200, exit: 250 }} classNames="drawer" unmountOnExit>
      <div className={classNames(classes.drawer, 'drawer', props.className)}>
        {props.close && (
          <button
            className={classes.closeButton}
            data-pendo-label="Close"
            data-pendo-topic="drawer"
            onClick={props.close}
            type="button"
          >
            <DoubleBackChevronIcon aria-label="Close" />
          </button>
        )}
        {props.children}
      </div>
    </CSSTransition>
  );
};

export const DrawerHeader = (props: DrawerHeaderProps): ReactElement => {
  const displayDateTime = formatIsoDate(props.date)?.formattedDateTime;
  return (
    <div className={classes.drawerHeader}>
      <span className={classes.preTitleText}>
        {props.project.address_line} | {props.project.city}
      </span>
      <span className={classes.title}>{props.project.name}</span>
      {props.date && (
        <Box paddingTop={6} flexDirection="column">
          <div className={classes.datePrefix}>Current walkthrough:</div>
          <time className={classes.displayDate}>{displayDateTime}</time>
        </Box>
      )}
    </div>
  );
};

export const DrawerControls = (props: DrawerSimpleSectionProps): ReactElement => (
  <div className={classes.drawerControls}>{props.children}</div>
);

export const DrawerBody = (props: DrawerSimpleSectionProps): ReactElement => (
  <div className={classes.drawerBody}>{props.children}</div>
);
