import { Badge, Icon } from '@chakra-ui/react';
import { ChangeEvent, Component } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { Button } from '../../';
import {
  DenormalizedAnnotation as LegacyDenormalizedAnnotation,
  DenormalizedReply as LegacyDenormalizedReply,
} from '../../../@types/OnSiteIQ';
import { Annotation, AnnotationReply } from '../../../@types/api/v0/rest/Annotation';
import { LegacyAction, Store } from '../../../@types/redux/store';
import { deleteReply, updateReply } from '../../../actions/projects';
import theme from '../../../theme';
import { DeleteIcon, EditIcon } from '../../Icon';
import ContentBox from './ContentBox';
import DeleteConfirmation from './DeleteConfirmation';
import SafetyStatusControl from './SafetyStatusControl';

import classes from './Annotations.module.scss';

interface Props extends PropsFromRedux {
  annotation: Annotation | LegacyDenormalizedAnnotation;
  reply: AnnotationReply | LegacyDenormalizedReply;
}

interface State {
  editing: boolean;
  deleting: boolean;
  pending: string;
  safetyMitigation: 'C' | 'O' | null;
}

const MitigationBadge = ({ status }: { status: 'C' | 'O' | null }) => {
  switch (status) {
    case 'O':
      return <Badge colorScheme="red">Open</Badge>;
    case 'C':
      return <Badge colorScheme="green">Closed</Badge>;
    default:
      return null;
  }
};

class Reply extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editing: false,
      deleting: false,
      pending: props.reply.content,
      safetyMitigation: props.reply.safety_mitigation,
    };
  }

  updateReply = (id: string) => async () => {
    await this.props.updateReply(id, { content: this.state.pending, safety_mitigation: this.state.safetyMitigation });
    this.setState({ editing: false });
  };

  render() {
    const { annotation, reply } = this.props;
    const showSafetyStatusControl = this.state.editing && ['H', 'M', 'L'].includes(annotation.safety_status ?? '');

    return (
      <div className={classes.reply}>
        <div aria-hidden className={classes.userAvatar}>
          <span className={classes.circle}>{reply.poster.name[0]}</span>
          <span className={classes.divider} />
        </div>
        <div className={classes.replyBody}>
          <div className={classes.author}>
            {reply.poster.first_name} {reply.poster.last_name}
          </div>
          {!this.state.editing && (
            <div className={classes.editControls}>
              <Button
                className={classes.editButton}
                color={theme.colors.brand.primary[800]}
                onClick={this.toggleEdit}
                variant="ghost"
              >
                <Icon aria-label="Edit" as={EditIcon} />
              </Button>
              <Button className={classes.deleteButton} onClick={this.toggleDelete} variant="ghost">
                <DeleteIcon aria-label="Delete" />
              </Button>
            </div>
          )}
          {!this.state.editing && (
            <div className={classes.replyBadge}>
              <MitigationBadge status={reply.safety_mitigation} />
            </div>
          )}
          <ContentBox
            content={this.state.pending}
            editing={this.state.editing}
            formExtras={
              showSafetyStatusControl && (
                <SafetyStatusControl
                  onChange={(safetyMitigation) => this.setState({ safetyMitigation })}
                  value={this.state.safetyMitigation}
                />
              )
            }
            onCancelEdit={this.toggleEdit}
            onChange={this.onChange}
            onFinishEdit={this.updateReply(reply.id)}
            textAreaLabel="Reply"
          />
        </div>
        {this.state.deleting && (
          <DeleteConfirmation
            onCancel={this.toggleDelete}
            onDelete={() => this.props.deleteReply(this.props.reply.id)}
            title="Delete Reply"
          >
            <p className={classes.confirmationDisclaimer}>Are you sure you want to delete this reply?</p>
          </DeleteConfirmation>
        )}
      </div>
    );
  }

  onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ pending: e.target.value });
  };

  toggleEdit = () => {
    this.setState((prevState) => ({
      editing: !prevState.editing,
      pending: this.props.reply.content,
      safetyMitigation: this.props.reply.safety_mitigation,
    }));
  };

  toggleDelete = () => {
    this.setState((prevState) => ({ deleting: !prevState.deleting }));
  };
}

const mapDispatchToProps = (dispatch: ThunkDispatch<Store, unknown, Action<LegacyAction>>) => ({
  deleteReply: (id: string) => dispatch(deleteReply(id)),
  updateReply: (id: string, data: { content: string; safety_mitigation: 'C' | 'O' | null }) =>
    dispatch(updateReply(id, data)),
});

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Reply);
