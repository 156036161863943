import { AspectRatio, Badge, Button, Icon } from '@chakra-ui/react';

import { DenormalizedAnnotation as LegacyDenormalizedAnnotation } from '../../../@types/OnSiteIQ';
import { Annotation } from '../../../@types/api/v0/rest/Annotation';
import theme from '../../../theme';
import { Degree360Icon } from '../../Icon';

import classes from './Annotations.module.scss';

interface Props {
  annotation: Annotation | LegacyDenormalizedAnnotation;
  onViewAnnotation: (annotation: Annotation | LegacyDenormalizedAnnotation) => void;
}

const safetyOptions = new Map([
  ['H', { category: 'Safety', desc: 'High Risk', color: 'red' }],
  ['M', { category: 'Safety', desc: 'Medium Risk', color: 'orange' }],
  ['L', { category: 'Safety', desc: 'Low Risk', color: 'yellow' }],
  ['B', { category: 'Safety', desc: 'Best Practice Suggestion', color: 'purple' }],
  ['P', { category: 'Safety', desc: 'Positive Observation', color: 'green' }],
]);

const progressOptions = new Map([
  ['B', { category: 'Progress', desc: 'Behind Schedule', color: 'red' }],
  ['O', { category: 'Progress', desc: 'On Schedule', color: 'purple' }],
  ['A', { category: 'Progress', desc: 'Ahead of Schedule', color: 'green' }],
]);

const AnnotationImage = ({ annotation, onViewAnnotation }: Props) => {
  function onView360Click() {
    onViewAnnotation(annotation);
  }

  const prepareBadges = () => {
    const { safety_status, progress_status } = annotation;

    // If we have either of these set but they aren't in the map that's a problem
    const progress = progressOptions.get(progress_status ?? '');
    if (progress_status && !progress) {
      console.warn(`[AnnotationImage] Unknown progress_status: ${progress_status}`);
    }

    const safety = safetyOptions.get(safety_status ?? '');
    if (safety_status && !safety) {
      console.warn(`[AnnotationImage] Unknown safety_status: ${safety_status}`);
    }

    // This filter will remove any lookups that weren't found
    return [progress, safety].filter((s) => s);
  };

  // NOTE: using badge description as a key here
  // I don't think there will ever be two badges with the same description
  // And even if there were it shouldn't be much of an issue
  // ^^ lol ok dude
  const badges = prepareBadges().map((badge) => (
    <Badge colorScheme={badge?.color} key={badge?.desc}>
      {`${badge?.category}: ${badge?.desc}`}
    </Badge>
  ));

  const tradeBadges = annotation.trades.map((t) => (
    <Badge color="blue" key={t.id}>
      {t.category}
    </Badge>
  ));

  return (
    <div className={classes.thumbnail}>
      <AspectRatio ratio={4 / 3}>
        <img alt="" src={annotation?.image} />
      </AspectRatio>
      <div className={classes.badges}>
        <div>{badges}</div>
        <div>{tradeBadges}</div>
      </div>
      <Button
        backgroundColor={theme.colors.white}
        border="1px solid var(--primary-500)"
        borderRadius="100%"
        color={theme.colors.brand.primary[500]}
        data-pendo-label="Open 360"
        data-pendo-topic="annotations"
        height="2.375rem"
        lineHeight={1}
        minWidth="2.375rem"
        onClick={onView360Click}
        padding="0.375rem"
        position="absolute"
        right="0.5rem"
        top="0.5rem"
        variant="unstyled"
        width="2.375rem"
        _hover={{
          backgroundColor: theme.colors.white,
          borderColor: theme.colors.brand.primary[700],
          color: theme.colors.brand.primary[700],
        }}
      >
        <Icon aria-label="Open in 360 degree viewer" as={Degree360Icon} height="1.5rem" width="1.5rem" />
      </Button>
    </div>
  );
};

export default AnnotationImage;
