import { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setSidebarActionsList } from '../actions/app';
import { Content, InternalLayout, PageTitleCard, Pane, PaneBody, PaneHead } from '../components';
import IntegrationSettings from '../components/Settings/IntegrationSettings';
import NotificationSettings from '../components/Settings/NotificationSettings';
import ProfileSettings from '../components/Settings/ProfileSettings';

const Settings = (): ReactElement => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSidebarActionsList([]));
  }, [dispatch]);

  return (
    <InternalLayout>
      <Content applyGutters>
        <PageTitleCard title="Settings" />
        <Pane>
          <PaneHead headingLevel={2} title="Profile and Security" />
          <PaneBody>
            <ProfileSettings />
          </PaneBody>
        </Pane>
        <Pane>
          <PaneHead headingLevel={2} title="App Integrations" />
          <PaneBody>
            <IntegrationSettings />
          </PaneBody>
        </Pane>
        <Pane>
          <PaneHead headingLevel={2} title="Notification Preferences" />
          <PaneBody>
            <NotificationSettings />
          </PaneBody>
        </Pane>
      </Content>
    </InternalLayout>
  );
};

export default Settings;
