import { Checkbox, FormControl, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay } from '@chakra-ui/react';
import { differenceInDays, endOfDay } from 'date-fns';
import { ReactElement, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';

import { Button } from '../../';
import { maybePluralize } from '../../../utils';
import { formatIsoDate } from '../../../utils/dateUtils';

import classes from './ChangeFloorplan.module.scss';

export interface DateWarningProps {
  floorplanName: string;
  fromDate: string;
  onAcknowledge: () => void;
  onCancelOrClose: () => void;
  open: boolean;
  toDate: string;
}

const DateWarning = (props: DateWarningProps): ReactElement | null => {
  const [ackChecked, setAckChecked] = useState(false);

  const distance = useMemo(() => {
    const diff = differenceInDays(new Date(props.toDate), new Date(props.fromDate));
    const absDiff = Math.abs(diff);

    if (absDiff < 1) {
      return undefined;
    }

    const age = diff < 0 ? 'older' : 'more recent';
    if (absDiff <= 50) {
      return `${absDiff} ${maybePluralize(absDiff, 'day')} ${age}`;
    } else if (absDiff > 50 && absDiff <= 60) {
      return `almost 2 months ${age}`;
    } else if (absDiff > 60 && absDiff <= 75) {
      return `over 2 months ${age}`;
    } else if (absDiff > 75 && absDiff <= 90) {
      return `almost 3 months ${age}`;
    } else {
      return `several months ${age}`;
    }
  }, [props.fromDate, props.toDate]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [_, setCookies] = useCookies(['date-warning-acknowledged']);

  const onAccept = () => {
    if (ackChecked) {
      setCookies('date-warning-acknowledged', true, {
        path: '/',
        expires: endOfDay(new Date()),
        sameSite: 'strict',
      });
    }
    props.onAcknowledge();
  };

  // This component shouldn't even be rendered if the difference between the two dates is less than 1 day. Regardless,
  // add an extra layer of safety.
  if (!distance) {
    return null;
  }

  return (
    <Modal closeOnOverlayClick={false} isCentered isOpen={props.open} onClose={props.onCancelOrClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <p>
            The nearest available date on the floor plan you selected ({props.floorplanName}) is{' '}
            <strong>{distance}</strong> ({formatIsoDate(props.toDate)?.formattedDateTime}). Would you like to continue?
          </p>
          <FormControl className={classes.reminderFormLabel}>
            <Checkbox isChecked={ackChecked} onChange={() => setAckChecked((current) => !current)}>
              Don't remind me again today
            </Checkbox>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={props.onCancelOrClose} variant="mediumEmphasis">
            Cancel
          </Button>
          <Button onClick={onAccept} variant="highEmphasis">
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DateWarning;
