import classNames from 'classnames';
import noop from 'lodash/noop';
import { ReactElement, useEffect, useRef } from 'react';

import { formatIsoDate } from '../../../utils/dateUtils';
import { TimeTravelOption } from './TimeTravelDrawer';

import classes from './TimeTravel.module.scss';

export interface TimeTravelListItemProps {
  /** ID of the current walkthrough. */
  currentWalkthroughId: number;
  /** Handler to call when the user selects a time travel option. */
  onSelectOption: (option: TimeTravelOption) => void;
  /** The current time travel option. */
  option: TimeTravelOption;
  /** Flag indicating whether or not to render the time travel option's thumbnail. */
  showThumbnail?: boolean;
}

const TimeTravelListItem = (props: TimeTravelListItemProps): ReactElement => {
  const { currentWalkthroughId, onSelectOption, option, showThumbnail } = props;

  const ref = useRef<HTMLButtonElement>(null);

  const formattedDateSegments = formatIsoDate(option.when);
  const title = formattedDateSegments?.formattedDate;
  const subtitle = formattedDateSegments?.formattedTime;

  /**
   * When the option is a `TimeTravelPair` instance, it will have a `walkthrough` property, so we can check to see that
   * it matches the current walkthrough. Otherwise, the option is a `Walkthrough` instance.
   */
  const active =
    'walkthrough' in option ? option.walkthrough === currentWalkthroughId : option.id === currentWalkthroughId;

  const onCardClick = () => {
    if (active) {
      return;
    }

    onSelectOption(option);
  };

  useEffect(() => {
    if (ref.current && active) {
      ref.current.scrollIntoView({
        block: 'center',
      });
    }
  }, [active, ref]);

  return (
    <button
      className={classNames(classes.card, active && classes.cardActive)}
      data-pendo-label="Select time travel date"
      data-pendo-topic="drawer"
      onClick={active ? noop : onCardClick}
      ref={ref}
    >
      {showThumbnail && 'thumbnail' in option && <img alt="" className={classes.thumbnail} src={option.thumbnail} />}
      <span className={classes.title}>{title}</span>
      <span className={classes.subtitle}>{subtitle}</span>
    </button>
  );
};

export default TimeTravelListItem;
