import classNames from 'classnames';
import throttle from 'lodash/throttle';
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button } from '../';
import { DenormalizedAnnotation, Floorplan, Node, Walkthrough } from '../../@types/OnSiteIQ';
import { Store } from '../../@types/redux/store';
import { setActiveDrawerItem, showDrawer } from '../../actions/app';
import { ErrorIcon } from '../Icon';
import LoadingIndicator from '../LoadingIndicator';
import Renderer, { RendererInstance, SceneParams } from './Renderer';
import TextureManager from './TextureManager';
import { TimingConstants, ViewerEvents } from './constants';

import classes from './PanographViewer.module.scss';

export interface Props {
  annotations: DenormalizedAnnotation[];
  floorplan: Floorplan;
  lookTarget?: { x: number; y: number; z: number };
  node: Node;
  onSnapshotAvailable: (dataUrl: string) => void;
  sceneIsInitializing: boolean;
  sceneIsUpdating: boolean; // TODO: is this needed at this level?
  setAnnotationTarget: (nextAnnotationTarget?: { x: number; y: number; z: number }) => void;
  setLookTarget: (nextLookTarget?: { x: number; y: number; z: number }) => void;
  setMapDirection: (nextMapDirection: { x: number; y: number }) => void;
  setNode: (node: Node) => void;
  setSceneIsInitializing: (nextsceneIsInitializing: boolean) => void;
  setSceneIsUpdating: (nextSceneIsUpdating: boolean) => void;
  walkthrough: Walkthrough;
  zoomFieldOfView: number;
  zoomIn: (increment: number) => void;
  zoomOut: (increment: number) => void;
}

export interface PanographViewerInstance {
  clearBrightness: () => void;
  focus: () => void;
  requestSnapshot: () => void;
  setBrightness: (level: number) => void;
  setMarking: (marking: boolean) => void;
}

const PanographViewer = forwardRef((props: Props, forwardedRef: ForwardedRef<PanographViewerInstance>) => {
  const {
    annotations,
    floorplan,
    lookTarget,
    node,
    onSnapshotAvailable,
    sceneIsInitializing,
    setAnnotationTarget,
    setLookTarget,
    setMapDirection,
    setNode,
    setSceneIsInitializing,
    setSceneIsUpdating,
    walkthrough,
    zoomFieldOfView,
    zoomIn,
    zoomOut,
  } = props;

  const dispatch = useDispatch();
  const currentDrawer = useSelector((state: Store) => state.app.drawer);

  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [renderer, setRenderer] = useState<RendererInstance>();
  const [sceneParams, setSceneParams] = useState<SceneParams>();
  // In addition to the flags received as props, we need a separate flag to be able to show a loading spinner during
  // long loads. This most often takes effect on throttled connections.
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const setMapDirectionThrottled = useMemo(
    () =>
      throttle(
        (mapDirection: { x: number; y: number }) => setMapDirection(mapDirection),
        TimingConstants.RATE_LIMIT_MAP_DIRECTION_CHANGE_EVENT,
        { trailing: true }
      ),
    [setMapDirection]
  );

  const onChangeCameraDirection = useCallback(
    (mapDirection: { x: number; y: number }) => setMapDirectionThrottled(mapDirection),
    [setMapDirectionThrottled]
  );

  const onClickAnnotation = useCallback(
    (annotation: DenormalizedAnnotation) => {
      if (currentDrawer !== 'annotations') {
        dispatch(showDrawer('annotations'));
      }

      dispatch(setActiveDrawerItem(annotation.id));
    },
    [currentDrawer, dispatch]
  );

  const onError = useCallback(() => {
    setErrorMessage('Failed to load walkthrough image. Please try again later.');
    renderer?.stopRendering();
  }, [renderer]);

  const onLookTransitionComplete = useCallback(() => setLookTarget(undefined), [setLookTarget]);

  const onMarkPoint = useCallback(
    (point: { x: number; y: number; z: number }) => {
      setAnnotationTarget(point);
      setLookTarget(point);
    },
    [setAnnotationTarget, setLookTarget]
  );

  const onSceneUpdateComplete = useCallback(() => {
    setSceneIsUpdating(false);
    setShowLoader(false);
  }, [setSceneIsUpdating]);

  const onSceneUpdateStart = useCallback(
    (nextShowLoader: boolean) => {
      setSceneIsUpdating(true);
      setShowLoader(nextShowLoader);
    },
    [setSceneIsUpdating]
  );

  const onZoom = useCallback(
    (fieldOfViewDiff: number) => {
      const handler = fieldOfViewDiff < 0 ? zoomIn : zoomOut;
      handler(Math.abs(fieldOfViewDiff));
    },
    [zoomIn, zoomOut]
  );

  const reload = () => {
    history.go(0);
  };

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    const webGLContext = canvasRef.current.getContext('webgl') ?? canvasRef.current.getContext('experimental-webgl');
    const webGLSupported = webGLContext?.constructor?.name === 'WebGLRenderingContext';
    if (!webGLSupported) {
      setErrorMessage(
        'Failed to load 360º viewer. Your device does not support WebGL. Please enable WebGL if it is disabled, or try again with a different browser.'
      );
      return;
    }

    const textureManager = new TextureManager();
    const rendererInstance = new Renderer({ canvas: canvasRef.current, textureManager });

    (async () => {
      try {
        await rendererInstance.initialize();
        setRenderer(rendererInstance);
        setSceneIsInitializing(false);
      } catch (error) {
        console.error('[PanographViewer] Failed to initialize scene', error);
        setErrorMessage(
          'Failed to load 360º viewer. Please try again later or reach out to our Customer Success team at customersuccess@onsiteiq.io.'
        );
      }
    })();

    // eslint-disable-next-line consistent-return
    return () => {
      if (rendererInstance) {
        rendererInstance.dispose();
        console.debug('[PanographViewer] Renderer disposed.');
      }
    };
  }, [setSceneIsInitializing]);

  useEffect(() => {
    if (!renderer) {
      return;
    }

    const handlers = {
      [ViewerEvents.ON_CHANGE_CAMERA_DIRECTION]: onChangeCameraDirection,
      [ViewerEvents.ON_CHANGE_NODE]: setNode,
      [ViewerEvents.ON_CLICK_ANNOTATION]: onClickAnnotation,
      [ViewerEvents.ON_ERROR]: onError,
      [ViewerEvents.ON_LOOK_TRANSITION_COMPLETE]: onLookTransitionComplete,
      [ViewerEvents.ON_MARK_POINT]: onMarkPoint,
      [ViewerEvents.ON_SCENE_UPDATE_COMPLETE]: onSceneUpdateComplete,
      [ViewerEvents.ON_SCENE_UPDATE_START]: onSceneUpdateStart,
      [ViewerEvents.ON_SNAPSHOT_AVAILABLE]: onSnapshotAvailable,
      [ViewerEvents.ON_ZOOM]: onZoom,
    };
    for (const [eventName, eventHandler] of Object.entries(handlers)) {
      renderer.setHandler(eventName, eventHandler);
    }
  }, [
    onChangeCameraDirection,
    onClickAnnotation,
    onError,
    onLookTransitionComplete,
    onMarkPoint,
    onSceneUpdateComplete,
    onSceneUpdateStart,
    onSnapshotAvailable,
    onZoom,
    renderer,
    setNode,
  ]);

  useEffect(() => {
    if (!renderer) {
      return;
    }
    // If all data exactly matches the current render parameters, the scene does not need to change.
    if (
      floorplan.id === sceneParams?.floorplan?.id &&
      walkthrough.id === sceneParams?.walkthrough?.id &&
      node.id === sceneParams?.node?.id &&
      annotations.length === sceneParams?.annotations?.length
    ) {
      return;
    }

    const nextSceneParams = { annotations, floorplan, node, walkthrough };
    setSceneParams(nextSceneParams);

    (async () => {
      if (!sceneParams || sceneParams?.walkthrough?.id !== nextSceneParams.walkthrough.id) {
        console.debug('[PanographViewer] Loading walkthrough scene...', nextSceneParams);
        await renderer.loadWalkthroughScene(nextSceneParams);
        renderer.startRendering();
      } else if (sceneParams.node?.id !== nextSceneParams.node.id) {
        console.debug('[PanographViewer] Changing node...', nextSceneParams);
        await renderer.moveToNode(nextSceneParams.node, nextSceneParams.annotations);
      } else if (sceneParams.annotations.length !== nextSceneParams.annotations.length) {
        console.debug('[PanographViewer] Updating annotations...', nextSceneParams);
        renderer.updateAnnotations(nextSceneParams.annotations);
      }
    })();
  }, [annotations, floorplan, sceneParams, renderer, walkthrough, node]);

  useEffect(() => {
    if (lookTarget) {
      renderer?.lookAt(lookTarget.x, lookTarget.y, lookTarget.z);
    }
  }, [lookTarget, renderer]);

  useEffect(() => {
    renderer?.setFieldOfView(zoomFieldOfView);
  }, [renderer, zoomFieldOfView]);

  useEffect(() => {
    if (!canvasRef.current) {
      return undefined;
    }

    if (!showLoader) {
      canvasRef.current.style.opacity = '1';
      return undefined;
    }

    const timer = setTimeout(() => {
      if (canvasRef.current) {
        canvasRef.current.style.opacity = '0.5';
      }
    }, TimingConstants.IDLE_WAIT_DURATION);

    return () => clearTimeout(timer);
  }, [showLoader]);

  useImperativeHandle(
    forwardedRef,
    () => ({
      clearBrightness: () => {
        if (!canvasRef.current) {
          throw new Error('Cannot clear brightness: missing canvas');
        }
        canvasRef.current.style.filter = '';
      },
      focus: () => {
        if (!canvasRef.current) {
          throw new Error('Cannot focus: missing canvas');
        }
        canvasRef.current.focus();
      },
      requestSnapshot: () => {
        renderer?.requestSnapshot();
      },
      setBrightness: (level: number) => {
        if (!canvasRef.current) {
          throw new Error('Cannot set brightness: missing canvas');
        }
        canvasRef.current.style.filter = `brightness(${level})`;
      },
      setMarking: (marking: boolean) => {
        if (!canvasRef.current) {
          throw new Error('Cannot set marking status: missing canvas');
        }

        renderer?.setMarking(marking);
        if (marking) {
          canvasRef.current.classList.add(classes.cursorCrosshair);
        } else {
          canvasRef.current.classList.remove(classes.cursorCrosshair);
        }
      },
    }),
    [canvasRef, renderer]
  );

  return (
    <div className={classes.viewerContainer}>
      {!errorMessage && (sceneIsInitializing || showLoader) && (
        <LoadingIndicator
          className={classes.loadingIndicator}
          delay={sceneIsInitializing ? 0 : TimingConstants.IDLE_WAIT_DURATION}
          fullPage
        />
      )}
      {errorMessage && (
        <div className={classes.errorContainer}>
          <ErrorIcon aria-hidden className={classes.errorIcon} />
          <p className={classes.errorMessage}>{errorMessage}</p>
          <Button onClick={reload} variant="mediumEmphasis">
            Try again
          </Button>
        </div>
      )}
      <canvas className={classNames(classes.canvas, { [classes.hidden]: errorMessage })} ref={canvasRef} tabIndex={0} />
    </div>
  );
});

export default PanographViewer;
