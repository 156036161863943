import { Box, BoxProps } from '@chakra-ui/react';
import classNames from 'classnames';
import { ReactElement, ReactNode, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import classes from './Layout.module.scss';

export interface ContentProps {
  applyGutters?: boolean;
  boxProps?: BoxProps;
  children?: ReactNode;
  className?: string;
  constrainToPageHeight?: boolean;
  drawerOpen?: boolean;
}

/**
 * Main content outlet. Lots of opportunity for cleanup here once we switch over to exclusively right drawers.
 */
const Content = (props: ContentProps): ReactElement => {
  const [initialOpen, setInitialOpen] = useState(props.drawerOpen);
  return (
    <CSSTransition
      classNames="drawer"
      in={props.drawerOpen === undefined ? false : props.drawerOpen}
      onExited={() => setInitialOpen(false)}
      timeout={{ enter: 200, exit: 250 }}
    >
      <Box
        className={classNames(
          {
            'drawer-enter-done': initialOpen,
            [classes.constrainToPageHeight]: props.constrainToPageHeight,
          },
          props.className
        )}
        display={props.applyGutters ? 'flex' : undefined}
        flexFlow="column nowrap"
        left="var(--width-sidebar)"
        padding={props.applyGutters ? 'var(--gutter-page-vertical) var(--gutter-page-horizontal)' : undefined}
        position="relative"
        top={0}
        width="calc(100% - var(--width-sidebar))"
        {...props.boxProps}
      >
        {props.children}
      </Box>
    </CSSTransition>
  );
};

export default Content;
