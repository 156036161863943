/**
 * Pendo topic names that may be passed into the `data-pendo-topic` prop. When provided on an interactive element, it
 * should be accompanied by a descriptive `data-pendo-label` prop.
 *
 * @example
 * ```tsx
 *  <Link
 *    data-pendo-topic={PendoTopic.PORTFOLIO}
 *    data-pendo-label="Go to Progress Tracking from example CTA"
 *    href={progressTrackingPath}
 *    onClick={goToProgressTracking}
 *  >
 *    Go to Progress Tracking
 *  </Link>
 * ```
 */
export enum PendoTopic {
  PORTFOLIO = 'portfolio',
  USER_MANAGEMENT = 'user-management',
  PROGRESS_TRACKING = 'progress-tracking',
}

export enum DrawerType {
  PROJECT = 'project drawer',
  VIEW_USERS = 'view users drawer',
  USER_PROFILE = 'user profile drawer',
  MILESTONE_PROGRESS = 'milestone progress drawer',
}
