import { Flex, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { Redirect, generatePath, useLocation, useParams } from 'react-router-dom';

import { FloorplanApi } from '../api/v0/rest/FloorplanApi';
import { Content, InternalLayout, LoadingIndicator } from '../components';
import { ErrorIcon } from '../components/Icon';
import { ProjectHierarchyQueryKeys, QueryTopics } from '../constants/queries';
import Routes from '../routes';
import theme from '../theme';

import commonClasses from '../Common.module.scss';

/**
 * This component redirects legacy project URL patterns to newer, more consistent equivalents:
 *
 * - `/floorplan/<floorplan_id>/` to `/projects/<project_id>/floorplans/<floorplan_id>/`
 * - `/floorplan/<floorplan_id>?walkthrough=<walkthrough_id>` to
 *   `/projects/<project_id>/floorplans/<floorplan_id>/walkthroughs/<walkthrough_id>`.
 * - `/floorplan/<floorplan_id>/walkthrough/<walkthrough_id>` to
 *   `/projects/<project_id>/floorplans/<floorplan_id>/walkthroughs/<walkthrough_id>`.
 */
const LegacyProjectUrlRedirectContainer = () => {
  const location = useLocation<{ walkthrough?: string }>();
  const urlParameters = useParams<{ floorplanId: string; walkthroughId?: string }>();
  const floorplanId = Number(urlParameters.floorplanId);

  const projectDetailsQuery = useQuery({
    queryKey: [QueryTopics.PROJECT_HIERARCHY, ProjectHierarchyQueryKeys.FLOORPLAN_PROJECT, floorplanId],
    queryFn: async ({ signal }) => (await FloorplanApi.getProjectByFloorplanId(floorplanId, { signal })).data,
  });

  // Walkthrough ID, if present, may be a path or query parameter.
  const targetWalkthroughId = useMemo(() => {
    if (urlParameters.walkthroughId) {
      return urlParameters.walkthroughId;
    }

    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('walkthrough');
  }, [location.search, urlParameters.walkthroughId]);

  if (projectDetailsQuery.isFetching) {
    return (
      <InternalLayout>
        <LoadingIndicator fullPage />
      </InternalLayout>
    );
  }

  const project = projectDetailsQuery.data;

  if (projectDetailsQuery.isError || !project) {
    const statusCode = (projectDetailsQuery.error as AxiosError)?.response?.status;
    switch (statusCode) {
      case 403:
      case 404:
        // TODO: TS-219: replace with dedicated HTTP 403 page where user can request access.
        return <Redirect to={Routes.NOT_FOUND} />;
      case 500:
      default: {
        return (
          <InternalLayout>
            <Content constrainToPageHeight>
              <Flex alignItems="center" height="100%" justifyContent="center" flexDir="column">
                <ErrorIcon aria-hidden className={commonClasses.errorIcon} />
                <Text color={theme.colors.brand.gray[900]} textAlign="center">
                  Failed to load project details. Please try again later.
                </Text>
              </Flex>
            </Content>
          </InternalLayout>
        );
      }
    }
  }

  if (targetWalkthroughId) {
    return (
      <Redirect
        to={generatePath(Routes.WALKTHROUGH, {
          projectId: project.id,
          floorplanId,
          walkthroughId: targetWalkthroughId,
        })}
      />
    );
  }

  return <Redirect to={generatePath(Routes.FLOORPLAN, { projectId: project.id, floorplanId })} />;
};

export default LegacyProjectUrlRedirectContainer;
