import { Icon } from '@chakra-ui/react';
import classNames from 'classnames';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '../../';
import { DenormalizedAnnotation as LegacyDenormalizedAnnotation } from '../../../@types/OnSiteIQ';
import { Annotation } from '../../../@types/api/v0/rest/Annotation';
import { Store } from '../../../@types/redux/store';
import { setActiveDrawerItem } from '../../../actions/app';
import { deleteAnnotation, updateAnnotation } from '../../../actions/projects';
import useThunkDispatch from '../../../hooks/useThunkDispatch';
import { formatIsoDate } from '../../../utils/dateUtils';
import { DeleteIcon, EditIcon, ExternalLinkIcon } from '../../Icon';
import OshaLink from '../../OshaLink';
import AnnotationImage from './AnnotationImage';
import ContentBox from './ContentBox';
import DeleteConfirmation from './DeleteConfirmation';
import ReplyList from './ReplyList';

import classes from './Annotations.module.scss';

interface Props {
  annotation: Annotation | LegacyDenormalizedAnnotation;
  readOnly: boolean;
  onViewAnnotation: (annotation: Annotation | LegacyDenormalizedAnnotation) => void;
}

const FOCUS_ANIMATION_DURATION = 1000;

const Card = ({ annotation, readOnly, onViewAnnotation }: Props) => {
  const dispatch = useDispatch();
  const dispatchThunk = useThunkDispatch();

  const activeDrawerItem = useSelector((state: Store) => state.app.activeDrawerItem);

  const [deleting, setDeleting] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [pendingChanges, setPendingChanges] = useState<string>(annotation.content);
  const [hasFocus, setHasFocus] = useState(false);

  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!cardRef.current || activeDrawerItem !== annotation.id) {
      return;
    }

    cardRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
    setHasFocus(true);

    const timeout = setTimeout(() => {
      setHasFocus(false);
      dispatch(setActiveDrawerItem(undefined));
    }, FOCUS_ANIMATION_DURATION);

    // eslint-disable-next-line consistent-return
    return () => {
      setHasFocus(false);
      clearTimeout(timeout);
    };
  }, [activeDrawerItem, annotation.id, dispatch]);

  function onChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setPendingChanges(event.target.value);
  }

  async function onDeleteAnnotation() {
    await dispatchThunk(deleteAnnotation(annotation.id));
  }

  async function onUpdateAnnotation() {
    await dispatchThunk(updateAnnotation(annotation, pendingChanges));
    setEditing(false);
  }

  function toggleDeleting() {
    setDeleting((current) => !current);
  }

  function toggleEditing() {
    setEditing((current) => !current);
    setPendingChanges(annotation.content);
  }

  return (
    <div
      className={classNames(classes.card, { [classes.hasFocus]: hasFocus })}
      data-pendo-label="Touch drawer item"
      data-pendo-topic="annotations"
      ref={cardRef}
      role="listitem"
    >
      <div className={classes.headerRow}>
        <span className={classes.title}>{annotation.kind}</span>
        {!readOnly && !editing && (
          <div className={classes.editControls}>
            <Button className={classes.editButton} onClick={toggleEditing} variant="ghost">
              <Icon aria-label="Edit" as={EditIcon} />
            </Button>
            <Button className={classes.deleteButton} onClick={toggleDeleting} variant="ghost">
              <DeleteIcon aria-label="Delete" />
            </Button>
          </div>
        )}
      </div>
      {annotation.procore_link && (
        <a className={classes.procoreLink} href={annotation.procore_link} target="_blank" rel="noreferrer">
          View on Procore
          <ExternalLinkIcon aria-hidden />
        </a>
      )}
      <AnnotationImage annotation={annotation} onViewAnnotation={onViewAnnotation} />
      <div className={classes.author}>
        {annotation.poster.first_name} {annotation.poster.last_name}
      </div>
      <div className={classes.createdAt}>Posted {formatIsoDate(annotation.created)?.formattedDateTime}</div>
      {annotation.osha && (
        <OshaLink className={classes.oshaLink} description={annotation.osha.description} link={annotation.osha.link} />
      )}
      <ContentBox
        content={pendingChanges}
        editing={editing}
        onCancelEdit={toggleEditing}
        onChange={onChange}
        onFinishEdit={onUpdateAnnotation}
        textAreaLabel="Markup description"
      />
      <ReplyList annotation={annotation} replies={annotation.replies} />
      {deleting && (
        <DeleteConfirmation onCancel={toggleDeleting} onDelete={onDeleteAnnotation} title="Delete Markup">
          <p className={classes.confirmationDisclaimer}>Are you sure you want to delete this observation thread?</p>
          <p className={classes.confirmationDisclaimer}>
            You will no longer be able to read the original observation and its replies, or view the marked point in the
            360 image.
          </p>
        </DeleteConfirmation>
      )}
    </div>
  );
};

export default Card;
