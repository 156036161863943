interface Props {
  className?: string;
  description: string;
  link: string;
}

const OshaLink = ({ className, description, link }: Props) => {
  return (
    <a className={className} href={link} target="_blank" rel="noopener noreferrer">
      {description}
    </a>
  );
};

export default OshaLink;
