const isMobileLandscape = () => window.matchMedia('(max-height: 600px)').matches;
const isMobilePortrait = () => window.matchMedia('(max-width: 600px)').matches;
const isMobile = () => isMobilePortrait() || isMobileLandscape();
const isTablet = () => window.matchMedia('(min-width: 600px) and (max-width: 1024px)').matches;
const isDesktop = () => window.matchMedia('(min-width: 1024px)').matches;

const getCssRootValue = (cssPropertyName, asScalar) => {
  const computedRootStyle = getComputedStyle(document.documentElement);
  const rawValue = computedRootStyle.getPropertyValue(cssPropertyName);

  // If we want the scalar value, remove all non-digits (\D) and cast.
  return asScalar ? Number(rawValue.replace(/\D/gu, '')) : rawValue;
};

// TODO this needs to be more thoroughly tested to confirm it works cross browser
// Currently I only have confirmation this works on iOS
const getViewportZoom = () => document.documentElement.clientWidth / window.innerWidth;

// Take a CSS variable name in rem units and convert to pixel values.
const remToPixels = (cssVariableName) => {
  const remValue = getCssRootValue(cssVariableName);
  if (!remValue || remValue === 'none') {
    return undefined;
  }

  // Rem units correspond the base font size on the <html> element. For example, if it has a font-size of 16px, further
  // down in the DOM, a rule with a value of 32px could also be expressed as 2rem (2rem * 16px = 32px).
  // const baseFontSizeScalar = Number(computedRootStyle.getPropertyValue('font-size').replace('px', ''));
  const baseFontSizeScalar = getCssRootValue('font-size', true);
  const remValueScalar = remValue.replace('rem', '');
  return remValueScalar * baseFontSizeScalar;
};

const pixelsToRem = (numPixels) => {
  const baseFontSizeScalar = getCssRootValue('font-size', true);
  return numPixels / baseFontSizeScalar;
};

export {
  isMobilePortrait,
  isMobileLandscape,
  isMobile,
  isTablet,
  isDesktop,
  getCssRootValue,
  getViewportZoom,
  remToPixels,
  pixelsToRem,
};
