import { useState } from 'react';

import { Button } from '../../';
import {
  DenormalizedAnnotation as LegacyDenormalizedAnnotation,
  DenormalizedReply as LegacyDenormalizedReply,
} from '../../../@types/OnSiteIQ';
import { Annotation, AnnotationReply } from '../../../@types/api/v0/rest/Annotation';
import { addReply } from '../../../actions/projects';
import useThunkDispatch from '../../../hooks/useThunkDispatch';
import { countDisplay } from '../../../utils';
import AddReply from './AddReply';
import Reply from './Reply';

import classes from './Annotations.module.scss';

interface Props {
  annotation: Annotation | LegacyDenormalizedAnnotation;
  replies: (AnnotationReply | LegacyDenormalizedReply)[];
}

const ReplyList = ({ annotation, replies }: Props) => {
  const dispatch = useThunkDispatch();

  const [showReplies, setShowReplies] = useState(false);

  async function handleAddReply(newReply: { comment: string; safety_mitigation: 'C' | 'O' | null }) {
    await dispatch(addReply(annotation, newReply));
    setShowReplies(true);
  }

  return (
    <div className={classes.replyList}>
      {replies.length > 0 && !showReplies && (
        <div className={classes.buttonRow}>
          <Button onClick={() => setShowReplies(true)} variant="lowEmphasis">
            {`View ${countDisplay(replies.length, 'Comment')}`}
          </Button>
        </div>
      )}
      {showReplies && replies.map((r) => <Reply key={r.id} reply={r} annotation={annotation} />)}
      <AddReply annotation={annotation} onSubmit={handleAddReply} />
    </div>
  );
};

export default ReplyList;
