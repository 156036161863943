import { useEffect, useState } from 'react';

import { LoadingIndicator, SearchInput } from '../..';
import { Me } from '../../../@types/OnSiteIQ';
import { Detection, DetectionType } from '../../../@types/api/v0/rest/Detection';
import { Project } from '../../../@types/api/v0/rest/Project';
import { Walkthrough } from '../../../@types/api/v0/rest/Walkthrough';
import { DrawerBody, DrawerControls, DrawerHeader, SidebarActionDrawer } from '../../SidebarActionDrawer';
import SearchDetectionList from './SearchDetectionList';
import SearchDetectionTypeList from './SearchDetectionTypeList';

export interface SearchDrawerProps {
  /** Close the drawer. */
  close: () => void;
  /** Currently-selected search detection. */
  currentDetection?: Detection;
  /** Currently-selected search detection type. */
  currentDetectionType?: DetectionType;
  /** List of search detections of `currentDetectionType` for this walkthrough. */
  detections: Detection[];
  /** List of search detection types for this walkthrough. */
  detectionTypes: DetectionType[];
  /** Flag indicating whether or not data is loading for this drawer. */
  isFetching?: boolean;
  /** Handler called when the user clicks the "View 360" button on a search detection. */
  on360Click: (detection: Detection) => void;
  /** Handler called when the user selects a detection. */
  onDetectionSelect: (detection: Detection) => void;
  /** Handler called when the user selects a detection type or clears their selection. */
  onDetectionTypeSelect: (detectionType: DetectionType | undefined) => void;
  /** Handler called when the user votes on a detection. A vote value of `L` means "like", `D` means "dislike". */
  onDetectionVote: (detection: Detection, vote: 'L' | 'D') => void;
  /** Current project instance. */
  project: Project;
  /** Whether or not the drawer is visible. */
  show: boolean;
  /** Current walkthrough instance. */
  walkthrough?: Walkthrough;
  /** Current user details. */
  user?: Me;
}

const SearchDrawer = (props: SearchDrawerProps) => {
  const {
    close,
    currentDetection,
    currentDetectionType,
    detections,
    detectionTypes,
    isFetching,
    on360Click,
    onDetectionTypeSelect,
    onDetectionSelect,
    onDetectionVote,
    project,
    show,
    user,
    walkthrough,
  } = props;

  const [searchInputValue, setSearchInputValue] = useState<string>('');

  /**
   * If the user has already selected a detection type then changes the search text, clear the current detection type.
   * This kicks them back to step 1 (selecting a detection type from the filtered list).
   */
  const handleDetectionTypeSearchChange = (searchInputValue: string) => {
    if (currentDetectionType) {
      onDetectionTypeSelect(undefined);
    }

    setSearchInputValue(searchInputValue);
  };

  /**
   * When the user selects a detection type, set the current detection type to the selection. This advances the user to
   * step 2 (selecting a detection of the chosen type).
   */
  const handleDetectionTypeSelect = (detectionType: DetectionType) => {
    onDetectionTypeSelect(detectionType);
    setSearchInputValue(detectionType.label);
  };

  // If the current detection is cleared by the parent (e.g. when changing floorplans or time travel), clear the search value.
  useEffect(() => {
    if (!currentDetection) {
      setSearchInputValue('');
    }
  }, [currentDetection]);

  return (
    <SidebarActionDrawer className="search-drawer" show={show} close={close}>
      <DrawerHeader date={walkthrough?.when} project={project} />
      <DrawerControls>
        <SearchInput
          disabled={isFetching}
          inputProps={{ 'data-pendo-label': 'Use textbox', 'data-pendo-topic': 'search' }}
          onChange={handleDetectionTypeSearchChange}
          value={searchInputValue}
          variant="medium"
        />
      </DrawerControls>
      <DrawerBody>
        {isFetching && <LoadingIndicator />}
        {!isFetching && !currentDetectionType && (
          <SearchDetectionTypeList
            detectionTypes={detectionTypes}
            onDetectionTypeSelect={handleDetectionTypeSelect}
            searchText={searchInputValue}
          />
        )}
        {!isFetching && currentDetectionType && (
          <SearchDetectionList
            currentDetection={currentDetection}
            detections={detections}
            on360Click={on360Click}
            onDetectionSelect={onDetectionSelect}
            onDetectionVote={onDetectionVote}
            user={user}
          />
        )}
      </DrawerBody>
    </SidebarActionDrawer>
  );
};

export default SearchDrawer;
