import classNames from 'classnames';
import { ReactElement } from 'react';
import { CSSTransition } from 'react-transition-group';

import { PageTitleCard } from './';

import classes from './FloorPlanInfoCard.module.scss';

export interface FloorplanInfoCardProps {
  show: boolean;
  projectName: string;
  floorplanName: string;
  walkDate?: string;
}

const FloorplanInfoCard = (props: FloorplanInfoCardProps): ReactElement => (
  <CSSTransition in={props.show} timeout={250} classNames="floorplan-info-card">
    <PageTitleCard
      date={props.walkDate}
      className={classNames(classes.positionedCard, 'floorplan-info-card')}
      subtitle={props.projectName}
      title={props.floorplanName}
    />
  </CSSTransition>
);

export default FloorplanInfoCard;
