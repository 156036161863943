import { Notification } from '../@types/OnSiteIQ';
import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from '../constants/actionTypes';

export type BaseNotification = Omit<Notification, 'id' | 'kind'>;

const ERROR = 'error';
const SUCCESS = 'success';

const notify = (notification: BaseNotification, kind: typeof ERROR | typeof SUCCESS) => {
  (notification as Notification).kind = kind;
  return { notification, type: ADD_NOTIFICATION };
};

export const notifySuccess = (notification: BaseNotification) => notify(notification, SUCCESS);
export const notifyError = (notification: BaseNotification) => notify(notification, ERROR);
export const removeNotification = (id: number) => ({ type: REMOVE_NOTIFICATION, id });
