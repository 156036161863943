import { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { LoadingIndicator } from '../..';
import {
  DenormalizedAnnotation as LegacyDenormalizedAnnotation,
  Project as LegacyProject,
} from '../../../@types/OnSiteIQ';
import { Annotation } from '../../../@types/api/v0/rest/Annotation';
import { Project } from '../../../@types/api/v0/rest/Project';
import { Store } from '../../../@types/redux/store';
import { DrawerBody, DrawerHeader, SidebarActionDrawer } from '../../SidebarActionDrawer';
import EmptyMessage from '../EmptyMessage';
import Card from './Card';

export interface AnnotationsDrawerProps {
  annotationLocationType?: 'floor' | 'location';
  annotations?: (Annotation | LegacyDenormalizedAnnotation)[];
  close: () => void;
  date?: string;
  /**
   * Show a loading spinner. Useful in cases when the parent component will change the drawer's content soon, and to
   * prevent the user from performing actions on annotations in the meantime. For example, when the 360º viewer is
   * transitioning between ground nodes on a slow connection, the user should not be able to immediately look at an
   * annotation until the scene has fully transitioned.
   */
  isLoading?: boolean;
  onViewAnnotation: (annotation: Annotation | LegacyDenormalizedAnnotation) => void;
  project: Project | LegacyProject;
  show: boolean;
}

// TODO sort annotations
const AnnotationsDrawer = (props: AnnotationsDrawerProps): ReactElement => {
  const { annotations = [] } = props;

  const user = useSelector((state: Store) => state.auth.user);

  const annotationPhrase = props.annotationLocationType === 'floor' ? 'on this floor' : 'at this location';

  return (
    <SidebarActionDrawer show={props.show} close={props.close} className="annotations">
      <DrawerHeader project={props.project} date={props.date} />
      <DrawerBody>
        {props.isLoading && <LoadingIndicator />}
        {!props.isLoading && annotations.length === 0 && (
          <EmptyMessage message={`No markups found ${annotationPhrase}.`} />
        )}
        {!props.isLoading && annotations.length > 0 && (
          <div role="list">
            {annotations.map((annotation) => (
              <Card
                key={annotation.id}
                annotation={annotation}
                readOnly={annotation.poster.id !== user?.id}
                onViewAnnotation={props.onViewAnnotation}
              />
            ))}
          </div>
        )}
      </DrawerBody>
    </SidebarActionDrawer>
  );
};

export default AnnotationsDrawer;
