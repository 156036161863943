import { Box, Text } from '@chakra-ui/react';

import { DetectionType } from '../../../@types/api/v0/rest/Detection';
import theme from '../../../theme';
import { countDisplay } from '../../../utils';

import classes from './Search.module.scss';

export interface SearchDetectionTypeListProps {
  /** List of search detection types for the current walkthrough. */
  detectionTypes: DetectionType[];
  /** Handler to call when the user selects a detection type. */
  onDetectionTypeSelect: (detectionType: DetectionType) => void;
  /** Current search text to filter detection types by. */
  searchText: string;
}

const SearchDetectionTypeList = (props: SearchDetectionTypeListProps) => {
  const { detectionTypes, onDetectionTypeSelect, searchText } = props;

  const filteredDetectionTypes = detectionTypes.filter((detectionType: DetectionType) => {
    const label = detectionType.label.toLocaleLowerCase();
    const input = searchText.toLocaleLowerCase();
    return label.includes(input);
  });

  if (filteredDetectionTypes.length === 0) {
    return (
      <Text color={theme.colors.brand.gray[600]} marginBlock="0.5rem" marginInline="2rem" textAlign="left">
        No results found.
      </Text>
    );
  }

  return (
    <Box className="cards">
      {filteredDetectionTypes.map((detectionType) => (
        <button
          className={classes.card}
          data-pendo-label="Select item"
          data-pendo-topic="search"
          key={`search-drawer-detection-${detectionType.id}`}
          onClick={() => onDetectionTypeSelect(detectionType)}
        >
          <span className={classes.title}>{detectionType.label}</span>
          <span className={classes.subtitle}>{countDisplay(detectionType.detection_count, 'detection')}</span>
        </button>
      ))}
    </Box>
  );
};

export default SearchDetectionTypeList;
