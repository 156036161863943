import classNames from 'classnames';
import { ReactElement } from 'react';

import classes from './EmptyMessage.module.scss';

interface Props {
  message: string;
}

const EmptyMessage = (props: Props): ReactElement => (
  <div className={classNames(classes.container, 'empty-message')}>
    <p className={classes.messageText}>{props.message}</p>
  </div>
);

export default EmptyMessage;
