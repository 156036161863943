// MARK: Query Topics

export enum QueryTopics {
  ASSET_TYPES = 'ASSET_TYPES',
  AUTH = 'AUTH',
  MILESTONES = 'MILESTONES',
  PORTFOLIO_DASHBOARD = 'PORTFOLIO_DASHBOARD',
  PROCORE = 'PROCORE',
  PROGRESS_TRACKING = 'PROGRESS_TRACKING',
  // TODO: rename to `PROJECTS`.
  PROJECT_HIERARCHY = 'PROJECT_HIERARCHY',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
}

// MARK: Core API v0

/** Query keys for API v0 user authentication/authorization endpoints. */
export enum AuthQueryKeys {
  USER_PROFILE = 'USER_PROFILE',
}

/**
 * Query keys for API v0 project endpoints. These were initially created for the move to React Query, but before
 * the v1 endpoints were introduced. Try not to use these in too much new code.
 */
// TODO: rename to ProjectQueryKeys
export enum ProjectHierarchyQueryKeys {
  FLOORPLAN_PROJECT = 'FLOORPLAN_PROJECT',
  MILESTONE_TOTALS = 'MILESTONE_TOTALS',
  PROJECT_DETAILS = 'PROJECT_DETAILS',
  PROJECT_MILESTONES = 'PROJECT_MILESTONES',
  WALKTHROUGH = 'WALKTHROUGH',
  WALKTHROUGH_ANNOTATIONS = 'WALKTHROUGH_ANNOTATIONS',
  WALKTHROUGH_DETECTIONS = 'WALKTHROUGH_DETECTIONS',
  WALKTHROUGH_DETECTION_TYPES = 'WALKTHROUGH_DETECTION_TYPES',
  WALKTHROUGH_DETECTION_VOTE = 'WALKTHROUGH_DETECTION_VOTE',
}

// MARK: Core API v1

/** Query keys for API v1 asset type endpoints. */
export enum AssetTypeQueryKeys {
  ASSET_TYPES_LIST = 'ASSET_TYPES_LIST',
}

export enum MilestoneQueryKeys {
  MILESTONES_LIST = 'MILESTONES_LIST',
}

/** Query keys for API v1 portfolio dashboard endpoints. */
export enum PortfolioDashboardQueryKeys {
  PORTFOLIO_PROJECTS_LIST = 'PORTFOLIO_PROJECTS_LIST',
  PROGRESS_HISTORY = 'PROGRESS_HISTORY',
  MILESTONE_PROGRESS = 'MILESTONE_PROGRESS',
}

/** Query keys for API v1 project endpoints. */
export enum ProjectQueryKeys {
  PROJECT_CREATE = 'PROJECT_CREATE',
  PROJECT_DETAILS = 'PROJECT_DETAILS',
  PROJECT_UPDATE = 'PROJECT_UPDATE',
}

/** Query keys for API v1 progress tracking endpoints. */
export enum ProgressTrackingQueryKeys {
  MILESTONE_TOTAL_PROGRESS_HISTORY = 'MILESTONE_TOTAL_PROGRESS_HISTORY',
  MILESTONE_FLOOR_PROGRESS_HISTORY = 'MILESTONE_FLOOR_PROGRESS_HISTORY',
  PROGRESS_DATA_EXPORT = 'PROGRESS_DATA_EXPORT',
  PROGRESS_TABLE_DATA = 'PROGRESS_TABLE_DATA',
}

export enum UserManagementQueryKeys {
  ACCOUNT_MEMBERS = 'ACCOUNT_MEMBERS',
  ACCOUNT_MEMBERS_WITH_INTERNAL_USERS = 'ACCOUNT_MEMBERS_WITH_INTERNAL_USERS',
  PROJECT_USERS = 'PROJECT_USERS',
  PROJECT_USERS_WITH_INTERNAL_USERS = 'PROJECT_USERS_WITH_INTERNAL_USERS',
  ACCOUNT_PROJECTS = 'ACCOUNT_PROJECTS',
  ME = 'ME',
  SHARED_ACCOUNTS = 'SHARED_ACCOUNTS',
  SHARED_PROJECTS = 'SHARED_PROJECTS',
  REMOVE_FROM_ACCOUNT = 'REMOVE_FROM_ACCOUNT',
  UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS',
  RESEND_INVITE = 'RESEND_INVITE',
}

export enum AiMilestonesQueryKeys {
  PROJECT_APPLICABLE_MILESTONES = 'PROJECT_APPLICABLE_MILESTONES',
}

// MARK: Procore v1

/** Query keys for Procore v1 endpoints. */
export enum ProcoreQueryKeys {
  CONTRIBUTING_BEHAVIORS = 'CONTRIBUTING_BEHAVIORS',
  CONTRIBUTING_CONDITIONS = 'CONTRIBUTING_CONDITIONS',
  COST_CODES = 'COST_CODES',
  HAZARDS = 'HAZARDS',
  LOCATIONS = 'LOCATIONS',
  OBSERVATION_ASSIGNEES = 'OBSERVATION_ASSIGNEES',
  OBSERVATION_DEFAULT_DISTRIBUTION_MEMBERS = 'OBSERVATION_DEFAULT_DISTRIBUTION_MEMBERS',
  OBSERVATION_POTENTIAL_DISTRIBUTION_MEMBERS = 'OBSERVATION_POTENTIAL_DISTRIBUTION_MEMBERS',
  OBSERVATION_TYPES = 'OBSERVATION_TYPES',
  RFI_POTENTIAL_ASSIGNEES = 'RFI_POTENTIAL_ASSIGNEES',
  RFI_POTENTIAL_RESPONSIBLE_CONTRACTORS = 'RFI_POTENTIAL_RESPONSIBLE_CONTRACTORS',
  RFI_POTENTIAL_RECEIVED_FROMS = 'RFI_POTENTIAL_RECEIVED_FROMS',
  SPECIFICATION_SECTIONS = 'SPECIFICATION_SECTIONS',
  TRADES = 'TRADES',
}
