import { Box, Flex, Text, Toast, useToast } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, generatePath, useHistory, useParams } from 'react-router-dom';
import { Vector3 } from 'three';

import { Annotation } from '../../@types/api/v0/rest/Annotation';
import { Detection, DetectionType, DetectionVoteCreateRequestBody } from '../../@types/api/v0/rest/Detection';
import { Node } from '../../@types/api/v0/rest/Node';
import { Store } from '../../@types/redux/store';
import * as appActions from '../../actions/app';
import { DetectionApi } from '../../api/v0/rest/DetectionApi';
import { ProjectApi } from '../../api/v0/rest/ProjectApi';
import { WalkthroughApi } from '../../api/v0/rest/WalkthroughApi';
import { Content, InternalLayout, LoadingIndicator } from '../../components';
import AnnotationsDrawer from '../../components/Drawers/Annotations/AnnotationsDrawer';
import ChangeFloorplanDrawer from '../../components/Drawers/ChangeFloorplan/ChangeFloorplanDrawer';
import SearchDrawer from '../../components/Drawers/Search/SearchDrawer';
import TimeTravelDrawer, { TimeTravelOption } from '../../components/Drawers/TimeTravel/TimeTravelDrawer';
import FloorplanInfoCard from '../../components/FloorplanInfoCard';
import { ErrorIcon } from '../../components/Icon';
import { GeometryConstants } from '../../components/PanographViewer/constants';
import MiniMap from '../../components/View360/MiniMap';
import { ProjectHierarchyQueryKeys, QueryTopics } from '../../constants/queries';
import Routes from '../../routes';
import theme from '../../theme';

import commonClasses from '../../Common.module.scss';

const WalkthroughContainer = () => {
  const history = useHistory();
  const urlParameters = useParams<{ projectId: string; floorplanId: string; walkthroughId: string }>();
  const projectId = Number(urlParameters.projectId);
  const floorplanId = Number(urlParameters.floorplanId);
  const walkthroughId = Number(urlParameters.walkthroughId);

  const dispatch = useDispatch();
  const currentDrawer = useSelector((state: Store) => state.app.drawer);
  const user = useSelector((state: Store) => state.auth.user);

  const [currentDetection, setCurrentDetection] = useState<Detection>();
  const [currentDetectionType, setCurrentDetectionType] = useState<DetectionType>();
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);
  const [showNodes, setShowNodes] = useState<boolean>(false);

  const toast = useToast();

  const projectDetailsQuery = useQuery({
    queryKey: [QueryTopics.PROJECT_HIERARCHY, ProjectHierarchyQueryKeys.PROJECT_DETAILS, projectId],
    queryFn: async ({ signal }) => (await ProjectApi.getById(projectId, { signal })).data,
  });
  const walkthroughDetailsQuery = useQuery({
    queryKey: [QueryTopics.PROJECT_HIERARCHY, ProjectHierarchyQueryKeys.WALKTHROUGH, walkthroughId],
    queryFn: async ({ signal }) => (await WalkthroughApi.getById(walkthroughId, { signal })).data,
  });
  const walkthroughAnnotationsQuery = useQuery({
    queryKey: [QueryTopics.PROJECT_HIERARCHY, ProjectHierarchyQueryKeys.WALKTHROUGH_ANNOTATIONS, walkthroughId],
    queryFn: async ({ signal }) => (await WalkthroughApi.getAnnotationsByWalkthroughId(walkthroughId, { signal })).data,
  });
  const walkthroughDetectionTypesQuery = useQuery({
    enabled: currentDrawer === 'search',
    queryKey: [QueryTopics.PROJECT_HIERARCHY, ProjectHierarchyQueryKeys.WALKTHROUGH_DETECTION_TYPES, walkthroughId],
    queryFn: async ({ signal }) => (await DetectionApi.getWalkthroughDetectionTypes(walkthroughId, { signal })).data,
  });
  const walkthroughDetectionsQuery = useQuery({
    enabled: currentDrawer === 'search' && Boolean(currentDetectionType),
    queryKey: [
      QueryTopics.PROJECT_HIERARCHY,
      ProjectHierarchyQueryKeys.WALKTHROUGH_DETECTIONS,
      walkthroughId,
      currentDetectionType?.id,
    ],
    queryFn: async ({ signal }) =>
      (await DetectionApi.getWalkthroughDetections(walkthroughId, currentDetectionType?.label ?? '', { signal })).data,
  });

  const createDetectionVoteMutation = useMutation({
    mutationKey: [
      QueryTopics.PROJECT_HIERARCHY,
      ProjectHierarchyQueryKeys.WALKTHROUGH_DETECTION_VOTE,
      walkthroughId,
      currentDetectionType?.id,
      currentDetection?.id,
    ],
    mutationFn: ({ requestBody }: { requestBody: DetectionVoteCreateRequestBody }) =>
      DetectionApi.createWalkthroughDetectionVote(requestBody),
    onError: () => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (toastProps) => (
          <Toast
            {...toastProps}
            title="An error occurred"
            description="Failed to submit detection vote. Please try again later."
            status="error"
          />
        ),
      });
    },
    onSuccess: () => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (toastProps) => (
          <Toast {...toastProps} title="Success" description="Detection vote submitted." status="success" />
        ),
      });
      walkthroughDetectionsQuery.refetch();
    },
  });

  const closeDrawer = () => dispatch(appActions.closeDrawer());

  const showDrawer = (drawerName: 'annotations' | 'change-floorplan' | 'search' | 'time-travel') =>
    dispatch(appActions.showDrawer(drawerName));

  useEffect(() => {
    if (!isFinite(projectId) || projectId === 0) {
      return;
    }

    dispatch(
      appActions.setSidebarActionsList([
        {
          actionItem: 'progress',
          onClick: () => {
            history.push(generatePath(Routes.PROJECT_PROGRESS_TRACKING, { id: projectId }));
          },
          active: false,
        },
        'change-floorplan',
        'time-travel',
        'search',
        'annotations',
      ])
    );
  }, [dispatch, history, projectId]);

  const project = projectDetailsQuery.data;
  const floorplan = projectDetailsQuery.data?.floorplans?.find(({ id }) => id === floorplanId);
  const walkthrough = walkthroughDetailsQuery.data;
  const annotations = walkthroughAnnotationsQuery.data;
  const detectionTypes = walkthroughDetectionTypesQuery.data ?? [];
  const detections = walkthroughDetectionsQuery.data ?? [];

  // Automatically select the first detection if there isn't an active one and if the list isn't empty.
  useEffect(() => {
    if (currentDetectionType && walkthroughDetectionsQuery.data?.[0] && !currentDetection) {
      setCurrentDetection(walkthroughDetectionsQuery.data[0]);
    }
  }, [currentDetection, currentDetectionType, walkthroughDetectionsQuery.data]);

  // When the user changes the floorplan or time travels, clear the current search data.
  useEffect(() => {
    setCurrentDetection(undefined);
    setCurrentDetectionType(undefined);
  }, [walkthroughId]);

  /**
   * Navigate to the 360º viewer on some ground node. Optionally, the viewer may be configured to face a set of target
   * coordinates.
   */
  const goTo360 = (node: Node, lookTarget?: { x: number; y: number; z: number }) => {
    if (!floorplan || !walkthrough) {
      return;
    }

    const destination = generatePath(Routes.VIEW_360, {
      floorplanId: floorplan.id,
      walkthroughId: walkthrough.id,
      nodeId: node.id,
    });
    const queryParams = new URLSearchParams();
    if (lookTarget) {
      queryParams.set('lookX', String(lookTarget.x));
      queryParams.set('lookY', String(lookTarget.y));
      queryParams.set('lookZ', String(lookTarget.z));
    }

    history.push(`${destination}?${queryParams.toString()}`);
  };

  /**
   * When the user selects a detection in the search drawer or on the minimap, store a reference to the detection. If
   * the detection was selected from the minimap, ensure the drawer is open.
   */
  const handleDetectionSelect = (detection: Detection) => {
    if (currentDetection?.id === detection.id) {
      // The node we've clicked on is already active, let's jump to it
      viewDetection(detection);
    } else {
      // The node we've clicked wasn't active before, let's make it active now
      setCurrentDetection(detection);
      showDrawer('search');
    }
  };

  /**
   * When the user selects a detection type, set the state accordingly. If the user clears the selection, clear both the
   * current detection type and current detection
   */
  const handleDetectionTypeSelect = (detectionType: DetectionType | undefined) => {
    if (!detectionType) {
      setCurrentDetection(undefined);
      setCurrentDetectionType(undefined);
      return;
    }

    setCurrentDetectionType(detectionType);
    setShowNodes(false);
  };

  /** Handler called when the user votes on a detection. A vote value of `L` means "like", `D` means "dislike". */
  const handleDetectionVote = (detection: Detection, vote: 'L' | 'D') => {
    createDetectionVoteMutation.mutate({
      requestBody: {
        id: detection.votes.find((vote) => vote.user_id === user?.id)?.id ?? null,
        vote_type: vote,
        detection_id: detection.id,
      },
    });
  };

  /**
   * When the user selects a time travel option, verify that the selected option is the correct type, since time travel
   * options may be either `Walkthrough` or `TimeTravelPair` instances. If the option is a `TimeTravelPair` instance,
   * navigate to the walkthrough and node.
   */
  const handleTimeTravel = (timeTravelOption: TimeTravelOption) => {
    if ('walkthrough' in timeTravelOption) {
      console.warn('[View360Page] Selected 360 time travel option was not a time travel pair!');
    } else {
      history.push(
        generatePath(Routes.WALKTHROUGH, {
          projectId,
          floorplanId,
          walkthroughId: timeTravelOption.id,
        })
      );
    }
  };

  /**
   * When the user clicks on a node in the map, navigate to the 360º viewer. If the node has at least one annotation,
   * face the annotation on load.
   */
  const view360Node = (node: Node) => {
    const annotation = annotations?.find((a) => a.node === node.id);
    if (annotation) {
      showDrawer('annotations');
      goTo360(node, {
        x: annotation.x,
        y: annotation.y,
        z: annotation.z,
      });
    } else {
      goTo360(node);
    }
  };

  /**
   * When the user clicks an annotation in the left drawer, navigate to the 360º viewer facing the annotation.
   */
  const viewAnnotation = (annotation: Annotation) => {
    const node = walkthrough?.nodes?.find((node: Node) => node.id === annotation.node);
    if (!node) {
      console.warn('[WalkthroughContainer] Tried to view annotation but node not found!');
      return;
    }

    goTo360(node, {
      x: annotation.x,
      y: annotation.y,
      z: annotation.z,
    });
  };

  /**
   * When the user clicks a search detection in the left drawer, navigate to the 360º viewer facing the detected object.
   *
   * Note: detection coordinates come down from Core API as a normalized vector (i.e. a vector in the direction of the
   * detection, but with magnitude 1) in R^3. Now, when we set a look target for the 360º viewer, the target must be a
   * point (x, y, z) on the imagery sphere. To accomplish this, we scale the vector up to the length of the sphere
   * radius.
   *
   * In the future, we could potentially avoid this by having annotations, detections, and general camera orientation
   * solely use angle measure -- theta (pan) and phi (tilt).
   */
  const viewDetection = (detection: Detection) => {
    const node = walkthrough?.nodes?.find((node: Node) => node.id === detection.node_id);
    if (!node) {
      console.warn('[WalkthroughContainer] Tried to view search detection but node not found!');
      return;
    }

    const denormalizedVector = new Vector3(detection.x, detection.y, detection.z).multiplyScalar(
      GeometryConstants.SPHERE_RADIUS
    );
    goTo360(node, { x: denormalizedVector.x, y: denormalizedVector.y, z: denormalizedVector.z });
  };

  const criticalQueries = [projectDetailsQuery];
  const lowPriorityQueries = [
    walkthroughDetailsQuery,
    walkthroughAnnotationsQuery,
    walkthroughDetectionTypesQuery,
    walkthroughDetectionsQuery,
  ];

  const isAnyCriticalQueryFetching = criticalQueries.some((query) => query.isFetching);
  if (isAnyCriticalQueryFetching) {
    return (
      <InternalLayout>
        <Content>
          <LoadingIndicator fullPage />
        </Content>
      </InternalLayout>
    );
  }

  const errorQueries = [...criticalQueries, ...lowPriorityQueries].filter((query) => query.isError);
  if (errorQueries.length > 0 || !project || !floorplan) {
    switch ((errorQueries[0]?.error as AxiosError | undefined)?.response?.status) {
      case 403:
      case 404:
        // TODO: TS-219: replace with dedicated HTTP 403 page where user can request access.
        return <Redirect to={Routes.NOT_FOUND} />;
      case 500:
      default: {
        return (
          <InternalLayout>
            <Content constrainToPageHeight>
              <Flex alignItems="center" height="100%" justifyContent="center" flexDir="column">
                <ErrorIcon aria-hidden className={commonClasses.errorIcon} />
                <Text color={theme.colors.brand.gray[900]} textAlign="center">
                  Failed to load data for this walkthrough. Please try again later.
                </Text>
              </Flex>
            </Content>
          </InternalLayout>
        );
      }
    }
  }

  return (
    <InternalLayout>
      <ChangeFloorplanDrawer
        close={closeDrawer}
        date={walkthrough?.when}
        floorplan={floorplan}
        isLoading={walkthroughDetailsQuery.isFetching}
        project={project}
        projectFloorplans={project.floorplans}
        show={currentDrawer === 'change-floorplan'}
      />
      <TimeTravelDrawer
        close={closeDrawer}
        isLoading={walkthroughDetailsQuery.isFetching}
        onSelectOption={handleTimeTravel}
        options={floorplan.dated_walkthroughs}
        project={project}
        show={currentDrawer === 'time-travel'}
        walkthrough={walkthrough}
      />
      <SearchDrawer
        currentDetection={currentDetection}
        currentDetectionType={currentDetectionType}
        close={closeDrawer}
        detections={detections}
        detectionTypes={detectionTypes}
        isFetching={
          walkthroughDetectionsQuery.isFetching ||
          walkthroughDetectionTypesQuery.isFetching ||
          createDetectionVoteMutation.isLoading
        }
        on360Click={viewDetection}
        onDetectionSelect={handleDetectionSelect}
        onDetectionTypeSelect={handleDetectionTypeSelect}
        onDetectionVote={handleDetectionVote}
        project={project}
        show={currentDrawer === 'search'}
        user={user}
        walkthrough={walkthrough}
      />
      <AnnotationsDrawer
        annotationLocationType="floor"
        annotations={annotations}
        close={closeDrawer}
        date={walkthrough?.when}
        isLoading={walkthroughDetailsQuery.isFetching || walkthroughAnnotationsQuery.isFetching}
        onViewAnnotation={viewAnnotation}
        project={project}
        show={currentDrawer === 'annotations'}
      />
      <Content>
        <FloorplanInfoCard
          floorplanName={floorplan.name}
          projectName={project.name}
          show={!currentDrawer}
          walkDate={walkthrough?.when}
        />
        {!isImageLoaded && <LoadingIndicator />}
        <Box style={{ display: isImageLoaded ? 'block' : 'hidden' }}>
          <MiniMap
            activeDetectionId={currentDetection?.id}
            annotations={annotations}
            detectionList={detections ?? []}
            floorplan={floorplan}
            initiallyShowZoom
            mini={false}
            nodes={walkthrough?.nodes ?? []}
            onClickNode={view360Node}
            onClickDetection={handleDetectionSelect}
            onImageLoaded={() => setIsImageLoaded(true)}
            onToggleShowNodes={setShowNodes}
            showNodes={showNodes || detections.length === 0}
          />
        </Box>
      </Content>
    </InternalLayout>
  );
};

export default WalkthroughContainer;
