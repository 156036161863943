import { AxiosRequestConfig } from 'axios';

import { NodeSnapshotEmail, NodeSnapshotEmailRequestBody } from '../../../@types/api/v0/rest/Node';
import { post } from '../../next';

export const NodeApi = {
  /**
   * Share a snapshot with a set of recipients.
   * @param nodeId Unique ID of the node on which the snapshot was taken.
   * @param requestBody Request payload with snapshot details.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping a representation of the snapshot email, if successful.
   */
  sendSnapshotEmail: (nodeId: string, requestBody: NodeSnapshotEmailRequestBody, requestConfig?: AxiosRequestConfig) =>
    post<NodeSnapshotEmailRequestBody, NodeSnapshotEmail>(
      `/api/nodes/${nodeId}/snapshotemail/`,
      requestBody,
      requestConfig
    ),
};
