// TODO: replace all literal usages of these URL patterns with these constants.
const Routes = {
  LOGIN: '/login',
  LOGIN_CALLBACK: '/login-callback',
  LOGIN_PROCORE_CALLBACK: '/implicit/procore-callback',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:uid/:token',
  ONBOARDING: '/onboarding',
  SET_PASSWORD: '/set-password',
  SETTINGS: '/settings',
  PROJECTS: '/',
  PROJECT_DETAILS: '/projects/:id',
  PROJECT_PROGRESS_TRACKING: '/projects/:id/progress-tracking',
  FLOORPLAN: '/projects/:projectId/floorplans/:floorplanId',
  WALKTHROUGH: '/projects/:projectId/floorplans/:floorplanId/walkthroughs/:walkthroughId',
  ACCOUNT_USERS: '/users/:accountId?',
  VIEW_360: '/floorplan/:floorplanId/walkthrough/:walkthroughId/node/:nodeId',
  NOT_FOUND: '/not-found',
  // Legacy routes, primarily for redirection to newer URL patterns.
  LEGACY_FLOORPLAN: '/floorplan/:floorplanId/walkthrough/:walkthroughId',
  LEGACY_FLOORPLAN_EMPTY: '/floorplan/:floorplanId',
  LEGACY_PROJECT_DASHBOARD: '/projects/:id/dashboard',
  LEGACY_PROJECT_EXECUTIVE_DASHBOARD: '/projects/:id/executive-dashboard',
  LEGACY_PROJECT_ISSUES_REPORT: '/projects/:id/report',
  LEGACY_PROJECT_MILESTONE_INSIGHTS: '/projects/:id/milestone-insights',
};

export default Routes;
