import { Box, Card, CardBody, Flex, HStack, Heading, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { Project as LegacyProject } from '../../@types/OnSiteIQ';
import { Project } from '../../@types/api/v0/rest/Project';
import theme from '../../theme';
import { formatAddress } from '../../utils/stringUtils';

export interface ControlCenterProps {
  /** Current project. */
  project: Project | LegacyProject;
  /** List of elements to render within the Control Center. */
  fields?: ReactNode[];
}

const ControlCenter = ({ project, fields = [] }: ControlCenterProps) => {
  return (
    <Card>
      <CardBody>
        <Flex>
          <Box>
            <Heading as="h1" size="lg">
              {project.name}
            </Heading>
            <Text fontSize="0.875rem" color={theme.colors.brand.gray[500]}>
              {formatAddress(project.address_line, project.city, project.state, project.zip_code)}
            </Text>
          </Box>
          <HStack marginLeft="auto">{fields}</HStack>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default ControlCenter;
