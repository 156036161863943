import { schema } from 'normalizr';

const project = new schema.Entity('projects');
const floorplan = new schema.Entity('floorplans');
const walkthrough = new schema.Entity('walkthroughs');
const node = new schema.Entity('nodes');
const user = new schema.Entity('users');
const reply = new schema.Entity('replies');
const annotation = new schema.Entity('annotations');

reply.define({
  poster: user,
});

annotation.define({
  poster: user,
  replies: [reply],
});

node.define({
  annotations: [annotation],
});

walkthrough.define({
  nodes: [node],
  annotations: [annotation],
});

floorplan.define({
  walkthroughs: [walkthrough],
  annotations: [annotation],
  project,
});

project.define({
  annotations: [annotation],
  floorplans: [floorplan],
});

export const floorplanSchema = floorplan;
export const walkthroughSchema = walkthrough;
export const nodeSchema = node;
export const userSchema = user;
export const annotationSchema = annotation;
