import { MathUtils } from 'three';

export const CameraConstants = {
  /** Initial field of view value used by the three.js PerspectiveCamera. */
  INITIAL_FOV: 75,
  /** Minimum FOV, i.e. maximum amount the camera can be zoomed in. */
  MIN_FOV: 30,
  /** Maximum FOV, i.e. maximum amount the camera can be zoomed out. */
  MAX_FOV: 120,
  /** When the camera attempts to pan downward, limit its rotation to this value in radians. */
  MAX_PHI: 175 * MathUtils.DEG2RAD,
  /** When the camera attempts to pan upward, limit its rotation to this value in radians. */
  MIN_PHI: 5 * MathUtils.DEG2RAD,
};

export const ControlConstants = {
  /** When navigating with the arrow keys, the current camera orientation is compared against a target orientation
   * (facing the path) and a difference in angles is computed. If the vertical arrow keys are held down, the
   * difference will be decayed by this multiple each frame. */
  MOVE_ANGLE_CORRECTION_DECAY: 5e-2,
  /**
   * When navigating with the vertical arrow keys, we consider the current viewing angle to be adequately facing the
   * desired target if it is within this many radians.
   */
  MOVE_ANGLE_TOLERANCE: 10e-3,
  /** Angle (in radians) to pan when the horizontal arrow key is active. */
  KEYBOARD_PAN_INCREMENT: 0.25 * MathUtils.DEG2RAD,
  /** Number of times per frame to pan. We pan in small chunks for smoothness -- this is the chunk size. */
  KEYBOARD_PAN_STEPS_PER_FRAME: 5,
};

export const GeometryConstants = {
  /**
   * Annotations are placed at points on the sphere. If they were placed exactly at the point, they would clip. Back
   * them away from the sphere by applying a scale factor to their position.
   */
  ANNOTATION_POSITION_SCALE: 0.95,
  /** Magnitude of each annotation sprite's scale vector. */
  ANNOTATION_SCALE: 70,
  /** Camera height (in feet) in the 3D scene */
  CAMERA_HEIGHT: 7,
  /** During ground node selection, ensure nodes are separated by at least this distance in feet. */
  GROUND_NODE_CULL_MIN_SEPARATION_DISTANCE: 2,
  /**
   * During ground node selection, only show nodes with array indices within this distance.
   * @remark The Node type also has an `index` property, but here we refer to the index within the array of nodes.
   */
  GROUND_NODE_MAX_INDEX_DISTANCE: 17,
  /** Offset between each ground node to prevent clipping. */
  GROUND_NODE_OFFSET: 10e-5,
  /**
   * When working inside a canvas, we need to distinguish between clicks and drags. Ordinary click handlers fire for
   * both clicks and drags. As a result, we record the starting position on pointer down then check if the move distance
   * has exceeded this value in pixels after each move.
   */
  MAX_CLICK_DISTANCE: 9,
  /** Radius (in feet) of the sphere geometry on which the current 360º image is textured. */
  SPHERE_RADIUS: 900,
};

/** Names used to uniquely identify critical application textures. */
export const GlobalTextureNames = {
  ANNOTATION: 'ANNOTATION',
  GROUND_NODE: 'GROUND_NODE',
  GROUND_NODE_ANNOTATED: 'GROUND_NODE_ANNOTATED',
  SEAM_COVER: 'SEAM_COVER',
};

/**
 * Representation of the current direction in which the camera is currently looking or the user is currently moving via
 * the keyboard arrow keys.
 */
export enum NavigationDirection {
  BACKWARD = -1,
  FORWARD = 1,
}

/**
 * Representation of the current direction in which the camera is currently panning via the keyboard arrow keys.
 */
export enum PanDirection {
  RIGHT = -1,
  LEFT = 1,
}

/**
 * Constants related to application performance.
 */
export const PerformanceConstants = {
  /** Maximum number of lowest-resolution textures to preload in either direction about the current node. */
  MAX_PREVIEW_TEXTURE_PRELOAD_COUNT: 20,
  /**
   * When the user traverses the scene with arrow keys, compute the average camera pan angle using this many nodes
   * opposite the direction of motion. High values are slower, smoother, but prone to overcompensation.
   */
  AVERAGE_ANGLE_INCLUDE_BACKWARD: 5,
  /**
   * When the user traverses the scene with arrow keys, compute the average camera pan angle using this many nodes
   * in the direction of motion. High values are slower, smoother, but prone to overcompensation.
   */
  AVERAGE_ANGLE_INCLUDE_FORWARD: 5,
  /**
   * Limit the render frequency to some number of frames per second. Dividing 1000 by this number gives us the desired
   * interval between frames.
   */
  FPS_CAP_INTERVAL: 1000 / 90,
};

/**
 * In order to know whether or not loading should be started for a texture (in cases such as keyboard navigation when
 * loading requests are made rapidly), we need to know if loading is already in progress.
 */
export enum TextureLoadingStatus {
  /** The texture has not been loaded yet and loading is not in progress. */
  NOT_STARTED,
  /** The texture has not been loaded yet, but loading is in progress.  */
  LOADING,
  /** The texture has been loaded. */
  LOADED,
}

/**
 * Possible image (i.e. texture) qualities which the client may request for scene assets. Based on the device's graphics
 * capabilities, these will map to different resolutions.
 * @see TextureManager#setSceneTextureQuality
 */
export enum TextureQuality {
  PREVIEW,
  MAX,
}

/** Animation durations and debounce/throttle timings. Integer values are in milliseconds, decimals are in seconds. */
export const TimingConstants = {
  /** Duration of time to wait until the loading spinner is shown for long-running loading operations. */
  IDLE_WAIT_DURATION: 1500,
  /** Duration of the animation used to pan/tilt to a given point. */
  LOOK_TRANSITION_DURATION: 0.3,
  /** Duration of the animation used to fade the scene in or out while moving between nodes. */
  NODE_TRANSITION_FADE_DURATION: 0.4,
  /**
   * When many node change events are fired (e.g. during keyboard arrow navigation), Safari only allows 100 history
   * push/replace calls per 30 seconds.
   */
  RATE_LIMIT_CHANGE_NODE_EVENT: 300,
  /** How many milliseconds to wait until the viewer should navigate to the next node via keyboard arrows. */
  RATE_LIMIT_KEYBOARD_NAVIGATION: 180,
  /** How frequently the 2D map orientation is updated (about 60fps). */
  RATE_LIMIT_MAP_DIRECTION_CHANGE_EVENT: 16,
  /** How frequently to respond to pointer wheel events (about 20fps). */
  RATE_LIMIT_POINTER_WHEEL: 50,
};

/** Names used for events which originate within the viewer or its canvas. */
export const ViewerEvents = {
  ON_CHANGE_CAMERA_DIRECTION: 'onchangecameradirection',
  ON_CHANGE_NODE: 'onchangenode',
  ON_CLICK_ANNOTATION: 'onclickannotation',
  ON_ERROR: 'onerror',
  ON_LOOK_TRANSITION_COMPLETE: 'onlooktransitioncomplete',
  ON_MARK_POINT: 'onmarkpoint',
  ON_SCENE_UPDATE_COMPLETE: 'onsceneupdatecomplete',
  ON_SCENE_UPDATE_START: 'onsceneupdatestart',
  ON_SNAPSHOT_AVAILABLE: 'onsnapshotavailable',
  ON_ZOOM: 'onzoom',
};
