import { ReactElement, useMemo } from 'react';

import { LoadingIndicator } from '../..';
import { Project as LegacyProject, Walkthrough as LegacyWalkthrough, TimeTravelPair } from '../../../@types/OnSiteIQ';
import { Project } from '../../../@types/api/v0/rest/Project';
import { Walkthrough } from '../../../@types/api/v0/rest/Walkthrough';
import { DrawerBody, DrawerHeader, SidebarActionDrawer } from '../../SidebarActionDrawer';
import TimeTravelListItem from './TimeTravelListItem';

/**
 * This component may be used to show a list of options which are either:
 * - From the walkthrough page, walkthroughs of the same floorplan.
 * - From the 360º page, nodes close to the current position on other walkthroughs of the same floorplan.
 */
export type TimeTravelOption = { id: number; when: string } | TimeTravelPair;

export interface TimeTravelDrawerProps {
  /** Function to call to close the drawer. */
  close: () => void;
  /**
   * Flag indicating whether or not to show a loading indicator.
   *
   * Useful in cases when the parent component will change the drawer's content soon, preventing the user from changing
   * the current time travel selection. For example, when the 360º viewer is transitioning between ground nodes on a
   * slow connection, the user should not be able to change the current walkthrough mid-stream.
   */
  isLoading?: boolean;
  /** Handler to call when the user selects a time travel option. */
  onSelectOption: (option: TimeTravelOption) => void;
  /** List of time travel options to show to the user. */
  options: TimeTravelOption[];
  /** Current project details. Used to render a header on the top of the drawer. */
  project: Project | LegacyProject;
  /** Flag indicating whether or not to show the drawer. */
  show: boolean;
  /** Flag indicating whether or not to render thumbnails for each time travel list item. */
  showThumbnails?: boolean;
  /** Current walkthrough visible to the user. */
  walkthrough?: Walkthrough | LegacyWalkthrough;
}

const sortByDate = (a: TimeTravelOption, b: TimeTravelOption): number => {
  if (new Date(a.when) > new Date(b.when)) {
    return -1;
  }
  if (new Date(b.when) > new Date(a.when)) {
    return 1;
  }

  return 0;
};

const TimeTravelDrawer = (props: TimeTravelDrawerProps): ReactElement => {
  const { close, isLoading, options, onSelectOption, project, show, showThumbnails, walkthrough } = props;

  const sortedOptions = useMemo(() => [...options].sort(sortByDate), [options]);

  return (
    <SidebarActionDrawer close={close} show={show}>
      <DrawerHeader project={project} date={walkthrough?.when} />
      <DrawerBody>
        {isLoading && <LoadingIndicator />}
        {!isLoading &&
          sortedOptions.map((option) => (
            <TimeTravelListItem
              currentWalkthroughId={walkthrough?.id ?? 0}
              key={option.id}
              onSelectOption={onSelectOption}
              option={option}
              showThumbnail={showThumbnails}
            />
          ))}
      </DrawerBody>
    </SidebarActionDrawer>
  );
};

export default TimeTravelDrawer;
