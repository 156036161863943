import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';

import { FloorsIcon, MarkupsIcon, ProgressTrackingIcon, SearchIcon, TimeIcon } from '../Icon';

import classes from './Sidebar.module.scss';

export type ActionProps = Omit<BaseProps, 'children' | 'label'>;

interface BaseProps {
  active?: boolean;
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  label: string;
  onClick?: () => void;
}

export const ActionItem = (props: BaseProps): ReactElement => (
  <button
    className={classNames(classes.actionItem, props.active && classes.actionItemActive, props.className)}
    data-pendo-label={props.label}
    data-pendo-topic="sidebar-action"
    disabled={props.disabled}
    onClick={props.onClick}
  >
    {props.children}
    <span className={classes.actionItemLabel}>{props.label}</span>
  </button>
);

export const AnnotationsAction = (props: ActionProps): ReactElement => (
  <ActionItem active={props.active} key="Markups" label="Markups" onClick={props.onClick}>
    <MarkupsIcon aria-hidden />
  </ActionItem>
);

export const ChangeFloorplanAction = (props: ActionProps): ReactElement => (
  <ActionItem active={props.active} key="Floors" label="Floors" onClick={props.onClick}>
    <FloorsIcon aria-hidden />
  </ActionItem>
);

export const SearchAction = (props: ActionProps): ReactElement => (
  <ActionItem active={props.active} key="Search" label="Search" onClick={props.onClick}>
    <SearchIcon aria-hidden />
  </ActionItem>
);

export const TimeTravelAction = (props: ActionProps): ReactElement => (
  <ActionItem active={props.active} key="Time Travel" label="Time Travel" onClick={props.onClick}>
    <TimeIcon aria-hidden />
  </ActionItem>
);

export const ProgressTrackingAction = (props: ActionProps): ReactElement => (
  <ActionItem active={props.active} key="Progress" label="Progress" onClick={props.onClick}>
    <ProgressTrackingIcon aria-hidden />
  </ActionItem>
);
