import { useDispatch } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { LegacyAction, Store } from '../@types/redux/store';

/**
 * The return type of react-redux dispatch functions for thunks is incorrect. This thin wrapper gives us a correctly typed
 * dispatch function using our store's dispatch function.
 */
function useThunkDispatch() {
  return useDispatch<ThunkDispatch<Store, unknown, Action<LegacyAction>>>();
}

export default useThunkDispatch;
