import { Network } from '../@types/redux/store';
import { NETWORK_ERR, NETWORK_REQ, NETWORK_SUCCESS } from '../constants/actionTypes';
import { NetworkMessage } from '../utils/network';

export const initialNetworkState: Network = {
  projects: {},
  walkthroughs: {},
  floorplans: {},
  timetravelPairs: {},
  matchingFloorplans: {},
};

function isNetworkField(state: Network, field: string): field is keyof Network {
  return field in state;
}

export default function reducer(state: Network | undefined, action: NetworkMessage): Network {
  if (!state) {
    return initialNetworkState;
  }

  if (action.type === NETWORK_REQ) {
    const kind = typeof action.kind === 'object' ? action.kind.netKind : action.kind;
    const data = { loaded: false, when: Date.now() };

    if (isNetworkField(state, kind)) {
      if (!action.id) {
        return { ...state, [kind]: data };
      }

      return {
        ...state,
        [kind]: { ...state[kind], [action.id]: data },
      };
    }
  }

  if (action.type === NETWORK_ERR) {
    const kind = typeof action.kind === 'object' ? action.kind.netKind : action.kind;
    const error = action.error ? action.error.message : 'Error';
    const data = { loaded: false, error, when: Date.now() };

    console.error(action.error);

    if (isNetworkField(state, kind)) {
      if (!action.id) {
        return { ...state, [kind]: data };
      }

      return { ...state, [kind]: { ...state[kind], [action.id]: data } };
    }
  }

  if (action.type === NETWORK_SUCCESS) {
    const kind = typeof action.kind === 'object' ? action.kind.netKind : action.kind;
    const data = { loaded: true, when: Date.now() };

    if (isNetworkField(state, kind)) {
      if (!action.id) {
        return { ...state, [kind]: data };
      }

      return { ...state, [kind]: { ...state[kind], [action.id]: data } };
    }
  }

  return state;
}
