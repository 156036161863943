import {
  Box,
  Button,
  Drawer as ChakraDrawer,
  DrawerProps as ChakraDrawerProps,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Icon,
  Text,
} from '@chakra-ui/react';
import { MouseEventHandler, ReactNode } from 'react';

import { DrawerType, PendoTopic } from '../../constants/analytics';
import theme from '../../theme';
import { DoubleRightArrowIcon } from '../Icon';
import LoadingIndicator from '../LoadingIndicator';

const DefaultErrorMessage = (
  <Text>
    An error occurred. Please try again later. If this issue persists, contact{' '}
    <a href="mailto:customersuccess@onsiteiq.io">customersuccess@onsiteiq.io</a> for assistance.
  </Text>
);

type DrawerProps = Pick<ChakraDrawerProps, 'isOpen' | 'onClose' | 'size'> & {
  /** Pendo topic used for analytics. */
  pendoTopic: PendoTopic;
  /** Used in analytics. */
  drawerType: DrawerType;
  /** Rendered in the drawer header as a heading. */
  primaryText?: string;
  /** Optional text to display under the primary text. */
  secondaryText?: string;
  /** Whether or not the drawer is fetching data. */
  isFetching?: boolean;
  /** Whether or not there was an error fetching data. */
  isError?: boolean;
  /** Content to display when `isError` is true. Defaults to a generic error message. */
  errorContent?: ReactNode;
  /** Optional additional content to display in the header, under the primary and secondary text. */
  additionalHeaderContent?: ReactNode;
  /** Content to display in the body of the drawer. */
  bodyContent?: ReactNode;
  /** Optional props to pass to the footer button. If provided, a button will be rendered in the footer. */
  footerButtonProps?: { pendoLabel: string; onClick: MouseEventHandler; text: string };
};

export const Drawer = ({
  isOpen,
  onClose,
  size = 'md',
  pendoTopic,
  drawerType,
  primaryText,
  secondaryText,
  isFetching,
  isError,
  errorContent = DefaultErrorMessage,
  additionalHeaderContent,
  bodyContent,
  footerButtonProps,
}: DrawerProps) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-autofocus
    <ChakraDrawer isOpen={isOpen} onClose={onClose} size={size} autoFocus={false}>
      <DrawerOverlay data-testid="drawer-overlay" />
      <DrawerContent>
        <DrawerCloseButton
          aria-label="Close drawer"
          data-pendo-topic={pendoTopic}
          data-pendo-label={`Close ${drawerType}`}
        >
          <Icon aria-hidden as={DoubleRightArrowIcon} height="1.5rem" width="1.5rem" />
        </DrawerCloseButton>
        <DrawerHeader>
          <Heading as="h1" size="lg">
            {primaryText}
          </Heading>
          {/* TODO: this should probably be an h2-level heading, even if it appears as a regular <Text> block... */}
          {secondaryText && (
            <Text fontSize="0.875rem" color={theme.colors.brand.gray[500]}>
              {secondaryText}
            </Text>
          )}
          <Box fontSize="1rem">{additionalHeaderContent}</Box>
        </DrawerHeader>
        <DrawerBody>
          {isFetching && <LoadingIndicator />}
          {isError && !isFetching && <Box margin="0 1rem">{errorContent}</Box>}
          {!isFetching && !isError && <>{bodyContent}</>}
        </DrawerBody>
        {footerButtonProps && (
          <DrawerFooter>
            <Button
              data-pendo-topic={pendoTopic}
              data-pendo-label={footerButtonProps.pendoLabel}
              marginTop="auto"
              width="100%"
              variant="mediumEmphasisV2"
              onClick={footerButtonProps.onClick}
            >
              {footerButtonProps.text}
            </Button>
          </DrawerFooter>
        )}
      </DrawerContent>
    </ChakraDrawer>
  );
};
