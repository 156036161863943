import { Box, FormControl, FormLabel, Select, Stack, Text, useDisclosure } from '@chakra-ui/react';
import classNames from 'classnames';
import maxBy from 'lodash/maxBy';
import orderBy from 'lodash/orderBy';
import { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Floorplan } from '../../@types/api/v0/rest/Floorplan';
import { Project } from '../../@types/api/v0/rest/Project';
import { Button, Content, InternalLayout, PageTitleCard, SearchInput } from '../../components';
import { ViewUsersDrawerContainer } from '../../components/Drawers/UserManagement/ViewUsersDrawerContainer';
import theme from '../../theme';
import { countDisplay, naturalSort } from '../../utils';
import { generateProjectPageUrl } from '../../utils/navigationUtils';
import OxBluePreview from './OxBluePreview';

import commonClasses from '../../Common.module.scss';
import classes from './ProjectDetails.module.scss';

export interface ProjectDetailsPageProps {
  /** The current project. */
  project: Project;
}

const ProjectDetailsPage = (props: ProjectDetailsPageProps): ReactElement => {
  const { project } = props;

  const history = useHistory();

  const [searchTerm, setSearchTerm] = useState('');
  const [sortType, setSortType] = useState<'alpha' | 'lastUpdated'>('alpha');

  const {
    isOpen: isViewUsersDrawerOpen,
    onOpen: onViewUsersDrawerOpen,
    onClose: onViewUsersDrawerClose,
  } = useDisclosure();

  const sortOptions = [
    { label: 'Floor plan name', value: 'alpha' },
    { label: 'Date updated', value: 'lastUpdated' },
  ];

  const filteredFloorplans = project.floorplans.filter(
    ({ name }) => name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
  );
  const floorplansSortedByName = naturalSort(filteredFloorplans, 'name');
  const floorplansSortedByRecency = orderBy(filteredFloorplans, 'updated');

  const floorplans = sortType === 'alpha' ? floorplansSortedByName : floorplansSortedByRecency;

  const goToFloorplan = (floorplan: Floorplan) => {
    const mostRecentWalkthroughId = maxBy(floorplan.dated_walkthroughs ?? [], 'when')?.id;

    const destination = generateProjectPageUrl(project.id, floorplan.id, mostRecentWalkthroughId);
    history.push(destination);
  };

  return (
    <>
      <InternalLayout>
        <Content applyGutters>
          <Stack direction="row" justifyContent="space-between">
            <PageTitleCard
              date={project.updated ?? undefined}
              datePrefix={project.updated ? 'Last walkthrough' : undefined}
              subtitle={project.address_line}
              title={project.name}
            />
            <Button
              variant="mediumEmphasis"
              background="none !important"
              data-pendo-topic="user-management"
              data-pendo-label="Open project users drawer from project details page"
              onClick={onViewUsersDrawerOpen}
            >
              View users
            </Button>
          </Stack>
          {project.floorplans.length === 0 ? (
            <Box backgroundColor={theme.colors.white} borderRadius="0.5rem" marginTop="1rem" padding="2.5rem 1rem">
              <Text color={theme.colors.brand.gray['600']} lineHeight="1.7" textAlign="center">
                It looks like this project doesn't have any floor plans yet.
              </Text>
            </Box>
          ) : (
            <>
              {project.oxblue && (
                <div className={classes.card}>
                  <OxBluePreview imageUrl={project.oxblue} link={project.oxblue_link} />
                </div>
              )}
              <SearchInput
                className={classes.searchInput}
                inputProps={{ 'data-pendo-label': 'Search floorplans', 'data-pendo-topic': 'project' }}
                onChange={setSearchTerm}
                placeholder="Search for floor plans"
                value={searchTerm}
                variant="light"
              />
              <div className={classNames(classes.card, classes.actionsCard)}>
                <div className={classes.actions}>
                  <FormControl className={classNames(commonClasses.horizontalFormControl, classes.sortFormControl)}>
                    <FormLabel>Sort by</FormLabel>
                    <Select
                      className={classNames(classes.select)}
                      onChange={(event) => setSortType(event.target.value as 'lastUpdated' | 'alpha')}
                      value={sortType}
                    >
                      {sortOptions.map((sortOption) => (
                        <option key={sortOption.value} value={sortOption.value}>
                          {sortOption.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {floorplans.length === 0 && <p className={classes.emptyList}>No results were found for your search.</p>}
                {floorplans.length > 0 && (
                  <>
                    {floorplans.map((floorplan) => (
                      <button
                        className={classes.floorCard}
                        data-pendo-label="Select floorplan"
                        data-pendo-topic="project"
                        key={`floor-result-${floorplan.id}`}
                        onClick={() => goToFloorplan(floorplan)}
                      >
                        <span className={classes.title}>{floorplan.name}</span>
                        <span className={classes.subtitle}>
                          {countDisplay(floorplan.dated_walkthroughs.length, 'walkthrough')}
                          {' | '}
                          {countDisplay(floorplan.annotation_count, 'observation')}
                        </span>
                      </button>
                    ))}
                  </>
                )}
              </div>
            </>
          )}
        </Content>
      </InternalLayout>
      <ViewUsersDrawerContainer isOpen={isViewUsersDrawerOpen} onClose={onViewUsersDrawerClose} project={project} />
    </>
  );
};

export default ProjectDetailsPage;
