import { denormalize } from 'normalizr';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { DenormalizedAnnotation, Node, Walkthrough } from '../../@types/OnSiteIQ';
import { Store } from '../../@types/redux/store';
import * as projectActions from '../../actions/projects';
import { InternalLayout, LoadingIndicator } from '../../components';
import { annotationSchema, nodeSchema, walkthroughSchema } from '../../constants/schemas';
import View360Page from './View360Page';

const View360Container = () => {
  const history = useHistory();
  const urlParameters = useParams<{ floorplanId: string; nodeId: string; walkthroughId: string }>();

  const dispatch = useDispatch();
  const drawer = useSelector((state: Store) => state.app.drawer);
  const entities = useSelector((state: Store) => state.entities);

  const floorplanId = Number(urlParameters.floorplanId);
  const nodeId = urlParameters.nodeId;
  const walkthroughId = Number(urlParameters.walkthroughId);

  const floorplan = useSelector((state: Store) => state.entities.floorplans[floorplanId]);
  const [walkthrough, setWalkthrough] = useState<Walkthrough>();

  /**
   * When Redux ingests the Walkthrough instance from the server, it "normalizes" it by removing the `nodes` property.
   * As a result, we need to add it back. Memoizing `walkthrough` below doesn't work because creating a new object on
   * every change to `normalizedWalkthrough` causes excessive re-renders in the child component.
   */
  const normalizedWalkthrough = useSelector((state: Store) => state.entities.walkthroughs[walkthroughId]);
  useEffect(() => {
    if (walkthroughId === walkthrough?.id) {
      return;
    }

    const denormalizedWalkthrough = denormalize(walkthroughId, walkthroughSchema, entities);
    setWalkthrough(denormalizedWalkthrough);
  }, [normalizedWalkthrough, entities, walkthrough?.id, walkthroughId]);

  const node = denormalize(nodeId, nodeSchema, entities) as Node | undefined;
  const project = floorplan ? entities.projects[floorplan.project] : undefined;

  useEffect(() => {
    dispatch(projectActions.loadFloorplan(floorplanId));
  }, [dispatch, floorplanId]);

  useEffect(() => {
    dispatch(
      projectActions.loadWalkthrough(walkthroughId, (error: Response) =>
        projectActions.catchWalktroughError(error, history)
      )
    );
  }, [dispatch, history, walkthroughId]);

  if (!project || !floorplan || !walkthrough || !node) {
    return (
      <InternalLayout>
        <LoadingIndicator fullPage />
      </InternalLayout>
    );
  }

  const projectFloorplans = Object.values(entities.floorplans).filter((f) => f.project === project.id);

  const otherWalkthroughs = floorplan.dated_walkthroughs.filter((w) => w.id !== walkthroughId);
  const walkthroughAnnotations = Object.values(entities.annotations)
    .filter((a) => walkthrough.nodes.map((n) => n.id).includes(a.node))
    .map((a) => denormalize(a, annotationSchema, entities) as DenormalizedAnnotation);
  const nodeAnnotations = walkthroughAnnotations.filter((a) => a.node === node.id);

  /**
   * @todo these can use a lexographic sort instead
   * i.e. t2.when - t1.when. Just need to ensure the time portion begins with Z or +.
   */
  const timeTravelPairs = [...(entities.timetravelPairs[node.id]?.pairs ?? [])].sort(
    (t1, t2) => Number(new Date(t2.when)) - Number(new Date(t1.when))
  );
  const matchingFloorplans = [...(entities.matchingFloorplans[node.id]?.pairs ?? [])].sort(
    (t1, t2) => Number(new Date(t2.when)) - Number(new Date(t1.when))
  );

  return (
    <View360Page
      drawer={drawer}
      floorplan={floorplan}
      matchingFloorplans={matchingFloorplans}
      node={node}
      nodeAnnotations={nodeAnnotations}
      otherWalkthroughs={otherWalkthroughs}
      project={project}
      projectFloorplans={projectFloorplans}
      timeTravelPairs={timeTravelPairs}
      walkthrough={walkthrough}
      walkthroughAnnotations={walkthroughAnnotations}
    />
  );
};

export default View360Container;
