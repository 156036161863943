import classNames from 'classnames';
import { differenceInDays, differenceInMilliseconds } from 'date-fns';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

import { Floorplan as LegacyFloorplan, Project as LegacyProject, MatchingFloorplan } from '../../../@types/OnSiteIQ';
import { Floorplan } from '../../../@types/api/v0/rest/Floorplan';
import { Project } from '../../../@types/api/v0/rest/Project';
import { formatIsoDate } from '../../../utils/dateUtils';
import { generateProjectPageUrl } from '../../../utils/navigationUtils';
import DateWarning from './DateWarning';

import classes from './ChangeFloorplan.module.scss';

interface FloorplanCardProps {
  action?: () => void;
  active?: boolean;
  currentDate?: string;
  floorplan: Floorplan | LegacyFloorplan;
  match?: MatchingFloorplan;
  project: Project | LegacyProject;
}

const FloorplanCard = ({
  action,
  active,
  currentDate,
  floorplan,
  match,
  project,
}: FloorplanCardProps): ReactElement => {
  const [dateWarningModalOpen, setDateWarningModalOpen] = useState(false);

  const wrapperRef = useRef<HTMLButtonElement>(null);

  const [cookies] = useCookies(['date-warning-acknowledged']);
  const warningAcknowledged = Boolean(cookies['date-warning-acknowledged']);

  const history = useHistory();

  // Creates a link and -- if possible -- a date for the best matching walkthrough
  // TODO: this function is a perfect candidate for unit testing
  const [link, when]: [string, string | undefined] = useMemo(() => {
    // If we were passed in a match, our work is already done.
    if (match) {
      return [generateProjectPageUrl(project.id, floorplan.id, match.walkthrough, match.id), match.when];
    }

    // If this floorplan doesn't have walkthroughs, return undefined.
    if (floorplan.dated_walkthroughs.length === 0) {
      return [generateProjectPageUrl(project.id, floorplan.id), undefined];
    }

    // If we don't have a date for the current walkthrough just go to the latest walkthrough.
    if (!currentDate) {
      const latest = maxBy(floorplan.dated_walkthroughs, 'when');
      return latest
        ? [generateProjectPageUrl(project.id, floorplan.id, latest.id), latest.when]
        : [generateProjectPageUrl(project.id, floorplan.id), undefined];
    }

    // Get the absolute difference (in ms) between our current walkthrough and all other floorplan walkthroughs.
    const diffs = floorplan.dated_walkthroughs.map((d) => ({
      ...d,
      diff: Math.abs(differenceInMilliseconds(new Date(d.when), new Date(currentDate))),
    }));

    // Get the best match
    const bestMatch = minBy(diffs, 'diff');

    // This should never return undefined here, this is just a fallback to appease typescript
    return bestMatch
      ? [generateProjectPageUrl(project.id, floorplan.id, bestMatch.id), bestMatch.when]
      : [generateProjectPageUrl(project.id, floorplan.id), undefined];
  }, [currentDate, floorplan.dated_walkthroughs, floorplan.id, match, project.id]);

  const daysDifference = when && currentDate ? differenceInDays(new Date(when), new Date(currentDate)) : 0;
  /**
   * We want to show the date warning any time the date of the next selected walkthrough is different from
   * the currently-selected walkthrough's date, since the user might not realize they're changing dates by
   * just clicking a different floor
   */
  const showDateWarningOnClick = when && currentDate && !warningAcknowledged && Math.abs(daysDifference) > 0;

  function navigateToWalkthrough() {
    if (dateWarningModalOpen) {
      setDateWarningModalOpen(false);
    }

    // If an additional action should be performed, call it now.
    action?.();

    history.push(link);
  }

  function onCardClick() {
    if (active) {
      return;
    }

    if (showDateWarningOnClick) {
      setDateWarningModalOpen(true);
    } else {
      navigateToWalkthrough();
    }
  }

  useEffect(() => {
    if (wrapperRef.current && active) {
      wrapperRef.current.scrollIntoView({
        block: 'center',
      });
    }
  }, [active, wrapperRef]);

  return (
    <>
      <button
        className={classNames(classes.card, active && classes.cardActive)}
        data-pendo-label="Select floor"
        data-pendo-topic="drawer"
        onClick={onCardClick}
        ref={wrapperRef}
      >
        <span className={classes.title}>{floorplan.name}</span>
        <span className={classes.subtitle}>
          <span>{floorplan.dated_walkthroughs?.length ?? 0} Walkthroughs</span>
          <span>{formatIsoDate(when)?.formattedDateTime}</span>
        </span>
      </button>
      {showDateWarningOnClick && (
        <DateWarning
          floorplanName={floorplan.name}
          fromDate={currentDate}
          onAcknowledge={navigateToWalkthrough}
          onCancelOrClose={() => setDateWarningModalOpen(false)}
          open={dateWarningModalOpen}
          toDate={when}
        />
      )}
    </>
  );
};

export default FloorplanCard;
