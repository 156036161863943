import { extendTheme } from '@chakra-ui/react';

import { accordionTheme } from './themes/accordionTheme';
import { buttonTheme } from './themes/buttonTheme';
import { cardTheme } from './themes/cardTheme';
import { checkboxTheme } from './themes/checkboxTheme';
import { drawerTheme } from './themes/drawerTheme';
import { formTheme } from './themes/formTheme';
import { headingTheme } from './themes/headingTheme';
import { menuTheme } from './themes/menuTheme';
import { popoverTheme } from './themes/popoverTheme';
import { progressTheme } from './themes/progressTheme';
import { statTheme } from './themes/statTheme';
import { tableTheme } from './themes/tableTheme';
import { tabsTheme } from './themes/tabsTheme';
import { tagTheme } from './themes/tagTheme';
import { tooltipTheme } from './themes/tooltipTheme';

const theme = extendTheme({
  colors: {
    brand: {
      primary: {
        100: '#e8e4ff',
        200: '#b4aaf3',
        300: '#9572f8',
        400: '#7142f1',
        500: '#936ef7',
        600: '#500bc1',
        700: '#330790',
        800: '#21055d',
        900: '#0f0330',
      },
      secondary: {
        100: '#f1fce5',
        200: '#bcf4b3',
        300: '#aaf297',
        400: '#acee8d',
        500: '#7fcd64',
        600: '#41a902',
        700: '#388001',
        800: '#1e4f01',
        900: '#093300',
      },
      // TODO: progressively moved these into the top-level "gray" key. Incrementally done to prevent accidental
      // styling overrides of built-in Chakra components.
      gray: {
        50: '#fafafa',
        100: '#efefef',
        200: '#c9cfcb',
        300: '#aeb7b2',
        400: '#9ea5a1',
        500: '#8c928e',
        600: '#6c716e',
        700: '#525755',
        800: '#2a2f2c',
        900: '#181b19',
      },
      // TODO: decide if we want to use error.200 / error.500 OR errorInternal / errorExternal for this.
      // TODO: one consistent error color (ask Peter)
      error: {
        100: '#ffdede',
        200: '#ec7643',
        500: '#cb5e3c',
      },
      errorInternal: '#ec7643',
      errorExternal: '#cb5e3c',
      // Palette generated with Smart Swatch using #cb8620 (the 500 shade below).
      warning: {
        50: '#fff3de',
        // Generated:
        // 100: '#f8deb8',
        // Ours (200 shade, but 50% lightened):
        100: '#fee094',
        // Generated:
        // 200: '#efc88e',
        // Ours (from Figma):
        200: '#fec229',
        300: '#e7b264',
        400: '#e09c39',
        // Generated:
        // 500: '#c6831f',
        // Ours (from Figma):
        500: '#cb8620',
        600: '#9a6616',
        700: '#6f490e',
        800: '#432c03',
        900: '#1b0e00',
      },
    },
    // TODO: continue overriding this with colors from brand.gray above.
    gray: {
      200: '#c9cfcb',
      800: '#2a2f2c',
    },
    white: '#FFFFFF',
  },
  components: {
    Accordion: accordionTheme,
    Button: buttonTheme,
    Card: cardTheme,
    Checkbox: checkboxTheme,
    Drawer: drawerTheme,
    Form: formTheme,
    Heading: headingTheme,
    Menu: menuTheme,
    Popover: popoverTheme,
    Progress: progressTheme,
    Stat: statTheme,
    Tabs: tabsTheme,
    Table: tableTheme,
    Tag: tagTheme,
    Tooltip: tooltipTheme,
  },
  textStyles: {
    detail: {
      fontSize: '0.875rem',
      letterSpacing: '0.1px',
      lineHeight: 1.286,
    },
  },
  radii: {
    card: '0.5rem',
    input: '0.375rem',
  },
});

export default theme;
