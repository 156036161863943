import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Account } from '../../../@types/OnSiteIQ';
import { AccountUser } from '../../../@types/UserManagement';
import { UserManagementApi } from '../../../api/next';
import { QueryTopics, UserManagementQueryKeys } from '../../../constants/queries';
import Toast from '../../Toast';

type UserRemovalConfirmationModalProps = Omit<ModalProps, 'children'> & {
  user: AccountUser;
  account: Account;
  /** Function called when the Confirm button is clicked */
  onConfirm: () => void;
};

export const UserRemovalConfirmationModal = (props: UserRemovalConfirmationModalProps) => {
  const { isOpen, onClose, account, user, onConfirm } = props;

  const queryClient = useQueryClient();
  const toast = useToast();

  const removeFromAccountMutation = useMutation({
    mutationKey: [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.REMOVE_FROM_ACCOUNT, user?.id],
    mutationFn: ({ account, user }: { account: Account; user: AccountUser }) =>
      UserManagementApi.removeMember(account.id, user.id),
    onSuccess: (_, variables) => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (toastProps) => (
          <Toast
            {...toastProps}
            title="Success"
            description={`${variables.user.name} has been removed from ${account.name}.`}
            status="success"
          />
        ),
      });

      onConfirm();
      onClose();

      // TODO: invalidate more stuff -- this should also clear the shared projects
      queryClient.invalidateQueries({
        queryKey: [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.ACCOUNT_MEMBERS, variables.account.id],
      });
    },
    onError: (error) => {
      console.error('[UserRemovalConfirmationModal] Failed to remove user from account', error);
    },
  });

  const handleCancel = () => {
    removeFromAccountMutation.reset();
    onClose();
  };

  const handleConfirm = () => {
    removeFromAccountMutation.mutate({ account, user });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="lg">
      <ModalOverlay />
      {/* TODO: move this padding to the Chakra theme/default  */}
      <ModalContent padding="1rem 1.5rem !important">
        <ModalHeader>Remove user</ModalHeader>
        {removeFromAccountMutation.isError && (
          <ModalBody>
            <Text fontSize="0.875rem">
              An error occurred while removing {user.name} from this account ({account.name}). Please try again later or
              contact our Customer Success team at{' '}
              <a href="mailto:customersuccess@onsiteiq.io">customersuccess@onsiteiq.io</a> if this issue persists.
            </Text>
          </ModalBody>
        )}
        {!removeFromAccountMutation.isError && (
          <ModalBody data-testid="userRemovePromptText">
            {'Are you sure you want to remove '}
            <Text fontWeight={700} as="span">
              {user.name ? user.name : user.email}
            </Text>
            {' from '}
            <Text fontWeight={700} as="span">
              {account.name}
            </Text>
            {'?'}
          </ModalBody>
        )}
        <ModalFooter marginTop="4rem">
          {removeFromAccountMutation.isError && (
            <Button variant="mediumEmphasisV2" onClick={handleCancel} marginRight="0.75rem">
              Close
            </Button>
          )}
          {!removeFromAccountMutation.isError && (
            <>
              <Button
                variant="mediumEmphasisV2"
                isDisabled={removeFromAccountMutation.isLoading}
                onClick={handleCancel}
                marginRight="0.75rem"
              >
                Cancel
              </Button>
              <Button variant="highEmphasisV2" isLoading={removeFromAccountMutation.isLoading} onClick={handleConfirm}>
                Confirm
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
