import { AspectRatio, Box, Image, Link } from '@chakra-ui/react';

export interface OxBluePreviewProps {
  /** URL of the OxBlue preview image. */
  imageUrl: string;
  /** URL of the OxBlue project. */
  link: string | null;
}

const OxBluePreview = (props: OxBluePreviewProps) => {
  const { imageUrl, link } = props;

  const preview = (
    <Box maxWidth="16rem" position="relative">
      <AspectRatio ratio={4 / 3}>
        <Image alt="Project captured by OxBlue" src={imageUrl} title="Project captured by OxBlue" />
      </AspectRatio>
      <Image
        alt=""
        bottom="0.5rem"
        height="auto"
        position="absolute"
        right="0.5rem"
        src="https://oxblue.com/pro/images/logo_oxblue_84x28.png"
        width="5.25rem"
      />
    </Box>
  );

  if (!link) {
    return preview;
  }

  return (
    <Link href={link} rel="noopener noreferrer" target="_blank">
      {preview}
    </Link>
  );
};

export default OxBluePreview;
