import { Flex, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, generatePath, useHistory, useParams } from 'react-router-dom';

import { setSidebarActionsList } from '../../actions/app';
import { ProjectApi } from '../../api/v0/rest/ProjectApi';
import { Content, InternalLayout, LoadingIndicator } from '../../components';
import { ErrorIcon } from '../../components/Icon';
import { ProjectHierarchyQueryKeys, QueryTopics } from '../../constants/queries';
import Routes from '../../routes';
import theme from '../../theme';
import ProjectDetailsPage from './ProjectDetailsPage';

import commonClasses from '../../Common.module.scss';

const ProjectDetailsContainer = (): ReactElement => {
  const history = useHistory();
  const urlParameters = useParams<{ id: string }>();
  const projectId = Number(urlParameters.id);

  const dispatch = useDispatch();

  const projectDetailsQuery = useQuery({
    queryKey: [QueryTopics.PROJECT_HIERARCHY, ProjectHierarchyQueryKeys.PROJECT_DETAILS, projectId],
    queryFn: async ({ signal }) => (await ProjectApi.getById(projectId, { signal })).data,
  });

  useEffect(() => {
    if (!isFinite(projectId) || projectId === 0) {
      return;
    }

    dispatch(
      setSidebarActionsList([
        {
          actionItem: 'progress',
          onClick: () => {
            history.push(generatePath(Routes.PROJECT_PROGRESS_TRACKING, { id: projectId }));
          },
          active: false,
        },
      ])
    );
  }, [dispatch, history, projectId]);

  if (projectDetailsQuery.isFetching) {
    return (
      <InternalLayout>
        <LoadingIndicator fullPage />
      </InternalLayout>
    );
  }

  if (projectDetailsQuery.isError || !projectDetailsQuery.data) {
    const statusCode = (projectDetailsQuery.error as AxiosError)?.response?.status;
    switch (statusCode) {
      case 403:
      case 404:
        // TODO: TS-219: replace with dedicated HTTP 403 page where user can request access.
        return <Redirect to={Routes.NOT_FOUND} />;
      case 500:
      default: {
        return (
          <InternalLayout>
            <Content constrainToPageHeight>
              <Flex alignItems="center" height="100%" justifyContent="center" flexDir="column">
                <ErrorIcon aria-hidden className={commonClasses.errorIcon} />
                <Text color={theme.colors.brand.gray[900]} textAlign="center">
                  Failed to load project details. Please try again later.
                </Text>
              </Flex>
            </Content>
          </InternalLayout>
        );
      }
    }
  }

  return <ProjectDetailsPage project={projectDetailsQuery.data} />;
};

export default ProjectDetailsContainer;
