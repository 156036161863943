import { FormLabel, Textarea } from '@chakra-ui/react';
import classNames from 'classnames';
import { ChangeEvent, FormEvent, ReactNode, useEffect, useRef, useState } from 'react';

import { Button, Caption, FormControl } from '../../';

import classes from './Annotations.module.scss';

interface Props {
  content: string;
  editing: boolean;
  formExtras?: ReactNode;
  onCancelEdit: () => void;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onFinishEdit: () => Promise<void>;
  textAreaLabel: string;
}

const ContentBox = ({ content, editing, formExtras, onCancelEdit, onChange, onFinishEdit, textAreaLabel }: Props) => {
  const [condensed, setCondensed] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string>();

  const readOnlyTextRef = useRef<HTMLDivElement>(null);

  function expandText() {
    setCondensed(false);
  }

  function onCancel() {
    onCancelEdit();
  }

  async function onSave(event: FormEvent) {
    event.preventDefault();

    setSubmitting(true);
    setSubmitError(undefined);
    try {
      await onFinishEdit();
    } catch (error) {
      setSubmitError('Failed to update. Please try again later.');
    } finally {
      setSubmitting(false);
    }
  }

  useEffect(() => {
    if (!condensed || !readOnlyTextRef?.current) {
      return;
    }

    // Check to see if the annotation text currently overflows. If not, there's no need to show the "View more" button.
    if (readOnlyTextRef.current.scrollHeight === readOnlyTextRef.current.offsetHeight) {
      setCondensed(false);
    }
  }, [condensed, readOnlyTextRef]);

  useEffect(() => {
    if (editing && condensed) {
      setCondensed(false);
    }
  }, [condensed, editing]);

  return (
    <div className={classes.annotationBody}>
      {!editing && (
        <>
          <div className={classNames(classes.text, { [classes.condensed]: condensed })} ref={readOnlyTextRef}>
            {content}
          </div>
          {condensed && (
            // Screen readers can see the whole region of text since it's only masked with CSS, but don't mark this as
            // aria-hidden for keyboard users.
            <div className={classes.buttonRow}>
              <Button onClick={expandText} variant="lowEmphasis">
                Show more...
              </Button>
            </div>
          )}
        </>
      )}
      {editing && (
        <form onSubmit={onSave}>
          <FormControl>
            <Caption as={FormLabel}>{textAreaLabel}</Caption>
            <Textarea
              className={classes.textArea}
              onChange={onChange}
              resize="vertical"
              rows={5}
              size="lg"
              value={content}
            />
          </FormControl>
          {formExtras}
          <div className={classes.buttonRow}>
            <Button onClick={onCancel} type="reset" variant="lowEmphasis">
              Cancel
            </Button>
            <Button isDisabled={!content} isLoading={submitting} type="submit" variant="mediumEmphasis">
              Save
            </Button>
          </div>
          {submitError && <p className={classes.errorMessage}>{submitError}</p>}
        </form>
      )}
    </div>
  );
};

export default ContentBox;
