import {
  ActionCloseDrawer,
  ActionSaveFromLocation,
  ActionSetActiveDrawerItem,
  ActionSetSidebarActionsList,
  ActionShowDrawer,
  ActionToggleDrawer,
} from '../@types/redux/appActions';
import { SideBarAction } from '../components/Sidebar/Sidebar';
import {
  CLOSE_DRAWER,
  SAVE_FROM_LOCATION,
  SET_ACTIVE_DRAWER_ITEM,
  SET_SIDEBAR_ACTIONS_LIST,
  SHOW_DRAWER,
  TOGGLE_DRAWER,
} from '../constants/actionTypes';

export const closeDrawer = (): ActionCloseDrawer => ({
  type: CLOSE_DRAWER,
});

export const saveFromLocation = (fromLocation?: string): ActionSaveFromLocation => ({
  type: SAVE_FROM_LOCATION,
  fromLocation,
});

export const setActiveDrawerItem = (activeDrawerItem?: number | string): ActionSetActiveDrawerItem => ({
  type: SET_ACTIVE_DRAWER_ITEM,
  activeDrawerItem,
});

export const setSidebarActionsList = (sideBarActionsList: (string | SideBarAction)[]): ActionSetSidebarActionsList => ({
  sideBarActionsList,
  type: SET_SIDEBAR_ACTIONS_LIST,
});

export const showDrawer = (
  drawer: 'annotations' | 'change-floorplan' | 'search' | 'time-travel'
): ActionShowDrawer => ({
  drawer,
  type: SHOW_DRAWER,
});

export const toggleDrawer = (
  drawer: 'annotations' | 'change-floorplan' | 'search' | 'time-travel'
): ActionToggleDrawer => ({
  drawer,
  type: TOGGLE_DRAWER,
});
