import { AxiosRequestConfig } from 'axios';

import { Annotation, AnnotationCreateRequestBody } from '../../../@types/api/v0/rest/Annotation';
import { post } from '../../next';

export const AnnotationApi = {
  /**
   * Create an annotation instance.
   * @param requestBody Request payload to be sent to the server to create a new annotation.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the created annotation.
   */
  create: (requestBody: AnnotationCreateRequestBody, requestConfig?: AxiosRequestConfig) =>
    // Axios will automatically map the `requestBody` passed here as form data.
    post<AnnotationCreateRequestBody, Annotation>('/api/annotations/', requestBody, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...requestConfig,
    }),
};
