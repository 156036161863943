import { Box, Flex, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, generatePath, useHistory, useParams } from 'react-router-dom';

import { Store } from '../../@types/redux/store';
import * as appActions from '../../actions/app';
import { ProjectApi } from '../../api/v0/rest/ProjectApi';
import { Content, InternalLayout, LoadingIndicator } from '../../components';
import ChangeFloorplanDrawer from '../../components/Drawers/ChangeFloorplan/ChangeFloorplanDrawer';
import FloorplanInfoCard from '../../components/FloorplanInfoCard';
import { ErrorIcon } from '../../components/Icon';
import MiniMap from '../../components/View360/MiniMap';
import { ProjectHierarchyQueryKeys, QueryTopics } from '../../constants/queries';
import Routes from '../../routes';
import theme from '../../theme';

import commonClasses from '../../Common.module.scss';

const FloorplanContainer = () => {
  const history = useHistory();
  const urlParameters = useParams<{ projectId: string; floorplanId: string }>();
  const projectId = Number(urlParameters.projectId);
  const floorplanId = Number(urlParameters.floorplanId);

  const dispatch = useDispatch();
  const currentDrawer = useSelector((state: Store) => state.app.drawer);

  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

  const projectDetailsQuery = useQuery({
    queryKey: [QueryTopics.PROJECT_HIERARCHY, ProjectHierarchyQueryKeys.PROJECT_DETAILS, projectId],
    queryFn: async ({ signal }) => (await ProjectApi.getById(projectId, { signal })).data,
  });

  const project = projectDetailsQuery.data;
  const floorplan = projectDetailsQuery.data?.floorplans?.find(({ id }) => id === floorplanId);
  const projectFloorplans = projectDetailsQuery.data?.floorplans ?? [];

  const closeDrawer = () => dispatch(appActions.closeDrawer());

  useEffect(() => {
    if (!isFinite(projectId) || projectId === 0) {
      return;
    }

    dispatch(
      appActions.setSidebarActionsList([
        {
          actionItem: 'progress',
          onClick: () => {
            history.push(generatePath(Routes.PROJECT_PROGRESS_TRACKING, { id: projectId }));
          },
          active: false,
        },
        'change-floorplan',
      ])
    );
  }, [dispatch, history, projectId]);

  if (projectDetailsQuery.isFetching) {
    return (
      <InternalLayout>
        <Content>
          <LoadingIndicator fullPage />
        </Content>
      </InternalLayout>
    );
  }

  if (projectDetailsQuery.isError || !project || !floorplan) {
    const errorStatusCode = (projectDetailsQuery.error as AxiosError)?.response?.status;
    switch (errorStatusCode) {
      case 403:
      case 404:
        // TODO: TS-219: replace with dedicated HTTP 403 page where user can request access.
        return <Redirect to={Routes.NOT_FOUND} />;
      case 500:
      default: {
        return (
          <InternalLayout>
            <Content constrainToPageHeight>
              <Flex alignItems="center" height="100%" justifyContent="center" flexDir="column">
                <ErrorIcon aria-hidden className={commonClasses.errorIcon} />
                <Text color={theme.colors.brand.gray[900]} textAlign="center">
                  Failed to load data for this floor plan. Please try again later.
                </Text>
              </Flex>
            </Content>
          </InternalLayout>
        );
      }
    }
  }

  return (
    <InternalLayout>
      <ChangeFloorplanDrawer
        close={closeDrawer}
        floorplan={floorplan}
        project={project}
        projectFloorplans={projectFloorplans}
        show={currentDrawer === 'change-floorplan'}
      />
      <Content>
        <FloorplanInfoCard show={!currentDrawer} projectName={project.name} floorplanName={floorplan.name} />
        {!isImageLoaded && <LoadingIndicator />}
        <Box style={{ display: isImageLoaded ? 'block' : 'hidden' }}>
          <MiniMap
            annotations={[]}
            detectionList={[]}
            floorplan={floorplan}
            initiallyShowZoom
            mini={false}
            nodes={[]}
            onImageLoaded={() => setIsImageLoaded(true)}
          />
        </Box>
      </Content>
    </InternalLayout>
  );
};

export default FloorplanContainer;
