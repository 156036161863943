import { FormLabel, Textarea } from '@chakra-ui/react';
import classNames from 'classnames';
import { ChangeEvent, Component, FormEvent } from 'react';

import { Button, Caption, FormControl } from '../../';
import { DenormalizedAnnotation as LegacyDenormalizedAnnotation } from '../../../@types/OnSiteIQ';
import { Annotation } from '../../../@types/api/v0/rest/Annotation';
import SafetyStatusControl from './SafetyStatusControl';

import classes from './Annotations.module.scss';

interface Props {
  annotation: Annotation | LegacyDenormalizedAnnotation;
  onSubmit: ({ comment, safety_mitigation }: { comment: string; safety_mitigation: 'C' | 'O' | null }) => Promise<void>;
}

interface State {
  body: string;
  safetyMitigation: 'C' | 'O' | null;
  submitting: boolean;
  submitError?: string;
}

class AddReply extends Component<Props, State> {
  defaultState = {
    body: '',
    safetyMitigation: null,
    submitting: false,
    submitError: undefined,
  };

  state = this.defaultState;

  handleMitigationChange = (safetyMitigation: 'C' | 'O' | null) => {
    this.setState({ safetyMitigation });
  };

  handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ body: event.target.value });
  };

  onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    this.setState({ submitting: true });
    try {
      await this.props.onSubmit({ comment: this.state.body, safety_mitigation: this.state.safetyMitigation });
      this.setState(this.defaultState);
    } catch (error) {
      console.error('[AddReply] Failed to add reply!', error);
      this.setState({ submitting: false, submitError: 'Failed to add reply. Please try again later.' });
    }
  };

  render() {
    // Only show safety status control if annotation has safety status high, medium, or low
    // and it hasn't already been closed already.
    const showSafetyStatusControl =
      ['H', 'M', 'L'].includes(this.props.annotation.safety_status ?? '') &&
      !this.props.annotation.replies.some((r) => r.safety_mitigation === 'C');

    const submitDisabled = !this.state.body && !this.state.safetyMitigation;

    return (
      <form className={classNames(classes.annotationBody, classes.addReplyForm)} onSubmit={this.onSubmit}>
        <FormControl>
          <Caption as={FormLabel}>Reply</Caption>
          <Textarea
            className={classes.textArea}
            placeholder="Write a reply..."
            onChange={this.handleChange}
            value={this.state.body}
          />
        </FormControl>
        {showSafetyStatusControl && (
          <SafetyStatusControl onChange={this.handleMitigationChange} value={this.state.safetyMitigation} />
        )}
        <div className={classes.buttonRow}>
          <Button
            isDisabled={submitDisabled}
            data-pendo-label="Add reply"
            data-pendo-topic="annotations"
            isLoading={this.state.submitting}
            type="submit"
            variant="mediumEmphasis"
          >
            Post
          </Button>
        </div>
        {this.state.submitError && <p className={classes.errorMessage}>{this.state.submitError}</p>}
      </form>
    );
  }
}

export default AddReply;
