import { Box } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory, useLocation } from 'react-router';

import {
  DatedId,
  DenormalizedAnnotation,
  Floorplan,
  MatchingFloorplan,
  Node,
  Project,
  TimeTravelPair,
  Walkthrough,
} from '../../@types/OnSiteIQ';
import { Annotation } from '../../@types/api/v0/rest/Annotation';
import * as appActions from '../../actions/app';
import * as projectActions from '../../actions/projects';
import { CoachOverlay, Content, InternalLayout } from '../../components';
import AnnotationsDrawer from '../../components/Drawers/Annotations/AnnotationsDrawer';
import ChangeFloorplanDrawer from '../../components/Drawers/ChangeFloorplan/ChangeFloorplanDrawer';
import TimeTravelDrawer, { TimeTravelOption } from '../../components/Drawers/TimeTravel/TimeTravelDrawer';
import FloorplanInfoCard from '../../components/FloorplanInfoCard';
import PanographViewer, { PanographViewerInstance } from '../../components/PanographViewer/PanographViewer';
import { CameraConstants } from '../../components/PanographViewer/constants';
import { SideBarAction } from '../../components/Sidebar/Sidebar';
import BrighteningButton from '../../components/View360/Brightening/BrighteningButton';
import BrighteningSlider from '../../components/View360/Brightening/BrighteningSlider';
import Map from '../../components/View360/Map';
import MarkPointButton from '../../components/View360/MarkPointButton';
import SnapshotButton from '../../components/View360/SnapshotButton';
import { ZoomIn, ZoomOut } from '../../components/ZoomControls/ZoomControls';
import Routes from '../../routes';
import { isMobile } from '../../utils/device';
import AnnotationModalContainer from './AnnotationModal/AnnotationModalContainer';
import SnapshotModalContainer from './SnapshotModal/SnapshotModalContainer';

import commonClasses from '../../Common.module.scss';
import classes from './View360.module.scss';

interface Props {
  /** Name of the current drawer, if open. */
  drawer?: 'annotations' | 'change-floorplan' | 'search' | 'time-travel';
  /** Floorplan of the walkthrough currently being viewed in 360º. */
  floorplan: Floorplan;
  /** The list of floorplans with a walkthrough that have a node in the approximate location of the current node.  */
  matchingFloorplans: MatchingFloorplan[];
  /** Current walkthrough node. */
  node: Node;
  /** The list of annotations/markups on the current node. */
  nodeAnnotations: DenormalizedAnnotation[];
  /**
   * The set of other walkthroughs of this floorplan.
   * @todo This is currently used to preload them, but may be causing lag on big projects. Remove but test thoroughly.
   */
  otherWalkthroughs: DatedId<number>[];
  /** The project to which the current floorplan, walkthrough, and node are related. */
  project: Project;
  /** The list of all floorplans associated with the current project. */
  projectFloorplans: Floorplan[];
  /**
   * The list of possible time travel destinations. Nodes in the same approximate location on the floorplan, but
   * captured at a different point in time.
   */
  timeTravelPairs: TimeTravelPair[];
  /** The walkthrough currently being viewed in 360º. */
  walkthrough: Walkthrough;
  /** The list of all annotations/markups on any node in the current walkthrough. */
  walkthroughAnnotations: DenormalizedAnnotation[];
}

enum AnnotationWorkflowStatus {
  INACTIVE,
  MARKING_POINT,
  MODAL_VISIBLE,
}

const BRIGHTNESS_INCREMENTS = {
  1: 0.5,
  2: 1,
  3: 3,
  4: 5,
};

/** Default zoom increment used by the zoom buttons. Wheel events use a different increment. */
const ZOOM_DEFAULT_INCREMENT = (CameraConstants.MAX_FOV - CameraConstants.MIN_FOV) / 2;

const View360Page = (props: Props) => {
  const {
    drawer,
    floorplan,
    matchingFloorplans,
    node,
    nodeAnnotations,
    otherWalkthroughs,
    project,
    projectFloorplans,
    timeTravelPairs,
    walkthrough,
    walkthroughAnnotations,
  } = props;

  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();

  // 360 overlay state:
  const [annotationWorkflowStatus, setAnnotationWorkflowStatus] = useState<AnnotationWorkflowStatus>(
    AnnotationWorkflowStatus.INACTIVE
  );
  const [brightnessFilterValue, setBrightnessFilterValue] = useState<number>();
  const [isBrightening, setIsBrightening] = useState<boolean>(false);
  const [imageDataUrl, setImageDataUrl] = useState<string>();
  const [isSnapshotModalOpen, setIsSnapshotModalOpen] = useState<boolean>(false);

  // Hoisted viewer state:
  // When the user creates a new annotation, they begin by selecting a point. If present, these coordinates represent a
  // point on the 3D sphere which bounds the scene where the annotation should be placed. */
  const [annotationTarget, setAnnotationTarget] = useState<{ x: number; y: number; z: number }>();
  // The `node` prop corresponds to the URL node. Since we may update the current node without immediately updating the
  // URL, maintain a second reference. This is particularly useful during vertical arrow key nav.
  const [currentNode, setCurrentNode] = useState<Node>(node);
  // Several code paths require the camera to look at a point on the 3D sphere that encloses the scene. When this value
  // changes, the viewer should respond by orienting the camera on it.
  const [lookTarget, setLookTarget] = useState<{ x: number; y: number; z: number }>();
  // The view cone begins facing down the +z axis, which is the +y axis in 2D, so the default direction is <0, 1>.
  const [mapDirection, setMapDirection] = useState<{ x: number; y: number }>({ x: 0, y: 1 });
  const [sceneIsInitializing, setSceneIsInitializing] = useState<boolean>(true);
  const [sceneIsUpdating, setSceneIsUpdating] = useState<boolean>(false);
  // Field of view value used by the 360º viewer's three.js PerspectiveCamera. Increasing this value zooms the camera
  // out; decreasing it zooms the camera in.
  const [zoomFieldOfView, setZoomFieldOfView] = useState(CameraConstants.INITIAL_FOV);

  const panographViewerRef = useRef<PanographViewerInstance>(null);

  const sceneIsBusy = sceneIsInitializing || sceneIsUpdating;

  const setURL = useCallback(
    (nodeId: string) => {
      const destination = generatePath(Routes.VIEW_360, {
        floorplanId: floorplan.id,
        walkthroughId: walkthrough.id,
        nodeId,
      });
      history.replace(destination);
    },
    [floorplan.id, history, walkthrough.id]
  );

  const zoomIn = useCallback(
    (increment: number = ZOOM_DEFAULT_INCREMENT) => {
      setZoomFieldOfView(Math.max(zoomFieldOfView - increment, CameraConstants.MIN_FOV));
      panographViewerRef.current?.focus();
    },
    [zoomFieldOfView]
  );

  const zoomOut = useCallback(
    (increment: number = ZOOM_DEFAULT_INCREMENT) => {
      setZoomFieldOfView(Math.min(zoomFieldOfView + increment, CameraConstants.MAX_FOV));
      panographViewerRef.current?.focus();
    },
    [zoomFieldOfView]
  );

  const sidebarActions: SideBarAction[] = useMemo(
    () => [
      {
        actionItem: 'progress',
        onClick: () => {
          // TODO: use URL generator when the sidebar button bug is addressed
          history.push(`/projects/${project.id}/progress-tracking`);
        },
        active: false,
      },
      'change-floorplan',
      'time-travel',
      'annotations',
    ],
    [history, project.id]
  );

  useEffect(() => {
    dispatch(appActions.setSidebarActionsList(sidebarActions));
  }, [dispatch, sidebarActions]);

  useEffect(() => {
    setAnnotationWorkflowStatus(AnnotationWorkflowStatus.INACTIVE);
    setImageDataUrl(undefined);
    setIsSnapshotModalOpen(false);
    removeBrighteningFilter();

    const lookTargetVector = getTargetLookVector();
    if (lookTargetVector) {
      setLookTarget(lookTargetVector);
    }

    // TODO: I'm not sure we need this...it's probably causing performance issues for large projects.
    dispatch(projectActions.preloadWalkthroughs(otherWalkthroughs));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, walkthrough]);

  // Minimap click, 360 ground node click, or arrow key nav has finished. Update the URL.
  useEffect(() => {
    if (!sceneIsUpdating && currentNode.walkthrough === node.walkthrough && currentNode.id !== node.id) {
      setURL(currentNode.id);
    }
  }, [currentNode, node, sceneIsUpdating, setURL]);

  // Time travel
  useEffect(() => {
    if (currentNode.id !== node.id && currentNode.walkthrough !== node.walkthrough) {
      setCurrentNode(node);
    }
  }, [currentNode, node]);

  useEffect(() => {
    dispatch(projectActions.loadTimetravelPairs(node.id));
    dispatch(projectActions.loadMatchingFloorplans(node.id));
  }, [dispatch, node.id]);

  useEffect(() => {
    if (!lookTarget && annotationTarget) {
      panographViewerRef.current?.requestSnapshot();
    }
  }, [annotationTarget, lookTarget]);

  /**
   * Stop the annotation creation workflow.
   */
  function cancelSelectPoint() {
    if (annotationTarget) {
      setAnnotationTarget(undefined);
    }
    setAnnotationWorkflowStatus(AnnotationWorkflowStatus.INACTIVE);
    setImageDataUrl(undefined);
    panographViewerRef.current?.setMarking(false);
  }

  function closeDrawer() {
    dispatch(appActions.closeDrawer());
  }

  function closeSnapshotModal() {
    setBrightnessFilterValue(undefined);
    setImageDataUrl(undefined);
    setIsSnapshotModalOpen(false);
  }

  function getTargetLookVector(): { x: number; y: number; z: number } | null {
    if (!location.search) {
      return null;
    }

    let target = null;
    const params = new URLSearchParams(location.search);

    // This parameter is primarily set in email notifications prompting the user to view activity on the platform.
    if (params.has('an')) {
      const annotation = nodeAnnotations?.find((a) => a.id === params.get('an'));
      if (annotation) {
        target = {
          x: annotation.x,
          y: annotation.y,
          z: annotation.z,
        };
      }
    }

    // These are set when clicking on annotations or detections on the floorplan page.
    if (params.has('lookX') && params.has('lookY') && params.has('lookZ')) {
      const parsedTarget = {
        x: Number.parseFloat(params.get('lookX') as string),
        y: Number.parseFloat(params.get('lookY') as string),
        z: Number.parseFloat(params.get('lookZ') as string),
      };

      if (Number.isFinite(parsedTarget.x) && Number.isFinite(parsedTarget.y) && Number.isFinite(parsedTarget.z)) {
        target = parsedTarget;
      }
    }

    return target;
  }

  function handleChangeBrighteningFilter(value: 1 | 2 | 3 | 4) {
    panographViewerRef.current?.setBrightness(BRIGHTNESS_INCREMENTS[value]);
    setBrightnessFilterValue(BRIGHTNESS_INCREMENTS[value]);
  }

  /**
   * When the user selects a time travel option, verify that the selected option is the correct type, since time travel
   * options may be either `Walkthrough` or `TimeTravelPair` instances. If the option is a `TimeTravelPair` instance,
   * navigate to the walkthrough and node.
   */
  function handleTimeTravel(timeTravelOption: TimeTravelOption) {
    if ('walkthrough' in timeTravelOption) {
      history.push(
        generatePath(Routes.VIEW_360, {
          floorplanId: floorplan.id,
          walkthroughId: timeTravelOption.walkthrough,
          nodeId: timeTravelOption.id,
        })
      );
    } else {
      console.warn('[View360Page] Selected 360 time travel option was not a time travel pair!');
    }
  }

  /**
   * When the user clicks "View 360" on an annotation in the left drawer, snap to it.
   */
  function handleViewAnnotation({ x, y, z }: DenormalizedAnnotation) {
    setLookTarget({ x, y, z });
  }

  function onClickMinimapNode(targetNode: Node) {
    removeBrighteningFilter();
    setCurrentNode(targetNode);
    const annotation = walkthroughAnnotations?.find((a: DenormalizedAnnotation) => a.node === targetNode.id);
    if (annotation) {
      setLookTarget({
        x: annotation.x,
        y: annotation.y,
        z: annotation.z,
      });
      dispatch(appActions.showDrawer('annotations'));
    }
  }

  const onCreateAnnotation = (annotation: Annotation) => {
    dispatch(projectActions.annotationCreated(annotation));
  };

  /**
   * When the image data for a snapshot is available, decide which modal needs to be shown. Snapshots are created for
   * either the "Create a Markup" or "Take a Screenshot" modals.
   */
  function onSnapshotAvailable(dataUrl: string) {
    setImageDataUrl(dataUrl);
    if (annotationTarget && annotationWorkflowStatus === AnnotationWorkflowStatus.MARKING_POINT) {
      setAnnotationWorkflowStatus(AnnotationWorkflowStatus.MODAL_VISIBLE);
    } else {
      setIsSnapshotModalOpen(true);
    }
  }

  function removeBrighteningFilter(keepFilter?: boolean) {
    setIsBrightening(false);
    setBrightnessFilterValue((currentValue) => (keepFilter ? currentValue : undefined));
    panographViewerRef.current?.clearBrightness();
  }

  /**
   * Request that the 360º viewer generate a snapshot of its canvas on the next render loop (snapshots cannot be
   * reliably captured outside of it).
   */
  function requestSnapshot() {
    closeDrawer();
    removeBrighteningFilter(true);
    setAnnotationWorkflowStatus(AnnotationWorkflowStatus.INACTIVE);
    panographViewerRef.current?.setMarking(false);
    panographViewerRef.current?.requestSnapshot();
  }

  function toggleBrightening() {
    panographViewerRef.current?.setMarking(false);
    setAnnotationWorkflowStatus(AnnotationWorkflowStatus.INACTIVE);

    if (isBrightening) {
      removeBrighteningFilter();
    } else {
      panographViewerRef.current?.setBrightness(3);
      setBrightnessFilterValue(3);
      setIsBrightening(true);
    }
  }

  /**
   * Toggle the annotation creation workflow. Begin by picking a point in 3D space. If any drawer is open, close it. If
   * the image brightness has been modified, remove the filter.
   */
  function toggleSelectPoint() {
    if (annotationWorkflowStatus !== AnnotationWorkflowStatus.INACTIVE) {
      cancelSelectPoint();
      return;
    }

    if (drawer) {
      closeDrawer();
    }
    if (isBrightening) {
      removeBrighteningFilter();
    }

    setAnnotationWorkflowStatus(AnnotationWorkflowStatus.MARKING_POINT);
    panographViewerRef.current?.setMarking(true);
  }

  const isAnnotatingScene = Boolean(annotationWorkflowStatus);
  const isAnnotationModalOpen = annotationWorkflowStatus === AnnotationWorkflowStatus.MODAL_VISIBLE;
  const isPageTitleCardVisible = !drawer || !sidebarActions.includes(drawer);

  return (
    <InternalLayout>
      <ChangeFloorplanDrawer
        action={removeBrighteningFilter}
        close={closeDrawer}
        date={walkthrough.when}
        floorplan={floorplan}
        isLoading={sceneIsBusy}
        matches={matchingFloorplans}
        project={project}
        projectFloorplans={projectFloorplans}
        show={drawer === 'change-floorplan'}
      />
      <TimeTravelDrawer
        close={closeDrawer}
        isLoading={sceneIsBusy}
        onSelectOption={handleTimeTravel}
        options={timeTravelPairs}
        project={project}
        show={drawer === 'time-travel'}
        showThumbnails
        walkthrough={walkthrough}
      />
      <AnnotationsDrawer
        annotationLocationType="location"
        annotations={nodeAnnotations}
        close={closeDrawer}
        date={walkthrough.when}
        isLoading={sceneIsBusy}
        onViewAnnotation={handleViewAnnotation}
        project={project}
        show={drawer === 'annotations'}
      />
      <Content drawerOpen={Boolean(drawer)}>
        <FloorplanInfoCard
          show={isPageTitleCardVisible}
          projectName={project.name}
          floorplanName={floorplan.name}
          walkDate={walkthrough.when}
        />
        {annotationWorkflowStatus === AnnotationWorkflowStatus.MARKING_POINT && (
          <CoachOverlay>Click anywhere on the view to create a markup</CoachOverlay>
        )}
        <Box className={classes.view360Container}>
          <PanographViewer
            ref={panographViewerRef}
            annotations={walkthroughAnnotations}
            floorplan={floorplan}
            lookTarget={lookTarget}
            node={currentNode}
            onSnapshotAvailable={onSnapshotAvailable}
            sceneIsInitializing={sceneIsInitializing}
            sceneIsUpdating={sceneIsUpdating}
            setAnnotationTarget={setAnnotationTarget}
            setLookTarget={setLookTarget}
            setMapDirection={setMapDirection}
            setNode={setCurrentNode}
            setSceneIsInitializing={setSceneIsInitializing}
            setSceneIsUpdating={setSceneIsUpdating}
            walkthrough={walkthrough}
            zoomFieldOfView={zoomFieldOfView}
            zoomIn={zoomIn}
            zoomOut={zoomOut}
          />
          <Box className={classes.rightControls}>
            {isBrightening && (
              <Box className={commonClasses.view360ButtonGroup}>
                <BrighteningSlider onChange={handleChangeBrighteningFilter} />
              </Box>
            )}
            <Box className={commonClasses.view360ButtonGroup}>
              <BrighteningButton active={isBrightening} disabled={sceneIsBusy} onClick={toggleBrightening} />
            </Box>
            <Box className={commonClasses.view360ButtonGroup}>
              <SnapshotButton active={isSnapshotModalOpen} disabled={sceneIsBusy} onClick={requestSnapshot} />
              <MarkPointButton active={isAnnotatingScene} disabled={sceneIsBusy} onClick={toggleSelectPoint} />
            </Box>
            <Box className={commonClasses.view360ButtonGroup}>
              <ZoomIn
                buttonProps={{ 'data-pendo-label': 'Zoom in', 'data-pendo-topic': 'view360' }}
                disabled={sceneIsBusy || zoomFieldOfView <= CameraConstants.MIN_FOV}
                onClick={zoomIn}
              />
              <ZoomOut
                buttonProps={{ 'data-pendo-label': 'Zoom out', 'data-pendo-topic': 'view360' }}
                disabled={sceneIsBusy || zoomFieldOfView >= CameraConstants.MAX_FOV}
                onClick={zoomOut}
              />
            </Box>
          </Box>
          <SnapshotModalContainer
            brightness={brightnessFilterValue}
            floorplan={floorplan}
            image={imageDataUrl}
            isOpen={isSnapshotModalOpen}
            node={node}
            onClose={closeSnapshotModal}
            project={project}
            walkthrough={walkthrough}
          />
          <AnnotationModalContainer
            annotationTarget={annotationTarget}
            floorplan={floorplan}
            image={imageDataUrl}
            isOpen={isAnnotationModalOpen}
            node={node}
            onClose={cancelSelectPoint}
            onCreateAnnotation={onCreateAnnotation}
            project={project}
            walkthrough={walkthrough}
          />
          {!isMobile() && (
            <Map
              annotations={walkthroughAnnotations}
              currentNode={currentNode}
              floorplan={floorplan}
              mapDirection={mapDirection}
              nodes={walkthrough.nodes}
              onClickNode={onClickMinimapNode}
            />
          )}
        </Box>
      </Content>
    </InternalLayout>
  );
};

export default View360Page;
