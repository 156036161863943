import identity from 'lodash/identity';
import isColEmpty from 'lodash/isEmpty';

/**
 * A thin layer over Lodash's `isEmpty` function, except that it considers 0 and false to be empty as well.
 * @deprecated
 */
function isEmpty(value: any) {
  if (typeof value === 'number' && value !== 0) {
    return false;
  }

  if (typeof value === 'boolean' && value !== false) {
    return false;
  }

  return isColEmpty(value);
}

/**
 * Get the plural or singular form of some word depending on a count. If the user does not provide the plural form of
 * the word, an "s" is appended by default.
 */
function maybePluralize(count: number, wordSingular: string, wordPlural = `${wordSingular}s`) {
  return count === 1 ? wordSingular : wordPlural;
}

/**
 * Given a word and some count representing its frequency, retrieve a string describing its frequency. Optionally, the
 * plural form of the word and a prefix may be provided.
 * Examples:
 * 1) countDisplay(0, 'Company', { plural: 'Companies', prefix: 'Show' }) => "No Companies"
 * 2) countDisplay(1, 'Company', { plural: 'Companies', prefix: 'Show' }) => "Show 1 Company"
 * 3) countDisplay(2, 'Company', { plural: 'Companies' }) => "2 Companies"
 */
function countDisplay(count: number, text: string, options: { plural?: string; prefix?: string } = {}) {
  const plural = maybePluralize(count, text, options.plural);
  return [count ? options.prefix : false, count || 'No', plural].filter(identity).join(' ');
}

/**
 * Sort a collection of strings, but numbers are sorted based on their magnitude. Normally, numbers within strings are
 * just comapared digit-by-digit, so 10 would be less than 9.
 * @see https://blog.codinghorror.com/sorting-for-humans-natural-sort-order/
 */
function naturalSort<T>(collection: T[], sortProperty: keyof T) {
  const collator = new Intl.Collator('en-US', { numeric: true, sensitivity: 'base' });
  const copy = [...collection];
  return copy.sort((a: T, b: T) => collator.compare(a[sortProperty] as string, b[sortProperty] as string));
}

/**
 * Convert a Base64-encoded data URI into a File object.
 * @see https://stackoverflow.com/a/38935990
 */
const dataURItoFile = (dataUri: string, filename: string) => {
  const arr = dataUri.split(',');
  const mime = arr[0].match(/:(.*?);/u)?.[1] ?? '';
  const bstr = atob(arr[1]);
  const n = bstr.length;
  const u8arr = new Uint8Array(n);

  for (let i = 0; i < n; i += 1) {
    u8arr[i] = bstr.charCodeAt(i);
  }

  return new File([u8arr], filename, { type: mime });
};

export { countDisplay, isEmpty, maybePluralize, naturalSort, dataURItoFile };
