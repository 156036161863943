import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import 'whatwg-fetch';

import { setLoggedOut } from '../actions/auth';
import { store } from '../store';

const TOKEN_EXPIRY_TOLERANCE = 120000;

const parseJSON = (response) =>
  new Promise((resolve, reject) => {
    if (!response.ok) {
      if (response.status === 401) {
        store.dispatch(setLoggedOut());
      }
      // TODO: update this reject call to send a more traditional error, rather than passing the entire response to the reject handler
      return reject(response);
    }
    if (response.status === 204) {
      return resolve({ ok: true, json: {} });
    }

    return response.json().then((json) => resolve(json));
  });

const getNewAccessToken = async () => {
  const refreshToken = Cookies.get('onsitevision_refresh');
  if (!refreshToken) {
    // missing refresh token - cannot continue
    return null;
  }

  let decoded;
  try {
    decoded = jwtDecode(refreshToken);
  } catch (e) {
    console.error('error decoding refresh token', e);
    Cookies.remove('onsitevision_refresh', { domain: window.location.hostname });
    return null;
  }

  const expMilliseconds = decoded.exp * 1000;

  if (Date.now() + TOKEN_EXPIRY_TOLERANCE >= expMilliseconds) {
    Cookies.remove('onsitevision_refresh', { domain: window.location.hostname });
    Cookies.remove('onsitevision_access', { domain: window.location.hostname });
    return null;
  }

  const newTokenResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/token/refresh/`, {
    method: 'POST',
    body: JSON.stringify({ refresh: refreshToken }),
    headers: { 'Content-Type': 'application/json' },
  });
  if (newTokenResponse.status !== 200) {
    const parsedResponse = await newTokenResponse.json();
    console.error('[api][getNewAccessToken] Bad response on refresh', newTokenResponse.status, {
      parsedResponse,
    });
    if (parsedResponse.code === 'token_not_valid') {
      console.error('[api][getNewAccessToken] invalid refresh token. Deleting token.');
      store.dispatch(setLoggedOut());
      throw new Error('[api][getNewAccessToken] Invalid refresh token');
    }
    throw new Error('[api][getNewAccessToken] Failed to refresh access token');
  }
  const tokens = await newTokenResponse.json();
  return tokens.access;
};

const request = async (method, url, data, options) => {
  const requestUrl = `${process.env.REACT_APP_API_BASE_URL}${url}`;
  const requestOptions = {
    ...options,
    headers: options?.headers ?? new Headers(),
    method,
  };

  if (data && options?.isForm) {
    requestOptions.body = new FormData();
    for (const [key, value] of Object.entries(data)) {
      requestOptions.body.append(key, value);
    }
  } else if (data) {
    requestOptions.body = JSON.stringify(data);
    requestOptions.headers.append('Content-Type', 'application/json');
  }

  const jwtAccessToken = await getJWTAccessToken();
  if (jwtAccessToken) {
    requestOptions.headers.append('Authorization', `Bearer ${jwtAccessToken}`);
  }
  return fetch(requestUrl, requestOptions).then(parseJSON);
};

export const getJWTAccessToken = async () => {
  // Uses the refresh token if needed
  let jwtAccessToken = Cookies.get('onsitevision_access');
  const refreshToken = Cookies.get('onsitevision_refresh');

  if (!jwtAccessToken && refreshToken) {
    // Access token expired, but you still have a refresh token locally
    jwtAccessToken = await getNewAccessToken();
    if (jwtAccessToken) {
      Cookies.set('onsitevision_access', jwtAccessToken, { domain: window.location.hostname, expires: 1 });
    }
  } else if (jwtAccessToken) {
    let decoded;
    try {
      decoded = jwtDecode(jwtAccessToken);
    } catch (e) {
      console.error('error decoding jwt', e);
      Cookies.remove('onsitevision_access', { domain: window.location.hostname });
      return null;
    }
    const expMilliseconds = decoded.exp * 1000;

    if (Date.now() + TOKEN_EXPIRY_TOLERANCE >= expMilliseconds) {
      // token expired or will expire soon, so refresh
      jwtAccessToken = await getNewAccessToken();
      if (jwtAccessToken) {
        Cookies.set('onsitevision_access', jwtAccessToken, { domain: window.location.hostname, expires: 1 });
      }
    }
  }
  return jwtAccessToken;
};

const get = (url, options) => request('GET', url, null, options);
const del = (url, options) => request('DELETE', url, null, options);
const patch = (url, data, options) => request('PATCH', url, data, options);
const post = (url, data, options) => request('POST', url, data, options);

const api = {};

api.auth = {
  me: () => get(`/api/auth/user/`),
  jwtLogin: (data) => post('/api/token/', data),
  loginWithGoogle: (idToken) => get(`/api/auth/sso/social/v2/google?id_token=${idToken}`),
  setProfile: (data) => patch(`/api/auth/user/`, data),
  resetPassword: (data) => post(`/api/auth/password/reset/`, data),
  setPassword: (data) => post(`/api/auth/password/reset/confirm/`, data),
  changePassword: (data) => post(`/api/auth/password/change/`, data),
  getJWTfromOTT: (token) => get(`/api/auth/ott/${token}`),
  setProcoreAccessToken: (data) => post('/api/auth/sso/procore/token', data),
};

api.userPersona = {
  getPersonaOptions: () => get(`/api/personas/`),
};

api.userToken = {
  setPassword: (token, data) => patch(`/api/user_token/${token}/user_password/`, data),
  setProfile: (token, data) => patch(`/api/user_token/${token}/user_profile/`, data),
  validateToken: (token) => get(`/api/user_token/${token}/`),
  getPersonas: (token) => get(`/api/user_token/${token}/personas/`),
};

api.floorplans = {
  get: (id) => get(`/api/floorplans/${id}/`),
  getProject: (id) => get(`/api/floorplans/${id}/project/`),
};

api.walkthroughs = {
  get: (id) => get(`/api/walkthroughs/${id}/`),
  getFloorplan: (id) => get(`/api/walkthroughs/${id}/floorplan/`),
  getProject: (id) => get(`/api/walkthroughs/${id}/project/`),
  getAnnotations: (id) => get(`/api/walkthroughs/${id}/annotations/`),
};

api.nodes = {
  getTimetravelPairs: (id) => get(`/api/nodes/${id}/timetravel/`),
  getMatchingFloorplans: (id) => get(`/api/nodes/${id}/matching_floorplans/`),
};

// TODO: remove
api.annotations = {
  update: (id, data) => patch(`/api/annotations/${id}/`, data),
  remove: (id) => del(`/api/annotations/${id}/`),
};

api.replies = {
  create: (data) => post(`/api/replies/`, data),
  update: (id, data) => patch(`/api/replies/${id}/`, data),
  remove: (id) => del(`/api/replies/${id}/`),
};

export default api;
