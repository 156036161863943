import { Floorplan } from '../@types/OnSiteIQ';
import { store } from '../store';

// Loading checks below

export const isWalkthroughLoaded = (id: number): boolean => {
  const { network } = store.getState();
  return Boolean(network.walkthroughs[id] && network.walkthroughs[id].loaded);
};

export const isWalkthroughPending = (id: number): boolean => {
  const { network } = store.getState();
  return Boolean(network.walkthroughs[id] && network.walkthroughs[id].loaded === false);
};

// Manually check if another walkthrough with the same floorplan and project has been loaded
// This allows us to not have to make requests for the walkthroughs project and floorplan info
export const isOtherWalkthroughLoaded = (id: number): boolean => {
  const { floorplans } = store.getState().entities;

  // This is made as a seperate definition to help the typechecker
  const floorplanValues: Floorplan[] = Object.values(floorplans);

  // NOTE casting to number because I don't trust the js calling this
  return floorplanValues.flatMap((f: Floorplan) => f.dated_walkthroughs.map((o) => o.id)).includes(Number(id));
};

export const isFloorplanLoaded = (id: number): boolean => {
  const { network } = store.getState();
  return network.floorplans[id] && network.floorplans[id].loaded;
};

export const isFloorplanPending = (id: number): boolean => {
  const { network } = store.getState();
  return network.floorplans[id] && network.floorplans[id].loaded === false;
};

export const areTimetravelPairsLoaded = (nodeId: number): boolean => {
  const { network } = store.getState();
  return Boolean(network.timetravelPairs[nodeId] && network.timetravelPairs[nodeId].loaded === true);
};

export const areTimetravelPairsPending = (nodeId: number): boolean => {
  const { network } = store.getState();
  return Boolean(network.timetravelPairs[nodeId] && network.timetravelPairs[nodeId].loaded === false);
};

export const areMatchingFloorplansLoaded = (nodeId: number): boolean => {
  const { network } = store.getState();
  return Boolean(network.matchingFloorplans[nodeId] && network.matchingFloorplans[nodeId].loaded === true);
};

export const areMatchingFloorplansPending = (nodeId: number): boolean => {
  const { network } = store.getState();
  return Boolean(network.matchingFloorplans[nodeId] && network.matchingFloorplans[nodeId].loaded === false);
};
